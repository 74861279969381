<template>
  <div class="container">
    <div class="card text-start" style="margin: 10px 0px 30px">
      <div class="card-header">
        <h3>
          <strong>
            {{ courseName }} 課程資訊
          </strong>
        </h3>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item"><strong>課程工具:</strong>{{ courseInstrument }}</li>
        <li class="list-group-item"><strong>課程介紹:</strong>{{ courseIntroduce }}</li>
        <li class="list-group-item"><strong>指導教授:</strong>{{ courseTeacher }}</li>
        <li class="list-group-item"><strong>開課學期:</strong>{{ courseSemester }}{{courseTerm}}</li>
        <li class="list-group-item"><strong>開課班級:</strong>{{ courseClasses }}</li>
        <li class="list-group-item"><strong>當前時間:</strong><time-module></time-module></li>
      </ul>
    </div>
  </div>
</template>

<script>
import TimeModule from "../module/timeModule.vue";
export default {
  name: "courseDescription",
  components: {TimeModule},
  props:{
    courseIntroduce:{
      default:"Undefined"
    },
    courseTeacher:{
      default:"Undefined"
    },
    courseInstrument:{
      default:"Undefined"
    },
    courseName:{
      default:"Undefined"
    },
    courseSemester:{
      default:"Undefined"
    },
    courseTerm:{
      default:"Undefined"
    },
    courseClasses:{
      default:"Undefined"
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>

</style>