<template>
  <navbar></navbar>
  <div class="container">
    <div class="d-flex justify-content-start my-3 ms-2">
      <h3 class="fw-bold">
        <span v-if="mode == 'manage'">題庫管理</span>
        <span  v-if="mode == 'import'">題庫匯入</span>
      </h3>
    </div>
  </div>
  <button v-if="false" @click="debug">DEBUG</button>
  <type-selector :mode="mode" v-model:now-select-type="nowSelectType" ></type-selector>
  <search-engine :nowSelectType="nowSelectType" v-model:search-program-criteria="searchProgramCriteria"
  v-model:search-form-criteria="searchFormCriteria" @searchProgramBtn="fetchProgramData"  @searchFormBtn="fetchFormData">

  </search-engine>
  <display-result :mode="mode" :nowSelectType="nowSelectType" :programData="programData" :formData="formData" @importQuest="requireImportQuest"></display-result>
  <toast ref="toast"></toast>
</template>

<script>
import Navbar from "@/components/module/navbar/index.vue";
import DisplayResult from "@/components/courseMenu/ctrlComponent/QBManage/component/displayResult.vue";
import SearchEngine from "@/components/courseMenu/ctrlComponent/QBManage/component/searchEngine.vue";
import TypeSelector from "@/components/courseMenu/ctrlComponent/QBManage/component/typeSelector.vue";
import axios from "axios";
import Toast from "@/components/module/toast.vue";

export default {
  name: "index",
  components: {Toast, TypeSelector, SearchEngine, DisplayResult, Navbar},
  props:{
    mode: {type: String},
  },
  data(){
    const nowSelectType = "program"
    const searchProgramCriteria = {
      sort:"",
      title:"",
      version:"",
      language:"",
      creator:"",
    }
    const searchFormCriteria = {
      sort:"",
      title:"",
      amount:"",
      creator:""
    }
    // sort,title,lang,version,creator,questionId
    const programData = []
    // sort,title,amount,creator,questionId
    const formData = []
    this.fetchProgramData()
    this.fetchFormData()
    return {
      searchProgramCriteria,
      searchFormCriteria,
      nowSelectType,
      programData,
      formData
    }
  },
  methods:{
    fetchProgramData:async function (){
      let conditions = {}
      if(this.searchProgramCriteria){
        conditions = {
          sort:this.searchProgramCriteria.sort || "",
          title:this.searchProgramCriteria.title || "",
          lang: this.searchProgramCriteria.language || "",
          version: this.searchProgramCriteria.version || "",
          creator: this.searchProgramCriteria.creator || "",
        }
      }
      const url = (this.mode == "import") ? `/api/course/${this.$route.params.courseId}/qb/import/program/search`
          :`/api/course/${this.$route.params.courseId}/qb/manage/program/search`

      await axios.post(url,conditions).then(result=>{
        if(result.data.success){
          this.programData = []
          result.data.programData.forEach((data)=>{
            this.programData.push(data)
          })
        }
      })
    },
    fetchFormData:async function (){
      let conditions = {}
      if(this.searchFormCriteria){
        conditions = {
          sort:this.searchFormCriteria.sort || "",
          title:this.searchFormCriteria.title || "",
          amount: this.searchFormCriteria.amount || "",
          creator: this.searchFormCriteria.creator || "",
        }
      }
      await axios.post(`/api/course/${this.$route.params.courseId}/qb/manage/form/search`,conditions).then(result=>{
        if(result.data.success){

          this.formData = []
          result.data.formData.forEach((data)=>{
            this.formData.push({
              questionId: data.id,
              sort: data.sort,
              title: data.title,
              amount: data.amount,
              creator: data.creator,
            })
          })
        }
      })
    },
    requireImportQuest:async function (id){
      await axios.post(`/api/course/${this.$route.params.courseId}/qb/import/program/add`,{importId:id}).then(response=>{
        if(response.data.success){
          this.$refs.toast.show_toast("匯入題目成功。","success")
        }else{
          this.$refs.toast.show_toast("匯入題目失敗，請檢查是否有重複題目。","error")
        }
      })
    },
    debug:function(){
      console.log(this.searchProgramCriteria)
    }
  }
}
</script>

<style scoped>

</style>