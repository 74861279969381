<template>
  <register-navbar></register-navbar>
  <div v-if="resetShow" class="container">
    <form method="post" @submit.prevent="submitRegister">
      <div class="row mt-3">
        <h2 class="mb-3 fw-bolder">重置密碼</h2>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">密碼</label>
        <div class=" col-sm-9">
          <input type="password" v-model="stdPW" id="password" class="form-control" placeholder="Password" required>
        </div>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold ">確認密碼</label>
        <div class=" col-sm-9">
          <input type="password" v-model="stdCPW"
                 id="confirmPassword" class="form-control" :class="{'border-danger':confirmPasswordWarning}" placeholder="Confirm Password" required>
        </div>
      </div>

      <div v-if="confirmPasswordWarning" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">輸入密碼不一致，請重新輸入</small>
      </div>

      <div class="button--edit-password mt-3">
        <button type="submit" class="btn btn-primary me-3">送出</button>
        <button type="reset" class="btn btn-warning me-3">清除</button>
      </div>

    </form>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import axios from "axios";
import registerNavbar from "../module/navbar/Register-navbar.vue";
import AlertModal from "../module/Modal/alertModal.vue";
export default {
  name: "PasswordActive",
  components: {
    AlertModal,
    registerNavbar
  },
  created() {
    axios.get(`/api/editPassword/${this.$route.query.token}`).then((res) => {
      if(res.data.success){
        this.resetShow = true
      }
      else {
        this.alertModal= {
          show : true,
          state: "danger",
          title: "連結失效",
          bodyMsg:"本連結無效或已失效，請重新申請連結!",
          crossShow:false,
          confirmEvent:function (){
            this.$router.push("/recover")
          }
        }
      }

    })
  },
  data() {
    const alertModal = {
      show:false
    }
    return {
      resetShow:false,
      stdPW: "",
      stdCPW: "",
      confirmPasswordWarning: false,
      alertModal
    }
  },
  methods: {
    submitRegister() {
      if (this.stdPW == this.stdCPW) {
        axios.post("/api/password", {password: this.stdPW, token: this.$route.query.token})
            .then((res) => {
          if (res["data"].success) {
            this.alertModal= {
              show : true,
              state: "normal",
              title: "修改成功",
              bodyMsg:"已為您重設密碼，請至登入頁面用新密碼登入",
              crossShow:false,
              confirmEvent:function (){
                this.$router.push("/login")
              }
            }
          }
          else {
            this.alertModal= {
              show : true,
              state: "danger",
              title: "連結失效",
              bodyMsg:"本連結無效或已失效，請重新申請連結!",
              crossShow:false,
              confirmEvent:function (){
                this.$router.push("/recover")
              }
            }
          }
        })
      } else this.confirmPasswordWarning = true
    }
  }
}
</script>

<style scoped>
form {
  text-align: center;
  margin: auto;
  padding: 20px;
}


.data-brick {
  text-align: center;
  width: 400px;
  margin: auto;
}

label {
  text-align: left;
}

.button--edit-password {
  margin: 5px;
}
</style>