<template>
  <div v-show="loadInterface && loadSeatColor" class="container">
    <navbar :seatBoolean="seatBoolean" :seatNumber="seatNumber"></navbar>
    <exam-ctrl-bar></exam-ctrl-bar>
    <exam-interface @loadInterface="checkLoad"></exam-interface>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import navbar from "../module/navbar";
import examInterface from "./examInterface.vue";
import ExamCtrlBar from "./examCtrlBar.vue";
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "index",
  components: {
    ExamCtrlBar, navbar, examInterface, AlertModal
  },
  data() {
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    this.getColor()
    return {
      alertModal,
      loadInterface: false,
      loadSeatColor: false,
      seatBoolean: false,
      seatNumber: 0,
    }
  },
  methods: {
    checkLoad(name) {
      this[name] = true
      if (this.loadInterface) {
        this.alertModal = {
          show: false,
        }
      }
      else{
        this.alertModal = {
          show: true,
          state: "warning",
          title: "讀取失敗",
          bodyMsg: "請重新嘗試",
          crossShow: false,
          confirmEvent: function () {
            location.href = `/course/${this.$route.params.courseId}`
          }
        }
      }
    },
    getColor: async function () {
      await axios.post(`/api/course/${this.$route.params.examId}/color`).then((res) => {
        if (res.data.success) {
          this.seatBoolean = res.data.seatBoolean
          this.seatNumber = res.data.seatNumber
          this.loadSeatColor = true
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}`
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>