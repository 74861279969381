<template>
    <navbar></navbar>
    <div v-show="uploadShow || mode!='edit'">
        <div class="container mt-5">
            <div class="row">
                <div class="d-flex justify-content-start">
                    <h3><strong>
                        <span v-if="mode == 'new'">建立考試</span>
                        <span v-if="mode == 'edit'">編輯考試</span>
                    </strong></h3>

                </div>
            </div>
            <div class="row my-2">
                <div class="card container-fluid" style="width: 70vw">
                    <div class="row mt-4">
                        <label class="col-form-label col-md-3">名稱<span class="text-danger">*</span></label>
                        <div class="col-md-8">
                            <input v-model="quiz.title" class="form-control" required>
                        </div>
                    </div>
                    <div class="row mt-2 ">
                        <label class="col-form-label col-md-3">內容</label>
                        <div class="col-md-8">
                            <input v-model="quiz.detail" class="form-control">
                        </div>
                    </div>

                    <div class="row mt-2 mb-4">
                        <label class="col-form-label col-md-3">類型</label>
                        <div class="col-md-8">
                            <select v-model="quiz.type" class="form-select">
                                <option value="practice">練習</option>
                                <option value="exam">考試</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row my-2">
                <div class="card container-fluid" style="width: 70vw">
                    <div class="row">
                        <time-content v-model:end-time="timeContent.endTime" v-model:start-time="timeContent.startTime"
                                      v-model:title="timeContent.title"
                                      class="col"></time-content>
                    </div>
                    <div class="row mt-2">
                        <seat-distribute v-model="seatData.seatSetting"
                                         v-model:select-classroom="seatData.selectedClassroom"
                                         v-model:select-setting="seatData.selectedSetting"
                                         v-model:setting-disable="seatData.seatDisable"
                                         v-model:setting-title="timeContent.title"
                                         class="col"></seat-distribute>
                    </div>
                    <div class="row my-2">
                        <label class="col-form-label col-2 text-danger">[臨時] IP 範圍</label>
                        <div class="col-9">
                            <input class="form-control" placeholder="請輸入 140.137.145.0/24 ,140.137.145.* ,140.137.41.50 ..."
                            v-model="ip_setting.range">
                        </div>
                    </div>

                </div>
            </div>
            <div class="row my-2 d-flex justify-content-center">
                <button v-if="mode === 'edit'" class="col-md-2 btn btn-outline-primary mx-2" @click="saveExamData"><span
                    class="mx-1">儲存</span><i class="fas fa-save"></i>
                </button>

                <button v-if="mode === 'new'" class="col-md-2 btn btn-outline-primary mx-2"
                        @click="createExamData"><span
                    class="mx-1">新增考試</span><i class="fas fa-save"></i>
                </button>

                <button class="col-md-2 btn btn-outline-warning mx-2" @click="$router.go(-1)"><span
                    class="mx-1">返回</span><i
                    class="fas fa-backspace"></i></button>
            </div>
        </div>
        <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
    </div>
    <alert-modal v-if="mode=='edit'" v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import AlertModal from "../../../module/Modal/alertModal.vue";
import navbar from "../../../module/navbar";
import timeContent from "./timeContent.vue";
import seatDistribute from "./seatDistribute.vue";
import axios from "axios";

export default {
    name: "examDetailCtrl",
    props: {
        // 頁面模式
        mode: {
            type: String
        }
    },
    components: {navbar, AlertModal, timeContent, seatDistribute},
    data() {
        const quiz = {
            title: '',
            detail: '',
            type: "practice"
        }
        const ip_setting = {
            range:"",
        }
        const timeContent = {
            title: "",
            startTime: null,
            endTime: null,
        }
        const seatData = {
            seatSetting: [[]],
            seatDisable: false,
            selectedClassroom: -1,
            selectedSetting: -1,
        }
        const alertArgs = {
            show: false
        }
        const alertModal = {
            show: true,
            state: "loading",
            title: "讀取中",
            crossShow: false,
            confirmShow: false
        }
        this.fetchExamData()
        return {
            quiz,
            alertArgs,
            timeContent,
            seatData,
            alertModal,
            ip_setting,
            uploadShow: false,
        }
    },
    methods: {
        fetchExamData: async function () {
            if (this.mode === 'edit') {
                axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/edit/load`).then(result => {
                    if (result.data.success) {

                        const info = result.data.info
                        console.log(result.data)
                        this.quiz.title = info.title
                        this.quiz.detail = info.describe
                        this.quiz.type = info.type

                        this.timeContent.title = info.settingName
                        this.timeContent.startTime = info.startTime
                        this.timeContent.endTime = info.endTime

                        this.seatData.seatSetting = info.seatSet
                        this.seatData.seatDisable = info.seatDisable

                        this.ip_setting.range = info.ip_range
                        this.alertModal = {
                            show: false,
                        }
                        this.uploadShow = true
                    }
                })
            }
        },
        saveExamData: async function () {
            if (this.mode === 'edit') {
                let req = {
                    title: this.quiz.title,
                    describe: this.quiz.detail,
                    type: this.quiz.type,
                    settingName: this.timeContent.title,
                    timeStart: this.timeContent.startTime,
                    timeEnd: this.timeContent.endTime,
                    seatSetting: this.seatData.seatSetting,
                    seatDisable: this.seatData.seat_disable,
                    ip_range: this.ip_setting.range
                }
                axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/edit/update`, req).then(res => {
                    if (res.data.success) {
                        this.alertArgs = {
                            show: true,
                            title: "儲存成功",
                            bodyMsg: "儲存考試設定成功!"
                        }
                    } else {
                        this.alertArgs = {
                            show: true,
                            state: 'warning',
                            title: "儲存失敗",
                            bodyMsg: "儲存考試設定失敗，請重新嘗試!"
                        }
                    }
                })
            }
        },
        createExamData: async function () {
            if (this.mode === 'new') {
                let req = {
                    title: this.quiz.title,
                    describe: this.quiz.detail,
                    type: this.quiz.type,
                    settingName: this.timeContent.title,
                    timeStart: this.timeContent.startTime,
                    timeEnd: this.timeContent.endTime,
                    seatSetting: this.seatData.seatSetting,
                    seatDisable: this.seatData.seat_disable,
                    ip_range: this.ip_setting.range
                }
                axios.post(`/api/course/${this.$route.params.courseId}/quiz/create`, req).then(res => {
                    if (res.data.success) {
                        this.alertArgs = {
                            show: true,
                            title: "新增成功",
                            bodyMsg: "新增考試成功!",
                            crossShow: false,
                            confirmEvent: function () {
                                this.$router.push(`/course/${this.$route.params.courseId}/exam/${res.data.quizId}`)
                            }
                        }
                    } else {
                        this.alertArgs = {
                            show: true,
                            state: 'warning',
                            title: "新增失敗",
                            bodyMsg: "新增考試失敗，請重新嘗試!"
                        }
                    }
                })
            }
        }
    }
}

</script>

<style scoped>
.row-disabled {
    color: gray;
}

.row-disabled label,
.row-disabled span {
    color: gray;
}

.row-disabled input {
    color: gray;
    pointer-events: none;
    background-color: rgba(128, 128, 128, 0.15);
}


</style>