<template>
  <Transition name="modal">
    <div v-if="showModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="d-flex justify-content-end">
            <button @click="$emit('close')" class="btn-close">
            </button>

          </div>
          <div class="modal-header">
            <h3>學生列表 座位[<slot name="title"></slot>]</h3>
          </div>

          <div class="modal-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="input-group flex-nowrap">
                    <span class="input-group-text" id="addon-wrapping"><i class="fas fa-search"></i></span>
                    <input type="text" class="form-control" placeholder="搜尋項目" aria-label="Username" aria-describedby="addon-wrapping">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="table-overflow-y">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">系所</th>
                      <th scope="col">電子郵件</th>
                      <th scope="col">學號</th>
                      <th scope="col">姓名</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(std,index) in studentList" :key="index" @dblclick="buttonSubmit"
                        @click="selectUser(index)" :class="{'on-selected':selectedUser != null && std.uuid== selectedUser.uuid}">
                      <td>{{ index+1 }}</td>
                      <td>{{ std.schDep }}</td>
                      <td>{{ std.email }}</td>
                      <td>{{ std.stdId }}</td>
                      <td>{{ std.stdName }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer d-flex justify-content-center">
            <button class="btn btn-primary btn-lg" @click="buttonSubmit">選擇學生</button>
            <button class="btn btn-warning btn-lg" @click="buttonClear">清除選擇</button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>


import axios from "axios";

export default {
  name: "stdDisplayModal",
  props: ["showModal","propSelectedUser"],
  mounted() {

  },
  watch:{
    showModal:{
      handler(){
        if(this.showModal)
        {
          //console.log(this.propSelectedUser)
          this.selectedUser = this.propSelectedUser
        }
      }
    }
  },
  data() {
    this.fetchUserList()
    return {
      selectedUser:null,
      studentList: []
    }
  },
  methods: {
    closeModal(){
      this.$emit('close');
    },
    sendData(){
      this.$emit("emitSend",this.selectedUser)
    },
    selectUser(index){
      this.selectedUser = this.studentList[index]

    },
    buttonSubmit(){
      this.sendData()
      this.closeModal()
    },
    buttonClear(){
      this.selectedUser = null
      this.sendData()
    },
    fetchUserList:function (){
      axios.post(`/api/course/${this.$route.params.courseId}/stdList`).then(result=>{
        if(result.data.success){
          this.studentList = []
          result.data.student.map(mapItem=>{
            this.studentList.push({
              uuid : mapItem.uuid,
              stdId: mapItem.stdId,
              stdName:mapItem.name,
              email:mapItem.email,
              schDep:`${mapItem.school}-${mapItem.department}`
            })

          })
        }
      })
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  height: 90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #50a457;
  font-weight: bold;
}


.modal-body {
  height: 60vh;
}

.table-overflow-y
{
  height:50vh;
  overflow-y: auto;
}


.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.on-selected{
  background-color: rgba(199, 225, 81, 0.4);
}

</style>