<template>
  <navbar/>
  <formPaper :mode="mode"/>
</template>

<script>
import navbar from "../../module/navbar"
import formPaper from "./formPaper.vue";

export default {
  name: "index",
  components: {
    navbar, formPaper
  },
  props: {
    mode: {type: String},
  }
}
</script>

<style scoped>

</style>