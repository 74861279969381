import { createApp } from 'vue'
import App from './App.vue'
import jquery from 'jquery/dist/jquery'
// Boostrap 套件
//import {createPopper} from '@popperjs/core/dist/cjs/popper';
import bootstrap from 'bootstrap/dist/css/bootstrap.css'
import bootstrapJs from 'bootstrap/dist/js/bootstrap'
// 更多符號
import fontAwesomeCSS from '@fortawesome/fontawesome-free/css/all.css'
import fontAwesomeJS from '@fortawesome/fontawesome-free/js/all.js'
// Ace 編譯介面
import ace from 'ace-builds'
// Vue 網址路由
import router from './router'
// 時間挑選工具
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
// Toast-vue 套件
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// // Vuex
// import store from "./store"

/*
 =========== This Project is Made By 108 PCCU CSIE CloudJudge Platform Team ==========
 Member:  Yan-Ting Lee, BING-YU Zhuang, Jia-Kai Wu
 Advising professor: Sheng-Xuan Xu
 Grateful: Zi-Cheng Pan, JHIH-REN Li, PCCU-CSIE
 Taiwan Private Chinese Culture University Computer-Science and Information-Engineering
 */


const app = createApp(App)


app.use(fontAwesomeCSS)
app.use(fontAwesomeJS)
//app.use(createPopper)
app.use(jquery)
app.use(bootstrap)
app.use(bootstrapJs)
app.use(ace)
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true
});
app.component('Datepicker',Datepicker)
app.use(router)
app.mount('#app')
