<template>
  <navbar hide-bread-crumb="true"></navbar>
<h1>:( [401] </h1>
<h2>This Page Need Login</h2>
  <router-link to="/"><button class="btn btn-warning mx-3">回到首頁</button></router-link>
  <router-link to="/login"><button class="btn btn-primary mx-3">登入網頁</button></router-link>
</template>

<script>
import Navbar from "@/components/module/navbar";

export default {
  name: "page401",
  components: {Navbar}
}
</script>

<style scoped>
h1{
  font-size: 200px;
  font-weight: bolder;
  text-align: center;
}
h2{
  font-size: 100px;
  font-weight: bolder;
  text-align: center;
}
</style>