<template>
</template>

<script>
// write following type to use
// <toast :="toast_data" ref="toast"></toast>

// call this function used: this.$refs.toast.show_toast()
import {useToast} from "vue-toastification";

export default {
  name: "toast",
  props: {
    // Toast 顯示的文字
    text: {
      type: String,
      default: "No data"
    },
    // 彈出位置
    position: {
      type: String,
      default: "bottom-right",
    },
    // toast消失時間
    timeout: {
      type: Number,
      default: 2000,
    },
    // toast是否可以點擊消失
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    // 網頁失去焦點暫停
    pauseOnFocusLoss: {
      type: Boolean,
      default: true
    },
    // 鼠標移到上面時暫停計時
    pauseOnHover: {
      type: Boolean,
      default: true
    },
    // 是否顯示Ｘ
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    // 當鼠標到上面才顯示Ｘ
    showCloseButtonOnHover:{
      type: Boolean,
      default: true,
    },
    // 隱藏時間進度條
    hideProgressBar: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
    }
  },
  methods: {
    show_toast: function (text = this.text,type = "default") {
      const toast = useToast()
      const options = {
        position: this.position,
        timeout: this.timeout,
        closeOnClick: this.closeOnClick,
        pauseOnFocusLoss: this.pauseOnFocusLoss,
        pauseOnHover: this.pauseOnHover,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: this.showCloseButtonOnHover,
        hideProgressBar: this.hideProgressBar,
        closeButton: (this.showCloseBtn) ? "button" : false,
        icon: true,
        rtl: false
      }
      if(type === "success"){
        toast.success(text, options);
      }
      else if(type === "info"){
        toast.info(text, options);
      }
      else if(type === "warning"){
        toast.warning(text, options);
      }
      else if(type === "error"){
        toast.error(text, options);
      }
      else{
        toast(text, options);
      }
    }
  }
}
</script>

<style scoped>

</style>