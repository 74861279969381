<template>
  <div v-if="checkIsLogin">
    <login-navbar :show-go-to-top="showGoToTop" :seatBoolean="seatBoolean" :seat-number="seatNumber"></login-navbar>
    <bread-crumb v-if="!hideBreadCrumb"></bread-crumb>
  </div>
  <div v-else>
    <not-login-navbar></not-login-navbar>
  </div>
</template>


<script>
import auth from "@/store/auth";
import loginNavbar from "./header-navbar.vue"
import notLoginNavbar from "./Register-navbar.vue"
import BreadCrumb from "@/components/module/breadCrumb";
export default {
  name: "navbar",
  components:{
    BreadCrumb,
    loginNavbar,
    notLoginNavbar
  },
  props:["showGoToTop","seatBoolean","seatNumber","hideBreadCrumb"],
  data(){

  },
  computed:{
    checkIsLogin :function (){
      return auth.isLoginValid()
    }
  }
}
</script>



<style scoped>

</style>