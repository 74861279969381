<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col d-flex justify-content-start">
        <button class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOfSearch">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="collapse" id="collapseOfSearch">
        <form class="container-fluid card p-2">
          <div class="row">
            <label for="sortText" class="col-sm-2 col-form-label">分類</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="sortText" v-model="searchParams.sort">
            </div>
            <label for="titleText" class="col-sm-2 col-form-label">題目名稱</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="titleText" v-model="searchParams.title">
            </div>
          </div>
          <div class="row mt-1">
            <label for="langText" class="col-sm-2 col-form-label">題目數量</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="langText" v-model="searchParams.amount">
            </div>
            <label for="creatorText" class="col-sm-2 col-form-label">建立者</label>
            <div class="col-sm-4">
              <input type="text" class="form-control" id="creatorText" v-model="searchParams.creator">
            </div>
          </div>
          <div class="row mt-2 d-flex justify-content-center">
            <button class="btn col-1 btn-primary mx-2" type="button" @click="fetchFormData">
              <span>查詢</span><i class="fas fa-search ms-1"></i>
            </button>
            <button class="btn col-1 btn-danger mx-2" type="button" @click="resetSearchParams">
              <span>取消</span><i class="fas fa-times ms-1"></i>
            </button>
          </div>

        </form>

      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col" style="width: 10%">#</th>
          <th scope="col" style="width: 15%">分類</th>
          <th scope="col" style="width: 35%">題目</th>
          <th scope="col" style="width: 10%">題數</th>
          <th scope="col" style="width: 15%">建立人</th>
          <th scope="col" style="width: 15%">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(content,index) in formContent" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ content.sort }}</td>
          <td>{{ content.title }}</td>
          <td>{{ content.qAmount }}</td>
          <td>{{ content.creator }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <router-link :to="`/course/${content.courseId}/form/${content.id}/editFormPaper`">
                <button type="button" class="btn btn-outline-primary mx-1 py-0 btn-sm"><i class="fas fa-edit fa-fw"></i></button>
              </router-link>
              <button v-if="false" class="btn btn-outline-warning mx-1 py-0" @click="callDeleteModal(index)"><i class="fas fa-trash-alt"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <modal v-if="deleteModal.show" @close="deleteModal.show = false">
    <template v-slot:header>
      <h5>刪除試卷?</h5>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <p class="fa-warning">要刪除 {{ deleteModal.deleteInfo }} 嗎?</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" @click="deleteFormInfo()">確認</button>
        <button class="btn btn-warning mx-2" @click="deleteModal.show = false">取消</button>
      </div>
    </template>
  </modal>

  <modal v-if="alertModal.show" @close="alertModal.show = false">
    <template v-slot:header>
      <h5>
        <span v-if="alertModal.state === 'normal'" class="text-primary"><i class="fas fa-info-circle fa-1x"></i></span>
        <span v-if="alertModal.state === 'warning'" class="text-warning"><i class="fas fa-times-circle fa-1x"></i></span>
        <span>系統提示</span>
      </h5>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <span>{{ alertModal.alertInfo }}</span>
      </div>

    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" @click="alertModal.show = false">確認</button>
      </div>
    </template>
  </modal>


</template>

<script>
import axios from "axios";
import Modal from "../../module/Modal/index.vue";

export default {
  name: "formTable",
  components:{
    Modal
  },
  data() {
    this.firstFetchFormData()
    const searchParams = {
      sort:"",
      title:"",
      amount:"",
      creator:"",
    }
    const deleteModal = {
      show: false,
      deleteInfo: "",
      processId: undefined
    }
    const alertModal = {
      state: "normal",
      show: false,
      alertInfo: "",
      processId: undefined
    }
    return {
      formContent: [],
      searchParams,
      deleteModal,
      alertModal,
    }
  },
  methods: {
    firstFetchFormData: function () {
      axios.post("/api/system/form/list").then((result) => {
        this.formContent = result.data.formInfo
      })
    },
    fetchFormData: function () {
      let request = {
        sort: this.searchParams.sort,
        title: this.searchParams.title,
        amount: this.searchParams.amount,
        creator: this.searchParams.creator,
      }
      axios.post("/api/system/form/list",request).then((result) => {
        this.formContent = result.data.formInfo
      })
    },
    callDeleteModal: function (deleteId) {
      this.deleteModal.processId = deleteId
      this.deleteModal.deleteInfo = `${this.formContent[this.deleteModal.processId].sort}:${this.formContent[this.deleteModal.processId].title}`
      this.deleteModal.show = true
    },
    deleteFormInfo: function () {
      this.deleteModal.show = false
      axios.post("/api/system/form/delete", {formId: this.formContent[this.deleteModal.processId].id}).then((res) => {
        if (res.data.success) {
          this.alertModal.state = "normal"
          this.alertModal.alertInfo = "刪除成功!"
        } else {
          this.alertModal.state = "warning"
          this.alertModal.alertInfo = "刪除失敗!"
        }
        this.alertModal.show = true
        this.fetchFormData()
      })
    },

    resetSearchParams:function (){
      this.searchParams = {
        sort:"",
        title:"",
        amount:"",
        creator:"",
      }
    }
  }
}
</script>

<style scoped>

</style>