<template>
  <button @click="testUpdate">Update</button>
  <button @click="testGet">Get</button>
  <button @click="testIs">Is</button>
  <button @click="testDate">Date</button>
</template>

<script>
import store from "../../store/auth"
export default {
  name: "lyt Test",
  data(){
    return {
    }
  },
  methods:{
    testUpdate:function (){
      store.setters.updateLoginExpire()
    },
    testGet:function (){
      console.log(store.getters.getLoginExpire())
    },
    testIs:function (){
      console.log(store.getters.isLoginExpire())
    },
    testDate:function(){
      console.log(store.getters.loginExpireToDate())
    }
  },
  computed:{

  }
}
</script>


<style scoped>

</style>