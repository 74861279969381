<template>
  <div class="container">
    <course-menu-collapse collapse-title="尚未開始" :id="0">
      <course-entry-set :entry-data="getOnReady" entry-set-name="underway" entry-set-status="ready"></course-entry-set>
    </course-menu-collapse>
    <course-menu-collapse collapse-title="進行中" :collapse-active="true" :id="1">
      <course-entry-set :entry-data="getUnderWay" entry-set-name="underway" entry-set-status="process"></course-entry-set>
    </course-menu-collapse>
    <course-menu-collapse collapse-title="已結束練習" :id="2">
      <course-entry-set :entry-data="getFinishPractice" entry-set-name="finishPractice" entry-set-status="invalid"></course-entry-set>
    </course-menu-collapse>
    <course-menu-collapse collapse-title="已結束考試" :id="3">
      <course-entry-set :entry-data="getFinishTest" entry-set-name="finishTest" entry-set-status="invalid"></course-entry-set>
    </course-menu-collapse>
  </div>
</template>

<script>
import courseMenuCollapse from "./courseMenuCollapse.vue";
import CourseEntrySet from "./examEntrySet.vue";

export default {
  components: {
    CourseEntrySet,
    courseMenuCollapse,
  },
  name: "courseContainer",
  data()
  {
    return {}
  },
  props: {
    collapseActive: {
      type: Boolean,
      default:
          false
    },
    eventList:{
      type:Array
    }
  },
  computed: {
    getOnReady:function (){
      let result = []
      this.eventList.map((mapItem)=>{
        if(!mapItem.startMatch)
          result.push(mapItem)
      })
      return result
    },
    getUnderWay:function (){
      let result = []
      this.eventList.map((mapItem)=>{
        if(mapItem.startMatch && mapItem.endMatch)
          result.push(mapItem)
      })
      //console.log(result)
      return result
    },
    getFinishTest:function (){
      let result = []
      this.eventList.map((mapItem)=>{
        if(!(mapItem.startMatch && mapItem.endMatch) && !mapItem.endMatch && mapItem.type === "exam")
          result.push(mapItem)
      })
      return result
    },
    getFinishPractice:function (){
      let result = []
      this.eventList.map((mapItem)=>{
        if(!mapItem.endMatch && mapItem.type === "practice")
          result.push(mapItem)
      })
      return result
    }
  }
}
</script>

<style scoped>

</style>