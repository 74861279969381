<template>
  <navbar hide-bread-crumb="true"></navbar>
  <div class="mt-5">
    <h1><i class="fas fa-tools"></i> [510] <i class="fas fa-hammer"></i> </h1>
    <h2>This page still under construction ...</h2>
    <router-link to="/"><button class="btn btn-warning mx-3">回到首頁</button></router-link>
    <button class="btn btn-primary mx-3" @click="$router.go(-1)">回上一頁</button>
  </div>

</template>

<script>
import Navbar from "@/components/module/navbar";

export default {
  name: "page500",
  components: {Navbar}
}
</script>

<style scoped>
h1{
  font-size: 200px;
  font-weight: bolder;
  text-align: center;
}
h2{
  font-size: 100px;
  font-weight: bolder;
  text-align: center;
}
</style>