<template>
  <tbody class="tbody--border">
  <group v-for="(Array,ArrayIndex) in Arrays" :key="ArrayIndex" :="Array" :numberCode="numberCode" :index="ArrayIndex"
         :length="Arrays.length-1" @add-key-word-array="addKeyWordArray" @edit-key-word-array="editKeyWordArray"
         @delete-key-word-array="deleteKeyWordArray"/>
  </tbody>
</template>

<script>
import group from "./group.vue";

export default {
  name: "groupSet",
  emits: ["add-key-word", "edit-key-word", "delete-key-word"],
  components: {
    group,
  },
  props: {
    numberCode: {},
    Arrays: {
      type: Array,
      required: true
    },
  },
  methods: {
    addKeyWordArray(event) {
      this.$emit("add-key-word", {
        addKeyWord: event.addKeyWord,
        addMin: event.addMin,
        addMax: event.addMax,
        indexGroup: this.numberCode,
      });
    },
    editKeyWordArray(event) {
      this.$emit("edit-key-word", {
        newKeyWord: event.newKeyWord,
        newMin: event.newMin,
        newMax: event.newMax,
        indexArray: event.index,
        indexGroup: this.numberCode,
      });
    },
    deleteKeyWordArray(event) {
      this.$emit("delete-key-word", {
        indexArray: event.index,
        indexGroup: this.numberCode,
      });
    },
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

button {
  width: 60px;
}

.inputWidthNumber {
  width: 35px;
  margin: 0 auto;
  border: 0px;
  background-color: #ffffff;
}

.inputWidth {
  width: 80px;
  overflow-x: scroll;
  overflow-y: auto;
  text-overflow: clip;
  margin: 0 auto;
}

.tbody--border{
  border: 1px solid #212529 !important;
}
</style>