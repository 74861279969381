<template>
  <navbar :showGoToTop="true"/>
  <getAllQuiz/>
</template>

<script>
import navbar from "../module/navbar"
import getAllQuiz from "./getAllQuiz.vue";
export default {
  components: {
    navbar,getAllQuiz
  },
  name: "index"
}
</script>

<style scoped>

</style>