<template>
  <div class="join-course-body container ">
    <form method="post" @submit.prevent="submitJoinCourse">
      <div class="row">
        <h1 class="mb-3 fw-bolder">課程邀請碼</h1>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-2 col-form-label fw-bold">邀請碼</label>
        <div class=" col-sm-9">
          <input type="text" id="courseInvitation" v-model="invitation" class="form-control"
                 placeholder="請輸入開課課程的邀請碼" pattern="[a-zA-Z0-9]{1,}" required>
        </div>
      </div>
      <br>
      <div>
        <button type="submit" class="btn btn-primary me-3">加選</button>
        <button type="reset" class="btn btn-warning me-3">清除</button>
      </div>
    </form>

    <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
  </div>
</template>

<script>
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "joinCourse",
  components: {
    AlertModal
  },
  data() {
    const alertModal = {
      show: false
    }
    return {
      alertModal,
      invitation: ""
    }
  },
  methods: {
    submitJoinCourse: async function () {
      await axios.post(`/api/course/joinCourse`, {invitation: this.invitation,}).then((res) => {
        if (res.data.success && res.data.state.exist && !res.data.state.isCreate && res.data.state.isValid) {
          this.alertModal = {
            show: true,
            state: "normal",
            title: "加入成功",
            bodyMsg: "您以加入成功，將返回課程列表",
            crossShow: false,
            confirmEvent: function () {
              this.$router.push(`/course`)
            }
          }
        } else {
          if (res.data.state.exist) {
            if (res.data.state.isCreate && !res.data.state.isValid) {
              this.alertModal = {
                show: true,
                state: "danger",
                title: "加選課程失敗",
                bodyMsg: "您已在此課程中，若課程列表未顯示請詢問老師",
                crossShow: false,
                confirmEvent: function () {
                  this.$router.push(`/course`)
                }
              }
            }
            else if(res.data.state.isCreate && res.data.state.isValid){
              this.alertModal = {
                show: true,
                state: "danger",
                title: "加選課程失敗",
                bodyMsg: "您已在此課程中，請至課程列表檢閱",
                crossShow: false,
                confirmEvent: function () {
                  this.$router.push(`/course`)
                }
              }
            }
            else{
              this.alertModal = {
                show: true,
                state: "danger",
                title: "加選課程失敗",
                bodyMsg: "本加選失敗，請重新加選",
                crossShow: false,
              }
            }
          } else {
            this.alertModal = {
              show: true,
              state: "danger",
              title: "加選課程失敗",
              bodyMsg: "課程邀請碼錯誤，請重新輸入正確邀請碼",
              crossShow: false,
              confirmEvent: function () {
                document.getElementById("courseInvitation").focus();
              }
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
form {
  text-align: center;
  margin: auto;
  padding: 20px;
}

.join-course-body {
  text-align: center;
  margin: auto;
}

.data-brick {
  text-align: center;
  width: 580px;
  margin: auto;
}

label {
  text-align: left;
}
</style>