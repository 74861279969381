import {createRouter, createWebHistory} from 'vue-router'

import page404 from "../components/basicPage/page404.vue";

import basicRouter from "./sort/basicRouter"
import courseRouter from "./sort/courseRouter";
import httpStatusRouter from "./sort/httpStatusRouter";
import systemRouter from "./sort/systemRouter";
import testRouter from "./sort/testRouter";
import userRouter from "./sort/userRouter";




// 路由守衛
const beforeEachGuard = require("./beforeEach")
let routes = []
routes = routes.concat(basicRouter)
routes = routes.concat(courseRouter)
routes = routes.concat(httpStatusRouter)
routes = routes.concat(systemRouter)
routes = routes.concat(testRouter)
routes = routes.concat(userRouter)
routes = routes.concat([
    // 頁面未找到符合項
    {
        path: '/:pathMatch(.*)*',
        component: page404
    }
])



const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// 前端路由防護
router.beforeEach(beforeEachGuard)

export default router
