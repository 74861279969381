<template>
  <navbar/>
  <searchTopicTemplate/>
</template>

<script>
import navbar from "../../module/navbar"
import searchTopicTemplate from "./searchTopicTemplate.vue"

export default {
  name: "index",
  components:{
    navbar,searchTopicTemplate
  }
}
</script>

<style scoped>

</style>