<template>
  <div class="row mt-2" style="height: 100%">
    <div class="col-8">
      <exam-description v-bind="examDetail"></exam-description>
    </div>
    <div class="col-4">
      <exam-solution-info :exam-data="examData"></exam-solution-info>
    </div>
  </div>


  <exam-list :table-data="examData"></exam-list>
</template>

<script>
import examDescription from "./examPanelComponent/examDescription.vue";
import examList from "./examPanelComponent/examList.vue";
import examSolutionInfo from "./examPanelComponent/examSolutionInfo.vue";
import axios from "axios";
import dayjs from "dayjs";

export default {
  name: "examInterface",
  components: {
    examDescription,
    examList,
    examSolutionInfo
  },
  emits: ["loadInterface"],
  data() {
    this.fetchSVData()
    return {
      examDetail:{},
      examData:[],

    }
  },
  methods: {
    fetchSVData:function (){
      axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/list`).then((response)=>{

        if(response.data.success){
          this.examDetail = response.data.info.detail
          this.examData= response.data.info.event
          if(this.examDetail.examStartTime != null)
            this.examDetail.examStartTime = dayjs(new Date(this.examDetail.examStartTime)).format('YYYY/MM/DD A hh:mm:ss')
          else
            this.examDetail.examStartTime = '未限制'
          if(this.examDetail.examEndTime != null)
            this.examDetail.examEndTime = dayjs(new Date(this.examDetail.examEndTime)).format('YYYY/MM/DD A hh:mm:ss')
          else
            this.examDetail.examEndTime = '未限制'

          this.examDetail.examAmount = this.examData.length
          this.$emit("loadInterface", "loadInterface")
        }
        else{
          this.$emit("loadInterface", "failed")
        }

      })
    }
  }
}
</script>

<style scoped>

</style>