<template>
  <div v-if="entryData.length == 0">沒有對應的課程</div>
  <div v-else>
    <exam-entry v-for="(data,index) in entryData" :key="index" :exam-index="entrySetName+index"  :exam-status="entrySetStatus" :="data"></exam-entry>
  </div>

</template>

<script>
import examEntry from "./examEntry.vue"

export default {
  name: "examEntrySet",
  components: {
    examEntry,
  },
  props:{
    entryData:{
      type:Array,
      default:()=>[],
    },
    entrySetName:{
    },
    entrySetStatus:{
      default:"process"
    }
  },
  data() {
    return {
    }
  }
}
</script>


<style scoped>

</style>