<template>
<div ref="ace" :style="aceStyle">

</div>
</template>

<script>
import ace from "ace-builds";
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/theme-clouds_midnight'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-sql'
import 'ace-builds/src-noconflict/mode-mysql'
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-java'
import 'ace-builds/src-noconflict/mode-python'
import 'ace-builds/src-noconflict/mode-csharp'

export default {
  name: "aceEditor",
  props:{
    aceStyle:{
      default:"height:60vh;"
    },
    theme:{
      default: "monokai"
    },
    lang:{
      default: "c_cpp"
    },
    readOnly:{
      type:Boolean,
      default:false,
    },
    value:{
      type:String
    },
    fontSize:{
      type:Number,
      default:14
    },
    tabSize:{
      type:Number,
      default:4
    },
    defaultValue:{
      type:String,
      default:null,
    }

  },
  data(){
    return{
      aceEditor:null,
    }
  },
  mounted() {
    this.aceEditor = ace.edit(this.$refs.ace,{
      fontSize: this.fontSize,
      theme: `ace/theme/${this.theme}`,
      mode: `ace/mode/${this.lang}`,
      tabSize: this.tabSize,
      showPrintMargin: false,
      wrap: true, // cby設定的，當程式超過aceEditor頁面將不繼續延伸而是選擇換行
      indentedSoftWrap: true, // cby設定的，當程式碼換行之後是否預設前面有Tab
      readOnly:this.readOnly,
    })

    // 取得值 設定初始資料
    if(this.value != null || this.value != undefined){
      this.aceEditor.setValue(this.value)
      this.aceEditor.selection.clearSelection()
    }

    // 自動更新值
    this.aceEditor.session.on('change',()=>{
      this.$emit("update:value",this.aceEditor.getValue())
    })
  },
  methods:{
    reloadData : function (value){
      if(value != null || value != undefined){
        this.aceEditor.setValue(value)
        this.aceEditor.selection.clearSelection()
      }
    },
    debug:function (update){
      console.log(update)
    }
  }
}
</script>
<!--  cby修改會影響下層css設定，如有影響請設定scoped回去  -->
<style>

.ace_scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

.ace_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

.ace_scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.ace_scrollbar::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}
</style>