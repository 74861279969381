<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" ref="modal">
        <header class="modal-header">
          <slot name="header">
            <div class="row">
              <label class="fw-bold" style="font-size: 20px">{{ name }}</label>
            </div>
          </slot>
          <button type="button" class="btn-close" @click="close"></button>
        </header>

        <section class="modal-body">
          <slot name="body">
            <div v-for="(data,index) in content" :key="index">
              <div class="container border border-dark shadow--">

                <dd>
                  <dl class="row">
                    <dt class="col-auto px-0" style="font-size: 18px;">{{ index + 1 }}.</dt>
                    <dd class="col" style="font-size: 18px;text-align:justify;text-justify:inter-word;">{{ data.heading }}</dd>
                  </dl>
                </dd>
                <div>
                  <label class="col-6 col-form-label text-success">此題分數({{ data.point }}分)</label>
                </div>

                <div class="form-check" v-for="(dataAnswer,indexAnswer) in data.answer" :key="indexAnswer"
                     :style="[(data.type == 'filling')?'padding:0;':'']">
                  <div v-if="data.type == 'oneSelect'">
                    <input class="form-check-input" type="radio" :name="'question'+index">
                    <label class="form-check-label">{{ascii(indexAnswer)}}.{{ dataAnswer.content }}</label>
                  </div>

                  <div v-if="data.type == 'moreSelect'">
                    <input class="form-check-input" type="checkbox" :name="'question'+index">
                    <label class="form-check-label">{{ascii(indexAnswer)}}. {{ dataAnswer.content }}</label>
                  </div>

                  <div v-if="data.type == 'filling'" class="form-group row">
                    <label class="col-form-label fw-bold col-1">Ans:&nbsp;</label>
                    <div class="col-11">
                      <input type="text" class="form-control">
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">

          </slot>
          <button type="button" class="btn btn-outline-primary" @click="close">
            <i class="fas fa-check-circle"></i>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkProduct",
  props: {
    name: {},
    content: {
      default: [],
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$refs.modal.scrollTop = 0
    },
    ascii:function (indexAnswer){
      return String.fromCharCode(65 + indexAnswer)
    },
    show(index){
      //console.log(index)
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  max-height: 90vh;
  overflow: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}


.modal-body {
  text-align: left;
  font-size: 16px;
  margin: 20px 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.radio[disabled] {
  background-color: #FFFFFF;
}
</style>