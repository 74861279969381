<template>
  <div class="col-lg-3">
    <div class="card border-2 my-3 mx-3 course-box">
      <router-link :to="hrefLink">
        <div class="card-body">
          <h4 class="card-title">{{department}}</h4>
          <h3 class="card-title"><strong>{{ courseName }}</strong></h3>
          <h5 class="card-title">指導教授: {{teacher}}</h5>
          <h5 class="card-title">學年: {{semester}} {{term}}</h5>
          <h5 class="card-title">授課班級: {{teachClass}}</h5>

        </div>
      </router-link>
    </div>
  </div>

</template>

<script>
export default {
  name: "classBox",
  props:{
    courseInformation:{
      type:Object,
      required : true,
    }
  },
  data(){
    return{
      hrefLink : this.courseInformation.hrefLink,
      department : this.courseInformation.department,
      courseName : this.courseInformation.courseName,
      teacher : this.courseInformation.teacher,
      semester :this.courseInformation.semester,
      term:this.courseInformation.term,
      teachClass : this.courseInformation.teachClass,
    }
  }
}
</script>

<style scoped>
.course-box{
  width: 100%;
  height: 16rem;
  display: inline-block;
  padding: 10px;
}
.course-box:hover{
  background-color: rgba(217, 217, 217, 0.7);
}
a{
  text-decoration: none;
  color:inherit;
}
</style>