<template>
  <div class="from--form">
    <form v-if="!loading">
      <h5 v-if="mode == 'create'" class="fw-bold" style="text-align: left;font-size: 26px">創建考試模板</h5>
      <h5 v-if="mode == 'edit'" class="fw-bold" style="text-align: left;font-size: 26px">修改考試模板</h5>

      <div class="border border-dark shadow--">
        <div class="row">
          <label for="paperName" class="col-3 col-form-label fw-bold label--topic">考卷名稱</label>
          <input type="text" class="col-9 form-control input--topic" id="paperName"
                 v-model="paper.paperName" :placeholder="'第一次考試'">
        </div>
        <div v-if="paperNameError" class="row mb-sm-1 text-start">
          <small class="label--topic"></small>
          <small class="input--topic text-danger">{{ paperNameErrorMessage }}</small>
        </div>

        <div class="row">
          <label for="paperIllustrate" class="col-3 col-form-label fw-bold">考卷說明</label>
          <textarea class="col-9 form-control input--topic" id="paperIllustrate" rows="3"
                    v-model="paper.paperIllustrate" :placeholder="'考卷說明'"></textarea>
        </div>
        <div v-if="paperIllustrateError" class="row mb-sm-1 text-start">
          <small class="label--topic"></small>
          <small class="input--topic text-danger">試卷說明請勿為空</small>
        </div>

        <div class="row">
          <label for="paperSort" class="col-3 col-form-label fw-bold">試卷分類</label>
          <input type="text" class="col-9 form-control input--topic" id="paperSort"
                 v-model="paper.paperSort" :placeholder="'程式設計'">
        </div>
      </div>

      <div v-for="(formTopic,formTopicIndex) in formTopics" :key="formTopicIndex">
        <div class="border border-dark shadow--">
          <div class="row">
            <label class="col-2 col-form-label fw-bold label--topic--type--select">題型</label>
            <select class="col-2 form-select input--topic--type--select" v-model="formTopics[formTopicIndex].type"
                    @change="this.formTopics[formTopicIndex].errorAns = '';
                    this.formTopicsLeastErrorAns[formTopicIndex] = false;
                    this.formTopics[formTopicIndex].optionsAmount = 1">
              <option disabled value="">題型</option>
              <option value="select">選擇題</option>
              <option value="filling">填充題</option>
            </select>
            <label class="col-2 col-form-label fw-bold label--topic--type">分數</label>
            <div class="col-2 input-group input--topic--type">
              <input type="number" class="form-control" :placeholder="5" v-model="formTopics[formTopicIndex].point">
            </div>
            <label class="col-2 col-form-label fw-bold label--topic--type">顯示題數</label>
            <div class="col-2 input-group input--topic--type">
              <input type="number" class="form-control" :placeholder="5"
                     v-model="formTopics[formTopicIndex].optionsAmount"
                     :disabled="formTopics[formTopicIndex].type =='filling'">
            </div>
          </div>
          <div
              v-if="formTopics[formTopicIndex].point < 0 || formTopics[formTopicIndex].point === '' ||
              formTopics[formTopicIndex].optionsAmount < 1 || formTopics[formTopicIndex].optionsAmount > maxOptionsAmount ||
              formTopics[formTopicIndex].optionsAmount === ''"
              class="row mb-sm-1 text-start">
            <small class="label--topic--type--select"></small>
            <small class="input--topic--type--select"></small>
            <small class="label--topic--type"></small>
            <small v-if="formTopics[formTopicIndex].point < 0" class="input--topic--type text-danger">分數請勿為負</small>
            <small v-else-if="formTopics[formTopicIndex].point === ''"
                   class="input--topic--type text-danger">請勿為空</small>
            <small v-else class="input--topic--type"></small>
            <small class="label--topic--type"></small>
            <small v-if="formTopics[formTopicIndex].type != 'filling' && formTopics[formTopicIndex].optionsAmount < 0"
                   class="input--topic--type text-danger">顯示選項請勿為負</small>
            <small v-if="formTopics[formTopicIndex].type != 'filling' && formTopics[formTopicIndex].optionsAmount == 0"
                   class="input--topic--type text-danger">顯示選項請勿為零</small>
            <small v-if="formTopics[formTopicIndex].type != 'filling' && formTopics[formTopicIndex].optionsAmount === ''"
                   class="input--topic--type text-danger">請勿為空</small>
            <small v-if="formTopics[formTopicIndex].type != 'filling' && formTopics[formTopicIndex].optionsAmount > maxOptionsAmount"
                   class="input--topic--type text-danger">請勿超過{{ maxOptionsAmount }}個選項</small>
          </div>

          <div>
            <div class="form-group row">
              <label class="col-sm-2 col-form-label fw-bold label--topic">題目</label>
              <div class="col-sm-10 input-group input--topic">
                <textarea type="text" class="form-control" rows="3"
                       placeholder="this %s = ?(若要隨機出題請用%s)" v-model="formTopics[formTopicIndex].question"
                       @change="formTopicEditChangeAnsFormat(formTopicIndex)"></textarea>
              </div>
            </div>
            <div v-if="formTopicsQuestionError[formTopicIndex]" class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger">題目請勿為空</small>
            </div>

            <div v-for="(formTopicAns,formTopicAnsIndex) in formTopic.ans" :key="formTopicAnsIndex">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label fw-bold label--topic">隨機答案{{ formTopicAnsIndex + 1 }}</label>
                <div class="col-sm-10 input-group input--topic">
                  <input type="text" class="form-control" placeholder="[args,args2],[answer1,answer2]"
                         v-model="formTopics[formTopicIndex].ans[formTopicAnsIndex]"
                         @change="editFormTopicAns(formTopicIndex,formTopicAnsIndex)">
                  <button v-if="formTopic.ans.length == (formTopicAnsIndex+1) && !formTopicsAddInputAns[formTopicIndex]"
                          class="btn btn-outline-primary border" type="button"
                          @click="formTopicsAddInputAns[formTopicIndex] = true">
                    <i class="fas fa-plus-square"></i>
                  </button>
                  <button
                      v-if="formTopic.ans.length != 1 || formTopic.ans.length != (formTopicAnsIndex+1) && !formTopicsAddInputAns[formTopicIndex]"
                      class="btn btn-outline-danger border" type="button"
                      @click="deleteEditFormTopicsAns(formTopicIndex,formTopicAnsIndex)">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
              <div v-if="formTopicsEditInputAnsError[formTopicIndex][formTopicAnsIndex]" class="row mb-sm-1 text-start">
                <small class="label--topic"></small>
                <small class="input--topic text-danger">請勿為空</small>
              </div>
              <div v-if="formTopicsEditInputAnsFormatError[formTopicIndex][formTopicAnsIndex]"
                   class="row mb-sm-1 text-start">
                <small class="label--topic"></small>
                <small class="input--topic text-danger fw-bold">格式錯誤</small>
              </div>
            </div>

            <div v-if="formTopicsAddInputAns[formTopicIndex]" class="form-group row">
              <label class="col-sm-2 col-form-label fw-bold label--topic">新增隨機答案</label>
              <div class="col-sm-10 input-group input--topic">
                <input type="text" class="form-control"
                       placeholder="[args,args2],[answer1,answer2]" v-model="formTopicsInputAns[formTopicIndex]"
                       @change="editNewFormTopicAns(formTopicIndex)">
                <button class="btn btn-outline-primary border" type="button"
                        @click="addFormTopicsAns(formTopicIndex)">
                  <i class="fas fa-check"></i>
                </button>
                <button class="btn btn-outline-danger border" type="button"
                        @click="deleteFormTopicsAns(formTopicIndex)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
            <div v-if="formTopicsLeastRightAns[formTopicIndex]" class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger">請勿為空，若無需新增請刪除</small>
            </div>
            <div v-if="formTopicsLeastRightAnsFormat[formTopicIndex]" class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger fw-bold">格式錯誤，若無需新增請刪除</small>
            </div>

            <div v-if="formTopic.type != 'filling'" class="form-group row">
              <label class="col-sm-2 col-form-label fw-bold label--topic">錯誤選項</label>
              <div class="col-sm-10 input-group input--topic">
                <input type="text" class="form-control"
                       placeholder="option1,option2,option3" v-model="formTopics[formTopicIndex].errorAns"
                       @change="this.formTopicsLeastErrorAns[formTopicIndex] = (this.formTopics[formTopicIndex].errorAns == '') ? true : false">
              </div>
            </div>
            <div v-if="formTopicsLeastErrorAns[formTopicIndex] && formTopic.type != 'filling'"
                 class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger">最少要有一個正確答案，請勿為空</small>
            </div>

            <div>
              <button type="button" class="btn btn-outline-danger me-3" @click="deleteFormTopic(formTopicIndex)">
                <i class="far fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="border border-dark shadow--">
        <div class="row">
          <label class="col-2 col-form-label fw-bold label--topic--type--select">題型</label>
          <select class="col-2 form-select input--topic--type--select" v-model="topic.type"
                  @change="topic.optionsAmount = (topic.type == 'filling') ? 1 :topic.optionsAmount">
            <option disabled value="">題型</option>
            <option value="select">選擇題</option>
            <option value="filling">填充題</option>
          </select>
          <label class="col-2 col-form-label fw-bold label--topic--type">分數</label>
          <div class="col-2 input-group input--topic--type">
            <input type="number" class="form-control" :placeholder="5" v-model="topic.point">
          </div>
          <label class="col-2 col-form-label fw-bold label--topic--type">顯示題數</label>
          <div class="col-2 input-group input--topic--type">
            <input type="number" class="form-control" :placeholder="5" v-model="topic.optionsAmount"
                   :disabled="topic.type == 'filling'">
          </div>
        </div>

        <div
            v-if="topic.point < 0 || topic.point === '' || topic.optionsAmount < 1 || topic.optionsAmount > maxOptionsAmount || topic.optionsAmount === ''"
            class="row mb-sm-1 text-start">
          <small class="label--topic--type--select"></small>
          <small class="input--topic--type--select"></small>
          <small class="label--topic--type"></small>
          <small v-if="topic.point < 0" class="input--topic--type text-danger">分數請勿為負</small>
          <small v-else-if="topic.point === ''" class="input--topic--type text-danger">請勿為空</small>
          <small v-else class="input--topic--type"></small>
          <small class="label--topic--type"></small>
          <small v-if="topic.type != 'filling' && topic.optionsAmount < 0"
                 class="input--topic--type text-danger">顯示選項請勿為負</small>
          <small v-if="topic.type != 'filling' && topic.optionsAmount == 0"
                 class="input--topic--type text-danger">顯示選項請勿為零</small>
          <small v-if="topic.type != 'filling' && topic.optionsAmount === ''"
                 class="input--topic--type text-danger">請勿為空</small>
          <small v-if="topic.type != 'filling' && topic.optionsAmount > maxOptionsAmount" class="input--topic--type text-danger">請勿超過{{maxOptionsAmount}}個選項</small>
        </div>

        <div v-if="topic.type != ''">
          <div class="form-group row">
            <label class="col-sm-2 col-form-label fw-bold label--topic">題目</label>
            <div class="col-sm-10 input-group input--topic">
              <textarea type="text" class="form-control" rows="3"
                     placeholder="this %s = ?(若要隨機出題請用%s)" v-model="topic.question"
                     @change="changeTopicQuestionFormatAns"></textarea>
            </div>
          </div>
          <div v-if="questionError" class="row mb-sm-1 text-start">
            <small class="label--topic"></small>
            <small class="input--topic text-danger">題目請勿為空</small>
          </div>

          <div v-for="(topicAns,index) in topic.ans" :key="index">
            <div class="form-group row">
              <label class="col-sm-2 col-form-label fw-bold label--topic">隨機答案{{ index }}</label>
              <div class="col-sm-10 input-group input--topic">
                <input type="text" class="form-control" v-model="editInputAns[index]" @change="editTopicAns(index)">
                <button class="btn btn-outline-danger border" type="button" @click="deleteTopicAns(index)">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            </div>
            <div v-if="editInputAnsError[index]" class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger">請勿為空</small>
            </div>
            <div v-if="editInputAnsFormatError[index]" class="row mb-sm-1 text-start">
              <small class="label--topic"></small>
              <small class="input--topic text-danger fw-bold">格式錯誤，請填寫正確格式</small>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-sm-2 col-form-label fw-bold label--topic">新增隨機答案</label>
            <div class="col-sm-10 input-group input--topic">
              <input type="text" class="form-control"
                     placeholder="[args1,args2],[answer1,answer2]" v-model="inputAns"
                     @change="addAnsFormat">
              <button class="btn btn-outline-primary border" type="button" @click="addAns">
                <i class="fas fa-plus-square"></i>
              </button>
            </div>
          </div>
          <div v-if="inputAnsError" class="row mb-sm-1 text-start">
            <small class="label--topic"></small>
            <small class="input--topic text-danger">請勿為空</small>
          </div>
          <div v-if="inputAnsFormatError" class="row mb-sm-1 text-start">
            <small class="label--topic"></small>
            <small class="input--topic text-danger fw-bold">格式錯誤，請填寫正確格式</small>
          </div>
          <div v-if="leastRightAns" class="row mb-sm-1 text-start">
            <small class="label--topic"></small>
            <small class="input--topic text-danger">最少要有一個正確答案，請勿為空</small>
          </div>

          <div v-if="topic.type != 'filling'" class="form-group row">
            <label class="col-sm-2 col-form-label fw-bold label--topic">錯誤選項</label>
            <div class="col-sm-10 input-group input--topic">
              <input type="text" class="form-control"
                     placeholder="option1,option2,option3" v-model="topic.errorAns"
                     @change="this.leastErrorAns = (this.topic.errorAns == '') ? true : false">
            </div>
          </div>
          <div v-if="leastErrorAns && topic.type != 'filling'" class="row mb-sm-1 text-start">
            <small class="label--topic"></small>
            <small class="input--topic text-danger">最少要有一個正確答案，請勿為空</small>
          </div>

          <div>
            <button type="button" class="btn btn-outline-info me-3" @click="addTopic">
              <i class="fas fa-check-circle"></i>
            </button>
            <button type="button" class="btn btn-outline-danger me-3" @click="deleteTopic">
              <i class="far fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <div v-if="createError" class="me-3 my-2">
        <small class="text-danger">{{ createErrorMessage }}</small>
      </div>
      <div v-if="this.topic.type == '' && this.formTopics.length != 0">
        <button v-if="mode == 'create'" type="button" class="btn btn-outline-info me-3" @click="createFormTopic">
          <i class="fas fa-check-circle"></i>
        </button>
        <button v-if="mode == 'edit'" type="button" class="btn btn-outline-info me-3" @click="editFormTopicTemplate">
          <i class="fas fa-check-circle"></i>
        </button>
        <button type="button" class="btn btn-outline-danger me-3" @click="deleteAllFormTopic">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>

    </form>

    <form v-if="loading">
      <h5 v-if="mode == 'create'" class="fw-bold" style="text-align: left;font-size: 26px">創建考試模板</h5>
      <h5 v-if="mode == 'edit'" class="fw-bold" style="text-align: left;font-size: 26px">修改考試模板</h5>
      <div class="border border-dark shadow--">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </form>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "formPaper",
  components: {AlertModal},
  props: {
    mode: {type: String},
  },
  created(){
    if(this.mode == 'edit') {
      this.alertModal = {
        show: true,
        state: "loading",
        title: "資料讀取中",
        crossShow:false,
        confirmShow:false
      }
      this.loading = true
      axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.formId}/getFormPaper`).then((res)=>{
        if(res.data.success) {
          this.paper = res.data.formExamMould.paper
          //console.log('content:',res.data.formExamMould.content)
          this.formTopics = res.data.formExamMould.content
          for(let i = 0;i<this.formTopics.length;i++){
            this.formTopicsQuestionError.push(false)
            this.formTopicsEditInputAnsError.push([])
            this.formTopicsEditInputAnsFormatError.push([])
            for(let j=0;j<this.formTopics[i].ans.length;j++){
              this.editInputAnsError.push(false)
            }
            this.editInputAnsError.forEach(element => {
              this.formTopicsEditInputAnsError[this.formTopicsEditInputAnsError.length - 1].push(element)
              this.formTopicsEditInputAnsFormatError[this.formTopicsEditInputAnsFormatError.length - 1].push(element)
            });
            this.formTopicsLeastErrorAns.push(false)
            this.formTopicsAddInputAns.push(false)
            this.formTopicsInputAns.push("")
            this.formTopicsLeastRightAns.push(false)
            this.formTopicsLeastRightAnsFormat.push(false)
          }
          this.loading = false
          this.alertModal = {show: false,}
        }
      })
    }

  },
  data() {
    const alertModal = {
      show: false
    }
    return {
      alertModal,
      loading:false,
      topic: {
        type: "",
        question: "",
        point: 0,
        optionsAmount: 1,
        ans: [],
        errorAns: ""
      },
      maxOptionsAmount:10,
      editInputAns: [],
      editInputAnsError: [],
      editInputAnsFormatError: [],
      inputAns: "",
      inputAnsError: false,
      inputAnsFormatError: false,
      paper: {
        paperName: "",
        paperIllustrate: "",
        paperSort: ""
      },
      randomQuestion: false,
      formTopics: [],
      leastRightAns: false,//至少要有一個正確答案的警告
      leastErrorAns: false,//至少要有一個錯誤答案的警告
      questionError: false,//至少要有一個題目的警告

      formTopicsQuestionError: [],//題目的警告 已建立有幾題就有幾個index
      formTopicsEditInputAnsError: [],//隨機答案的警告
      formTopicsEditInputAnsFormatError: [],//隨機答案格式的警告
      formTopicsLeastErrorAns: [],//錯誤答案的警告 已建立有幾題就有幾個index
      formTopicsAddInputAns: [],//已建立題目中是否顯示新增隨機答案v-model的矩陣
      formTopicsInputAns: [],//已建立題目中的新增隨機答案v-model
      formTopicsLeastRightAns: [],//formTopicsInputAns的警告 已建立有幾題就有幾個index
      formTopicsLeastRightAnsFormat: [],//formTopicsInputAns的格式警告 已建立有幾題就有幾個index

      //創建的error提示
      createError: false,
      createErrorMessage: "",
      paperNameError: false,
      paperNameErrorMessage: "",
      paperIllustrateError: false,
    }
  },
  methods: {
    addAns() {
      if (this.topic.question.indexOf('%s') != -1) {
        if (this.inputAns !== "") {
          // eslint-disable-next-line
          let ansString = this.inputAns.split(/(\[[^\[\]]+\]\s*)/)
          let ansArray = []
          ansString.forEach(forEachItem => {
            if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
          })

          if (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") {
            this.inputAnsFormatError = false
            this.topic.ans.push(this.inputAns)
            this.editInputAns.push(this.inputAns)
            this.editInputAnsError.push(false)
            this.editInputAnsFormatError.push(false)
            this.inputAnsError = false
            this.inputAns = ""
          } else this.inputAnsFormatError = true
        } else this.inputAnsError = true
      } else {
        if (this.inputAns !== "") {
          this.inputAnsFormatError = false
          this.topic.ans.push(this.inputAns)
          this.editInputAns.push(this.inputAns)
          this.editInputAnsError.push(false)
          this.editInputAnsFormatError.push(false)
          this.inputAnsError = false
          this.inputAns = ""
        } else this.inputAnsError = true
      }
    },
    addAnsFormat() {
      this.leastRightAns = (this.topic.ans.length == 0 && this.inputAns == '') ? true : false
      if (this.topic.question.indexOf('%s') != -1) {
        //console.log(123)
        if (this.inputAns !== "") {
          // eslint-disable-next-line
          let ansString = this.inputAns.split(/(\[[^\[\]]+\]\s*)/)
          let ansArray = []
          ansString.forEach(forEachItem => {
            if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
          })

          if (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") {
            this.inputAnsFormatError = false
            this.inputAnsError = false
          } else this.inputAnsFormatError = true
        } else this.inputAnsError = true
      } else this.inputAnsFormatError = false
    },
    changeTopicQuestionFormatAns() {
      this.questionError = (this.topic.question == '') ? true : false
      for (let i = 0; i < this.topic.ans.length; i++)
        this.editTopicAns(i)
    },
    editTopicAns(index) {
      if (this.editInputAns[index] !== "") {
        if (this.topic.question.indexOf('%s') != -1) {

          // eslint-disable-next-line
          let ansString = this.editInputAns[index].split(/(\[[^\[\]]+\]\s*)/)
          let ansArray = []
          ansString.forEach(forEachItem => {
            if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
          })

          if (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") {
            this.topic.ans[index] = this.editInputAns[index]
            this.editInputAnsError[index] = false
            this.editInputAnsFormatError[index] = false
          } else this.editInputAnsFormatError[index] = true
        } else {
          this.topic.ans[index] = this.editInputAns[index]
          this.editInputAnsError[index] = false
          this.editInputAnsFormatError[index] = false
        }
      } else this.editInputAnsError[index] = true
    },
    deleteTopicAns(index) {
      this.topic.ans.splice(index, 1)
      this.editInputAns.splice(index, 1)
      this.editInputAnsError.splice(index, 1)
    },
    deleteTopic() {
      let reloadTopic = {
        type: "",
        question: "",
        point: 0,
        optionsAmount: 1,
        ans: [],
        errorAns: ""
      }
      this.topic = reloadTopic
      this.editInputAns = []
      this.editInputAnsError = []
      this.inputAns = ""
      this.inputAnsError = false
      this.leastRightAns = false
      this.leastErrorAns = false
      this.questionError = false
    },
    addTopic() {
      //若editInputAnsError裡面有true代表有欄位為空那麼不可提交
      let allEditError = (this.editInputAnsError.indexOf(true) == -1) ? false : true
      let allEditFormatError = (this.editInputAnsFormatError.indexOf(true) == -1) ? false : true
      this.leastRightAns = (this.topic.ans.length == 0 && this.inputAns == "") ? true : false
      this.leastErrorAns = (this.topic.errorAns == "" && this.topic.type != "filling") ? true : false
      this.questionError = (this.topic.question == "") ? true : false
      let pointError = (this.topic.point < 0 || this.topic.point === '') ? true : false
      let optionsAmountError = (this.topic.type == 'select' && this.topic.optionsAmount < 1 || this.topic.optionsAmount === '' || this.topic.optionsAmount > this.maxOptionsAmount) ? true : false

      if (!allEditError && !allEditFormatError && !this.leastRightAns && !this.leastErrorAns && !this.questionError && !pointError && !optionsAmountError) {
        this.addAns()
        if (!this.inputAnsFormatError) {
          this.formTopics.push(this.topic)
          this.formTopicsQuestionError.push(false)
          this.formTopicsEditInputAnsError.push([])
          this.formTopicsEditInputAnsFormatError.push([])
          //console.log('editInputAnsError:',this.editInputAnsError)
          this.editInputAnsError.forEach(element => {
            this.formTopicsEditInputAnsError[this.formTopicsEditInputAnsError.length - 1].push(element)
            this.formTopicsEditInputAnsFormatError[this.formTopicsEditInputAnsFormatError.length - 1].push(element)
          });
          //console.log('formTopicsEditInputAnsError:',this.formTopicsEditInputAnsError)
          //console.log('formTopicsEditInputAnsFormatError:',this.formTopicsEditInputAnsFormatError)
          this.formTopicsLeastErrorAns.push(false)
          this.formTopicsAddInputAns.push(false)
          this.formTopicsInputAns.push("")
          this.formTopicsLeastRightAns.push(false)
          this.formTopicsLeastRightAnsFormat.push(false)
          this.deleteTopic()
        }
      }
    },
    deleteEditFormTopicsAns(formTopicIndex, formTopicAnsIndex) {
      this.formTopics[formTopicIndex].ans.splice(formTopicAnsIndex, 1)
      this.formTopicsEditInputAnsError[formTopicIndex].splice(formTopicAnsIndex, 1)
      this.formTopicsEditInputAnsFormatError[formTopicIndex].splice(formTopicAnsIndex, 1)
    },
    formTopicEditChangeAnsFormat(formTopicIndex) {
      this.formTopicsQuestionError[formTopicIndex] = (this.formTopics[formTopicIndex].question == '') ? true : false

      for (let i = 0; i < this.formTopics[formTopicIndex].ans.length; i++)
        this.editFormTopicAns(formTopicIndex, i)
    },
    editFormTopicAns(formTopicIndex, formTopicAnsIndex) {
      this.formTopicsEditInputAnsError[formTopicIndex][formTopicAnsIndex] = (this.formTopics[formTopicIndex].ans[formTopicAnsIndex] == '') ? true : false
      if (this.formTopics[formTopicIndex].question.indexOf('%s') != -1) {
        // eslint-disable-next-line
        let ansString = this.formTopics[formTopicIndex].ans[formTopicAnsIndex].split(/(\[[^\[\]]+\]\s*)/)
        let ansArray = []
        ansString.forEach(forEachItem => {
          if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
        })
        this.formTopicsEditInputAnsFormatError[formTopicIndex][formTopicAnsIndex] = (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") ? false : true
      } else this.formTopicsEditInputAnsFormatError[formTopicIndex][formTopicAnsIndex] = false
    },
    editNewFormTopicAns(formTopicIndex) {
      this.formTopicsLeastRightAns[formTopicIndex] = (this.formTopicsInputAns[formTopicIndex] == '') ? true : false

      if (this.formTopics[formTopicIndex].question.indexOf('%s') != -1) {
        // eslint-disable-next-line
        let ansString = this.formTopicsInputAns[formTopicIndex].split(/(\[[^\[\]]+\]\s*)/)
        let ansArray = []
        ansString.forEach(forEachItem => {
          if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
        })
        this.formTopicsLeastRightAnsFormat[formTopicIndex] = (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") ? false : true
      } else this.formTopicsLeastRightAnsFormat[formTopicIndex] = false
    },
    addFormTopicsAns(index) {
      if (this.formTopicsInputAns[index] != "") {
        if (this.formTopics[index].question.indexOf('%s') != -1) {
          // eslint-disable-next-line
          let ansString = this.formTopicsInputAns[index].split(/(\[[^\[\]]+\]\s*)/)
          let ansArray = []
          ansString.forEach(forEachItem => {
            if (forEachItem.indexOf('[') != -1 && forEachItem.indexOf(']') != -1) ansArray.push(forEachItem)
          })
          if (ansString.length == 5 && ansArray.length == 2 && ansString[2] == ',' && ansString[0] == "" && ansString[4] == "") {
            this.formTopicsLeastRightAnsFormat[index] = false
            this.formTopics[index].ans.push(this.formTopicsInputAns[index])
            this.formTopicsEditInputAnsError[index].push(false)
            this.formTopicsEditInputAnsFormatError[index].push(false)
            this.deleteFormTopicsAns(index)
          } else this.formTopicsLeastRightAnsFormat[index] = true
        } else {
          this.formTopicsLeastRightAnsFormat[index] = false
          this.formTopics[index].ans.push(this.formTopicsInputAns[index])
          this.formTopicsEditInputAnsError[index].push(false)
          this.formTopicsEditInputAnsFormatError[index].push(false)
          this.deleteFormTopicsAns(index)
        }
      } else this.formTopicsLeastRightAns[index] = true
    },
    deleteFormTopicsAns(index) {
      this.formTopicsAddInputAns[index] = false
      this.formTopicsInputAns[index] = ""
      this.formTopicsLeastRightAns[index] = false
      this.formTopicsLeastRightAnsFormat[index] = false
    },
    deleteFormTopic(index) {
      this.formTopics.splice(index, 1)
      this.formTopicsQuestionError.splice(index, 1)
      this.formTopicsEditInputAnsError.splice(index, 1)
      this.formTopicsEditInputAnsFormatError.splice(index, 1)
      this.formTopicsLeastErrorAns.splice(index, 1)
      this.formTopicsAddInputAns.splice(index, 1)
      this.formTopicsInputAns.splice(index, 1)
      this.formTopicsLeastRightAns.splice(index, 1)
      this.formTopicsLeastRightAnsFormat.splice(index, 1)
    },
    createFormTopic() {
      this.paperNameError = (this.paper.paperName == "") ? true : false
      this.paperIllustrateError = (this.paper.paperIllustrate == "") ? true : false

      this.paperNameErrorMessage = (this.paperNameError) ? "試卷名稱請勿為空" : ""

      let allCreateError = (this.formTopicsQuestionError.indexOf(true) == -1 &&
          this.formTopicsLeastErrorAns.indexOf(true) == -1 &&
          this.formTopicsAddInputAns.indexOf(true) == -1 &&
          this.formTopicsLeastRightAns.indexOf(true) == -1 &&
          this.formTopicsLeastRightAnsFormat.indexOf(true) == -1) ? false : true
      this.formTopicsEditInputAnsError.forEach(element => {
        if (element.indexOf(true) != -1) allCreateError = true
      });
      this.formTopicsEditInputAnsFormatError.forEach(element => {
        if (element.indexOf(true) != -1) allCreateError = true
      });

      for (let i = 0; i < this.formTopics.length; i++) {
        if (this.formTopics[i].point < 0 || this.formTopics[i].point === '')
          allCreateError = true
      }
      for (let i = 0; i < this.formTopics.length; i++) {
        if (this.formTopics[i].type == 'select' && this.formTopics[i].optionsAmount < 1 || this.formTopics[i].optionsAmount > this.maxOptionsAmount || this.formTopics[i].optionsAmount === '')
          allCreateError = true
      }

      //console.log('allCreateError:', allCreateError)

      if (!allCreateError && !this.paperNameError && !this.paperIllustrateError) {
        this.createError = false
        this.createErrorMessage = ""

        axios.post("/api/paper/checkFormPaper", {
          paper: this.paper,
          formTopics: this.formTopics,
        }).then((res) => {
          this.createError = !res.data.success
          if (this.createError) this.createErrorMessage = "試卷第" + (res.data.falseIndex + 1) + "題格式不對，請再檢查一次是否填寫錯誤"
          else {
            //console.log("yes")
            axios.post(`/api/paper/${this.$route.params.courseId}/createFormPaper`, {
              paper: this.paper,
              formTopics: this.formTopics,
            }).then((res) => {
              if (res.data.success) {
                this.deleteAllFormTopic()
                this.alertModal = {
                  show: true,
                  state: "normal",
                  title: "創建成功",
                  bodyMsg: "您以創建成功，將返回課程頁面",
                  crossShow: false,
                  confirmEvent: function () {
                    this.$router.push(`/course/${this.$route.params.courseId}`)
                  }
                }
              } else {
                this.alertModal = {
                  show: true,
                  state: "danger",
                  title: "創建失敗",
                  bodyMsg: "本創建失敗，請重新創建",
                  crossShow: false,
                }
              }
            })
          }
        })

      } else {
        this.createError = true
        this.createErrorMessage = "請檢查試卷是否有哪邊尚未填寫完成"
      }

    },
    editFormTopicTemplate(){
      this.paperNameError = (this.paper.paperName == "") ? true : false
      this.paperIllustrateError = (this.paper.paperIllustrate == "") ? true : false

      this.paperNameErrorMessage = (this.paperNameError) ? "試卷名稱請勿為空" : ""

      let allCreateError = (this.formTopicsQuestionError.indexOf(true) == -1 &&
          this.formTopicsLeastErrorAns.indexOf(true) == -1 &&
          this.formTopicsAddInputAns.indexOf(true) == -1 &&
          this.formTopicsLeastRightAns.indexOf(true) == -1 &&
          this.formTopicsLeastRightAnsFormat.indexOf(true) == -1) ? false : true
      this.formTopicsEditInputAnsError.forEach(element => {
        if (element.indexOf(true) != -1) allCreateError = true
      });
      this.formTopicsEditInputAnsFormatError.forEach(element => {
        if (element.indexOf(true) != -1) allCreateError = true
      });

      for (let i = 0; i < this.formTopics.length; i++) {
        if (this.formTopics[i].point < 0 || this.formTopics[i].point === '')
          allCreateError = true
      }
      for (let i = 0; i < this.formTopics.length; i++) {
        if (this.formTopics[i].type == 'select' && this.formTopics[i].optionsAmount < 1 || this.formTopics[i].optionsAmount > this.maxOptionsAmount || this.formTopics[i].optionsAmount === '')
          allCreateError = true
      }

      if (!allCreateError && !this.paperNameError && !this.paperIllustrateError && this.mode == 'edit') {
        this.createError = false
        this.createErrorMessage = ""

        axios.post("/api/paper/checkFormPaper", {
          paper: this.paper,
          formTopics: this.formTopics,
        }).then((res) => {
          this.createError = !res.data.success
          if (this.createError) this.createErrorMessage = "試卷第" + (res.data.falseIndex + 1) + "題格式不對，請再檢查一次是否填寫錯誤"
          else {
           // console.log("yes")
            axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.formId}/editFormPaper`, {
              paper: this.paper,
              formTopics: this.formTopics,
            }).then((res) => {
              if (res.data.success) {
                this.deleteAllFormTopic()
                this.alertModal = {
                  show: true,
                  state: "normal",
                  title: "修改成功",
                  bodyMsg: "您以修改成功，將返回課程頁面",
                  crossShow: false,
                  confirmEvent: function () {
                    this.$router.push(`/course/${this.$route.params.courseId}`)
                  }
                }
              } else {
                this.alertModal = {
                  show: true,
                  state: "danger",
                  title: "修改失敗",
                  bodyMsg: "本修改失敗，請重新嘗試",
                  crossShow: false,
                }
              }
            })
          }
        })

      } else {
        this.createError = true
        this.createErrorMessage = "請檢查試卷是否有哪邊尚未填寫完成"
      }

    },
    deleteAllFormTopic() {
      this.topic = {
        type: "",
        question: "",
        ans: [],
        errorAns: ""
      }
      this.editInputAns = []
      this.editInputAnsError = []
      this.inputAns = ""
      this.inputAnsError = false
      this.paper = {
        paperName: "",
        paperIllustrate: "",
        paperSort: "",
      }
      this.randomQuestion = false
      this.formTopics = []
      this.leastRightAns = false
      this.leastErrorAns = false
      this.questionError = false
      this.formTopicsQuestionError = []
      this.formTopicsEditInputAnsError = []
      this.formTopicsEditInputAnsFormatError = []
      this.formTopicsLeastErrorAns = []
      this.formTopicsAddInputAns = []
      this.formTopicsInputAns = []
      this.formTopicsLeastRightAns = []
      this.formTopicsLeastRightAnsFormat = []
      this.createError = false
    },
    close() {
      location.href = "./createFormPaper"
    }
  }
}
</script>

<style scoped>
.from--form {
  width: 60%;
  text-align: center;
  margin: auto;
  padding: 20px;
}

label {
  text-align: left;
}

.col-3 {
  width: 17%;
}

.col-9 {
  width: 83%;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.input-group {
  padding: 0;
}

.label--topic--type--select {
  width: 17%;
}

.input--topic--type--select {
  width: 17%;
}

.label--topic--type {
  width: 13%;
}

.input--topic--type {
  width: 20%;
}

.label--topic {
  width: 17%;
}

.input--topic {
  width: 83%;
}

small.input--topic--type {
  padding: 0;
}

small.input--topic {
  padding: 0;
}

.input--topic > button {
  width: 8%;
}

</style>