import personalInformation from "../../components/personalInformation/index.vue";

export default [
    // 個人資料
    {
        path: '/personalInformation',
        name: 'personalInformation',
        component: personalInformation,
        meta: {needLogin: true},
    },
]