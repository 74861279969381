<template>
  <div v-show="uploadShow">
    <div class="form--div">
      <div class="form-group">
        <label class="col-form-label fw-bold --key">試卷模板搜尋條件
          <button type="button" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOfSearch">
            <i class="fas fa-search --key"></i>
          </button>
        </label>
      </div>
    </div>
    <div id="collapseOfSearch" class="form--div border border-secondary collapse">
      <form>
        <div class="form-group">
          <div class="col-6 form-floating">
            <input type="text" class="form-control input--label" id="inputTitle" v-model="title"
                   :placeholder="'程式設計第一次考試'" list="titleOptions">
            <datalist id="titleOptions">
              <option v-for="(data,index) in title_list" :key="index" :value="data"></option>
            </datalist>
            <label for="inputTitle" class="fw-bold">模板名稱</label>
          </div>

          <div class="col-6 form-floating">
            <input type="text" class="form-control input--label" id="inputContent" v-model="content"
                   :placeholder="'這次考迴圈，請認真作答'">
            <label for="inputContent" class="fw-bold">模板簡介</label>
          </div>
        </div>


        <div class="form-group">
          <div class="col-6 form-floating">
            <input type="text" class="form-control input--label" id="inputSort" v-model="sort"
                   :placeholder="'程式設計'" list="sortOptions">
            <datalist id="sortOptions">
              <option v-for="(data,index) in sort_list" :key="index" :value="data"></option>
            </datalist>
            <label for="inputSort" class="fw-bold">分類</label>
          </div>

          <div class="col-3 form-floating">
            <input type="text" class="form-control input--label" id="inputCreator" v-model="creator"
                   :placeholder="'李'">
            <label for="inputCreator" class="fw-bold">創建者</label>
          </div>

          <div class="col-3 form-floating">
            <input type="number" class="form-control input--label" id="inputAmount" v-model="amount"
                   :placeholder="'5'">

            <label for="inputAmount" class="fw-bold">題目數量</label>
          </div>
        </div>

        <div>
          <button type="button" class="btn btn-outline-primary me-3" @click="search">
            <i class="fas fa-search"></i>
          </button>
          <button type="button" class="btn btn-outline-danger me-3" @click="deleteAllSearch">
            <i class="far fa-trash-alt"></i>
          </button>
        </div>

      </form>
    </div>
    <searchTopicResult :searchFormExam="searchFormExam"/>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";
import searchTopicResult from "./searchTopicResult.vue";

export default {
  name: "searchTopicTemplate",
  components: {
    searchTopicResult, AlertModal
  },
  created() {
    this.search()
    this.getTitleAndSortList()
  },
  data() {
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      title: "",
      title_list: [],
      content: "",
      sort: "",
      sort_list: [],
      amount: "",
      creator: "",
      searchFormExam: {},
      alertModal,
      uploadShow: false,
    }
  },
  methods: {
    getTitleAndSortList() {
      axios.post(`/api/paper/${this.$route.params.courseId}/formExam`).then((res) => {
        if (res.data.success) {
          let sql_title_list = []
          res.data.formTitle.forEach((title) => {
            sql_title_list.push(title.title)
          })
          sql_title_list = sql_title_list.filter((ele, pos) => sql_title_list.indexOf(ele) == pos);
          this.title_list = sql_title_list

          let sql_sort_list = []
          res.data.formTitle.forEach((sort) => {
            sql_sort_list.push(sort.sort)
          })
          sql_sort_list = sql_sort_list.filter((ele, pos) => sql_sort_list.indexOf(ele) == pos);
          this.sort_list = sql_sort_list
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
        }
      })
    },
    search() {
      axios.post(`/api/paper/${this.$route.params.courseId}/search/formExam`, {
        title: this.title,
        sort: this.sort,
        amount: this.amount,
        creator: this.creator,
      }).then((res) => {
        if (res.data.success) this.searchFormExam = res.data.formExam
        else this.searchFormExam = {}
      })
    },
    deleteAllSearch() {
      this.title = ""
      this.content = ""
      this.sort = ""
      this.amount = ""
      this.creator = ""
      this.search()
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 90%;
  margin: 20px auto;
}

.--key {
  font-size: 20px;
}

form {
  margin: 10px 0;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

label {
  text-align: left;
}

.input--label {
  margin: auto;
  width: 97%;
}

#inputCreator {
  margin: auto;
  width: 94%;
}

#inputAmount {
  margin: auto;
  width: 94%;
}
</style>