<template>
  <div v-if="uploadShow" class="container">
    <div class="row">
      <course-box v-for="(data,index) in courseList" :key="index" :course-information='data'></course-box>
      <course-box-add-new></course-box-add-new>
    </div>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import courseBox from "./courseBox.vue";
import courseBoxAddNew from "./courseBoxAddNew.vue";
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "courseListContainer",
  components: {
    courseBox, courseBoxAddNew, AlertModal
  },
  data() {
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    this.fetchCourse();
    return {
      alertModal,
      courseList: [],
      uploadShow: false,
    }
  },
  methods: {
    fetchCourse: function () {
      axios.get('/api/course/getCourse').then((res) => {
        if(res.data.success){
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
          for (let resCourseLength = 0; resCourseLength < res["data"]["Course"].length; resCourseLength++) {
            let newObj = {
              hrefLink: "/course/" + res["data"]["Course"][resCourseLength]["courseId"],
              department: res["data"]["Course"][resCourseLength]["dep"],
              courseName: res["data"]["Course"][resCourseLength]["course_name"],
              teacher: res["data"]["Course"][resCourseLength]["teacher"],
              semester: res["data"]["Course"][resCourseLength]["school_semester"],
              term: res["data"]["Course"][resCourseLength]["school_term"],
              teachClass: res["data"]["Course"][resCourseLength]["open_course_class"]
            }
            this.courseList.push(newObj);
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>