<template>

  <div class="card py-3 container" style="height: max-content">
    <div class="row">
      <label class="col-form-label col-2">設定名稱</label>
      <div class="col-9">
        <input class="form-control" :value="title" @change="this.$emit('update:title',$event.target.value)">
      </div>
    </div>
    <div class="row py-2">
      <label class="col-form-label col-2">時間設定</label>
      <div class="col-4">
        <Datepicker :model-value="startTime" @update:model-value="emitStartTime"
                    placeholder="開始時間" selectText="選擇"
                    cancelText="清除" locale="zh" textInput weekStart="0">
        </Datepicker>
      </div>
      <label class="col-form-label col-1">~</label>
      <div class="col-4">
        <Datepicker :model-value="endTime" @update:model-value="emitEndTime"
                    placeholder="結束時間" selectText="選擇" cancelText="清除" locale="zh" textInput
                    weekStart="0"/>
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <button class="col-1 btn btn-outline-primary mx-2" @click="$emit('btnSave')">儲存</button>
      <button class="col-1 btn btn-outline-warning mx-2" @click="$emit('btnCancel')">取消</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "timeContent",
  props: {
    title: {
      type: String,
    },
    startTime: {
      default: null,
    },
    endTime: {
      default: null,
    }
  },
  data() {
  },
  methods: {
    emitStartTime: function (value)
    {
      this.$emit('update:startTime', value)
    },
    emitEndTime: function (value)
    {
      this.$emit('update:endTime', value)
    }
  }
}
</script>

<style scoped>
</style>