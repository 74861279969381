<template>
  <div id="viewCollapse" class="collapse">
    <div class="container p-2" v-if="fileData.length==0" style="text-align: center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card p-2" v-if="fileData.length!=0">
      <div class="container-fluid"></div>
      <div class="row">
        <h4 class="col fw-bold">題目預覽</h4>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="container-fluid mt-4">
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">題目分類:</p>
              <p class="col-7 fs-5">{{ illustrate.sort }}</p>
            </div>
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">題目名稱:</p>
              <p class="col-7 fs-5">{{ illustrate.title }}</p>
            </div>
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">版本號碼:</p>
              <p class="col-7 fs-5">{{ illustrate.version }}</p>
            </div>
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">建題目者:</p>
              <p class="col-7 fs-5">{{ illustrate.createName }}</p>
            </div>
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">查看輸入:</p>
              <p class="col-7 fs-5">
                <i v-if="illustrate.view" class="fas fa-check fa-fw"></i>
                <i v-if="!illustrate.view" class="fas fa-times fa-fw"></i>
              </p>
            </div>
            <div class="row">
              <p class="col-4 fw-bold mx-2 fs-5">允許下載:</p>
              <p class="col-7 fs-5">
                <i v-if="illustrate.download" class="fas fa-check fa-fw"></i>
                <i v-if="!illustrate.download" class="fas fa-times fa-fw"></i>
              </p>
            </div>
            <div v-if="false" class="row">
              <p class="col-4 fw-bold mx-2 fs-5">DEBUG:</p>
              <p class="col-7 fs-5">
                <button class="btn btn-danger" @click="debug">DEBUG</button>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="row px-2">
            <ul class="nav nav-tabs dark-tabs">
              <li v-for="(data,index) in fileData" :key="index" :class="{'active':pageArgs.selectedFile === index}"
                  class="nav-item">
                <button class="nav-link" style="color: inherit" @click="selectFile(index)">
                  <i class="far fa-file-code mx-2"></i>
                  <span>{{ data.fileName }}</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="row px-2">
            <ace-editor ref="ace" v-model:value="fileData[pageArgs.selectedFile].code"></ace-editor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import aceEditor from "../../../module/aceEditor.vue";
import bootstrap from "bootstrap/dist/js/bootstrap"

export default {
  name: "displayPanel",
  components: {aceEditor},
  props: {
    illustrate: {
      type: Object,
    },
    files: {
      type: Array
    }
  },
  data() {
    const Base64 = require('js-base64')
    const pageArgs = {
      selectedFile: 0,
    }
    const viewCollapse = null
    return {
      Base64,
      viewCollapse,
      pageArgs,
    }
  },
  computed: {
    fileData() {
      return this.files.map(item => (
          {fileName: item.fileName, code: item.code ? this.Base64.decode(item.code) : ""}));
    }
  },
  methods: {
    selectFile: function (fileIndex) {
      this.pageArgs.selectedFile = fileIndex
      this.$refs.ace.reloadData(this.fileData[this.pageArgs.selectedFile].code)
    },
    viewOn: function () {
      bootstrap.Collapse.getOrCreateInstance(document.getElementById('viewCollapse')).show()
    },
    debug: function () {
      bootstrap.Collapse.getOrCreateInstance(document.getElementById('viewCollapse')).hide()
    }
  }
}
</script>

<style scoped>
.dark-tabs {
  margin: 0px 0px -1px 0px;
  border-radius: 10px 10px 0px 0px;
  color: #FFFFFF !important;
  background-color: rgb(58, 59, 58) !important;
}

.active {
  color: #88dcea !important;
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(15, 17, 15) !important;
  border-color: white !important;
}

.nav-link {
  color: #dee2e6;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

</style>