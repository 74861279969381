<template>
  <registerNavbar></registerNavbar>
  <div class="register-body container ">
    <form method="post" @submit.prevent="submitRegister">
      <div class="row">
        <h1 class="mb-3 fw-bolder">註冊</h1>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">電子郵件</label>
        <div class=" col-sm-9">
          <input type="email" id="stdEmail" v-model="stdEmail" :class="{'border-danger': warning.email}"
                 class="form-control" placeholder="Email Address" required>
        </div>
      </div>


      <div v-if="warning.email" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">此Email已存在，請重新輸入</small>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">帳號</label>
        <div class=" col-sm-9">
          <input type="text" v-model="stdAcc" :class="{'border-danger': warning.account}" id="stdACC"
                 class="form-control"
                 placeholder="Account" required>
        </div>
      </div>

      <div v-if="warning.account" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">此帳號已存在，請重新輸入</small>
      </div>


      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">密碼</label>
        <div class=" col-sm-9">
          <input type="password" v-model="stdPW" id="password" class="form-control" :class="{'border-danger':regExpMatchPw}"
                 placeholder="Password" required>
        </div>
      </div>

      <div v-if="regExpMatchPw" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">密碼需要至少8位以上(A~Z,a~z,.!_)</small>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold ">確認密碼</label>
        <div class=" col-sm-9">
          <input type="password" v-model="stdCPW" :class="{'border-danger':!pwIsMatch}"
                 id="confirmPassword" class="form-control" placeholder="Confirm Password" required>
        </div>
      </div>

      <div v-if="!pwIsMatch" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">輸入密碼不一致，請重新輸入</small>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold ">姓名</label>
        <div class=" col-sm">
          <input type="text" v-model="stdName" class="form-control" placeholder="名稱 Name" required>
        </div>
      </div>


      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">學校</label>
        <div class=" col-sm-9 text-start">
          <select required class="form-select" id="school" @change="updateDepart" @click="updateDepart"
                  v-model="stdSchool">
            <option value="" selected disabled>學校</option>
            <option v-for="(data,index) in school_List" :key="index" :value="data">{{ data }}</option>
          </select>

        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">系所</label>
        <div class=" col-sm-9 text-start">
          <select required id="department" class="form-select" v-model="stdDepartment">
            <option value="" :selected="true" disabled>科系</option>
            <option v-for="(data,index) in department_List" :key="index" :value="data">{{ data }}</option>
          </select>
        </div>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">學號</label>
        <div class=" col-sm-9">
          <input type="text" v-model="stdID" id="stdID" :class="{'border-danger': warning.stdId}"
                 class="form-control" placeholder="Student ID" required>
        </div>
      </div>


      <div v-if="warning.stdId" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">此學號已存在，請重新輸入</small>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">電話號碼</label>
        <div class=" col-sm-9">
          <input type="text" v-model="stdPhone" id="stdPhone" class="form-control" placeholder="Phone"
                 pattern="^09[0-9]{8}|^\+886[0-9]{9}"
                 required>
        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">學年度</label>
        <div class=" col-sm-9">
          <input type="text" v-model="semester" class="form-control" placeholder="semester" required>
        </div>
      </div>

      <div>
        <button type="submit" class="btn btn-primary me-3">註冊</button>
        <button type="reset" class="btn btn-warning me-3">清除</button>
      </div>

    </form>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import axios from "axios"
import registerNavbar from "../module/navbar/Register-navbar.vue";
import AlertModal from "../module/Modal/alertModal.vue";

export default {
  name: "register",
  data() {
    this.updateSchool()
    let alertModal = {
      show: false
    }
    let warning = {
      account: false,
      email: false,
      stdId: false,
    }
    return {
      school_List: [],
      department_List: [],
      stdEmail: "",
      stdAcc: "",
      stdPW: "",
      stdCPW: "",
      stdID: "",
      stdName: "",
      stdSchool: "",
      stdDepartment: "",
      stdPhone: "",
      semester: "",
      warning,
      alertModal,
    }
  },
  components: {AlertModal, registerNavbar,},
  methods: {
    updateSchool: function () {
      axios.get('/api/info/get/school').then((res) => {

        let sql_school_list = []
        res["data"].forEach((school) => {
          sql_school_list.push(school.school)
        })
        sql_school_list = sql_school_list.filter((ele, pos) => sql_school_list.indexOf(ele) == pos);
        this.school_List = sql_school_list

      })
    },
    updateDepart: function () {
      let elem = document.getElementById("school")
      let dep = elem.value

      axios.get(`/api/info/get/school/${dep}`).then((res) => {
        let sql_department_list = []
        res["data"].forEach((dep) => {
          sql_department_list.push(dep["department"])
        })
        this.department_List = sql_department_list
      })
    },
    submitRegister: function () {
      console.log(this.stdPW === this.stdCPW)
      console.log(this.stdPW.match(/^[a-zA-Z0-9\\.!_]{8,}$/) != undefined)
      if(this.stdPW === this.stdCPW && this.stdPW.match(/^[a-zA-Z0-9\\.!_]{8,}$/) != undefined){
        this.warning = {
          account: false,
          email: false,
          stdId: false,
        }
        axios.post("/api/register", {
          email: this.stdEmail,
          account: this.stdAcc,
          stuID: this.stdID,
          password: this.stdPW,
          school: this.stdSchool,
          department: this.stdDepartment,
          name: this.stdName,
          phone: this.stdPhone,
          semester: this.semester,
        }).then((res) => {
          console.log(res)
          if (!res.data.success) {
            if (res.data.err_msg.EmailExist) {
              this.warning.email = true
            }
            if (res.data.err_msg.AccountExist) {
              this.warning.account = true
            }
            if (res.data.err_msg.StuIdExist) {
              this.warning.stdId = true
            }
          } else {
            this.alertModal = {
              show: true,
              crossShow:false,
              title: "註冊通知",
              bodyMsg: "帳號註冊成功，請到電子郵件中啟用認證信後，進行登入!!",
              confirmEvent: function () {
                this.$router.push("/login")
              }
            }
          }
        })
      }
    }
  },
  computed:{
    pwIsMatch:function (){
      return this.stdCPW === "" || this.stdPW === this.stdCPW
    },
    regExpMatchPw:function (){
      return this.stdPW != '' && this.stdPW.match(/^[a-zA-Z0-9\\.!_]{8,}$/) == undefined
    }
  }
}
</script>

<style scoped>
form {
  text-align: center;
  margin: auto;
  padding: 20px;
}

.register-body {
  text-align: center;
  margin: auto;
}

.data-brick {
  text-align: center;
  width: 580px;
  margin: auto;
}

label {
  text-align: left;
}

ul > .dropdown-menu {
  width: 300px;
}
</style>