<template>
    <navbar></navbar>
    <div v-show="uploadShow">
        <div class="container mt-5">
            <div class="row">
                <div class="col d-flex justify-content-start">
                    <h3><strong>題目題組設定</strong></h3>
                    <!--        <button class="btn btn-danger" @click="debug()">DEBUG</button>-->
                </div>
            </div>
            <div class="row ">
                <div class="col d-flex justify-content-center">
                    <div class="card p-3" style="width: 60vw">
                        <div class="container">
                            <!--            第幾題 -->
                            <div v-for="(item,index) in listQuestion" :key="item" class="row">
                                <div class="col">
                                    <div class="card my-1 p-2">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col text-start">
                                                    <h5><strong>第{{ index + 1 }}題</strong></h5>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <table class="table table-striped" style="width: 100%;">
                                                        <tbody>
                                                        <tr v-for="(item2,index2) in item" :key="item2">
                                                            <td style="width: 10%">
                                                                <span>{{ index2 + 1 }}</span>
                                                            </td>
                                                            <td style="width: 10%">
                                                                <input v-model="item2.select" class="form-check"
                                                                       type="checkbox">

                                                            </td>
                                                            <td style="width: 80%">
                                                                <table style="width: 100%">
                                                                    <tr v-for="(item3,index3) in item2.data"
                                                                        :key="item3">
                                                                        <td style="width: 40%">{{ item3.title }}</td>
                                                                        <td style="width: 30%">
                                                                            <span v-if="item3.type == 'code'">程式題<i
                                                                                class="fas fa-code mx-3"></i></span>
                                                                            <span v-else-if="item3.type == 'form'">試卷題<i
                                                                                class="fas fa-list mx-3"></i></span>
                                                                            <span v-else>ERROR</span>
                                                                        </td>
                                                                        <td style="width: 30%">
                                                                            <select class="form-select"
                                                                                    style="width: 100%"
                                                                                    @change="moveQuestion(index,index2,index3,$event.target.value)"
                                                                                    v-model="dummySelect"
                                                                            >
                                                                                <option :disabled="true"
                                                                                        :selected="true" value="-1">移動題目
                                                                                </option>
                                                                                <option v-for="(_,ques) in listQuestion"
                                                                                        :key="ques"
                                                                                        :disabled="ques == index"
                                                                                        :value="ques">第{{ ques + 1 }}題
                                                                                </option>
                                                                                <option value="-2">新增題目</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex justify-content-center">
                                            <button class="col-md-2 btn btn-primary mx-2" @click="combineQues(index)">
                                                合併
                                            </button>
                                            <button class="col-md-2 btn btn-warning mx-2" @click="unbindQues(index)">
                                                解散
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row  d-flex justify-content-center">
                                <button class="col-3 btn btn-outline-primary mx-2" @click="saveSettingToSV">
                                    儲存並重新產生試卷
                                </button>
                                <button class="col-2 btn btn-outline-warning mx-2">取消</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
    </div>

    <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import navbar from "../../module/navbar";
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";

export default {
    name: "quesGroupSetting",
    components: {AlertModal, navbar},
    data() {
        const alertArgs = {
            show: false,
        }
        const alertModal = {
            show: true,
            state: "loading",
            title: "讀取中",
            crossShow: false,
            confirmShow: false
        }
        this.fetchSettingFromSV()
        return {
            listQuestion: [],
            alertArgs,
            alertModal,
            uploadShow: false,
            dummySelect:-1,
        }
    },
    methods: {
        // 移動題目
        moveQuestion: function (quesIndex, sectionIndex, locate, moveTo) {
            this.dummySelect=-1
            let moveTmp = this.listQuestion[quesIndex][sectionIndex].data[locate]
            if (moveTo >= 0) {
                this.listQuestion[moveTo].push({select: false, data: [moveTmp]})
            } else if (moveTo == -2) {
                this.listQuestion.push([{select: false, data: [moveTmp]}])
            }
            this.listQuestion[quesIndex][sectionIndex].data.splice(locate, 1)
            if (this.listQuestion[quesIndex][sectionIndex].data.length <= 0) {
                this.listQuestion[quesIndex].splice(sectionIndex, 1)
                if (this.listQuestion[quesIndex].length <= 0)
                    this.listQuestion.splice(quesIndex, 1)
            }
        },
        // 合併問題
        combineQues: function (quesIndex) {
            let dataTmp = []
            // 取資料
            for (let i = this.listQuestion[quesIndex].length - 1; i >= 0; i--) {
                if (this.listQuestion[quesIndex][i].select) {
                    this.listQuestion[quesIndex][i].data.map((mapItem => {
                        dataTmp.push(mapItem)
                    }))
                    this.listQuestion[quesIndex].splice(i, 1)
                }
            }
            // 塞資料
            if (dataTmp.length != 0)
                this.listQuestion[quesIndex].push({select: false, data: dataTmp})

            this.initialSelect(quesIndex)
        },
        // 解散題組(內部的合併解除，不是將題目分開)
        unbindQues: function (quesIndex) {
            let dataTmp = []
            for (let i = this.listQuestion[quesIndex].length - 1; i >= 0; i--) {
                if (this.listQuestion[quesIndex][i].select) {
                    this.listQuestion[quesIndex][i].data.map(mapItem => {
                        dataTmp.push(mapItem)
                    })
                    this.listQuestion[quesIndex].splice(i, 1)
                }
            }
            dataTmp.map(mapItem => {
                this.listQuestion[quesIndex].push({select: false, data: [mapItem]})
            })
        },
        // 取得設定檔
        fetchSettingFromSV: async function () {
            await axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/quiz_set/list`)
                .then(res => {
                    if (res.data.success) {
                        const info = res.data.info
                        info.map(mapItem => {
                            const topic = []
                            mapItem.map(mapItem2 => {
                                const topicSet = {select: false, data: []}
                                mapItem2.map(mapItem3 => {
                                    topicSet.data.push(mapItem3)
                                })
                                topic.push(topicSet)
                            })
                            this.listQuestion.push(topic)
                        })
                        this.alertModal = {
                            show: false,
                        }
                        this.uploadShow = true
                    }
                })
        },
        // 儲存設定檔
        saveSettingToSV: async function () {
            let quizSetting = []
            this.listQuestion.map(mapItem => {
                let quesSet = []
                mapItem.map(mapItem2 => {
                    let ques = []
                    mapItem2.data.map(mapItem3 => {
                        ques.push(mapItem3)
                    })
                    quesSet.push(ques)
                })
                quizSetting.push(quesSet)
            })
            await axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/quiz_set/save`, {quizSetting}).then((response) => {
                if (response.data.success) {
                    this.alertArgs = {
                        show: true,
                        title: "儲存成功",
                        bodyMsg: "儲存題組設定成功",
                        confirmEvent: function () {
                            this.$router.go(-1)
                        }
                    }
                } else {
                    this.alertArgs = {
                        show: true,
                        state: 'warning',
                        title: "儲存失敗",
                        bodyMsg: "儲存題組設定失敗，請重新嘗試!!"
                    }
                }
            })
        },
        // 初始化選擇
        initialSelect: function (quesIndex) {
            this.listQuestion[quesIndex].forEach((value, index) => {
                this.listQuestion[quesIndex][index].select = false
            })
        },
        debug: function () {
            console.log(this.listQuestion)

        }
    }
}
</script>

<style scoped>

</style>