<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col d-flex justify-content-start">
        <button class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOfSearch">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="collapse" id="collapseOfSearch">
        <form class="container-fluid card p-2">
          <div class="row">
            <label for="sortText" class="col-sm-1 col-form-label">分類</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="sortText" v-model="searchParams.sort">
            </div>
            <label for="titleText" class="col-sm-1 col-form-label">題目名稱</label>
            <div class="col-sm-7">
              <input type="text" class="form-control" id="titleText" v-model="searchParams.title">
            </div>
          </div>
          <div class="row mt-1">
            <label for="langText" class="col-sm-1 col-form-label">使用語言</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="langText" v-model="searchParams.language">
            </div>
            <label for="versionText" class="col-sm-1 col-form-label">版本</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="versionText" v-model="searchParams.version">
            </div>
            <label for="creatorText" class="col-sm-1 col-form-label">建立者</label>
            <div class="col-sm-3">
              <input type="text" class="form-control" id="creatorText" v-model="searchParams.creator">
            </div>
          </div>
          <div class="row mt-2 d-flex justify-content-center">
            <button class="btn col-1 btn-primary mx-2" type="button" @click="fetchProgramData">
              <span>查詢</span><i class="fas fa-search ms-1"></i>
            </button>
            <button class="btn col-1 btn-danger mx-2" type="button" @click="resetSearchParams">
              <span>取消</span><i class="fas fa-times ms-1"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col" style="width: 10%">#</th>
          <th scope="col" style="width: 15%">分類</th>
          <th scope="col" style="width: 20%">題目</th>
          <th scope="col" style="width: 10%">使用語言</th>
          <th scope="col" style="width: 10%">版本</th>
          <th scope="col" style="width: 10%">建立人</th>
          <th scope="col" style="width: 15%">操作</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(content,index) in listContent" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ content.sort }}</td>
          <td>{{ content.title }}</td>
          <td>{{ content.lang }}</td>
          <td>{{ content.version }}</td>
          <td>{{ content.creator }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <button class="btn btn-outline-primary mx-1 py-0" @click="callEditPage(index)"><i class="fas fa-edit"></i>
              </button>
              <button v-if="false" class="btn btn-outline-warning mx-1 py-0" @click="callDeleteModal(index)"><i
                  class="fas fa-trash-alt"></i></button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <modal v-if="deleteModal.show" @close="deleteModal.show = false">
    <template v-slot:header>
      <h5>刪除考題?</h5>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <p class="fa-warning">要刪除 {{ deleteModal.deleteInfo }} 嗎?</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" @click="deleteCodeInfo()">確認</button>
        <button class="btn btn-warning mx-2" @click="deleteModal.show = false">取消</button>
      </div>
    </template>
  </modal>

  <modal v-if="alertModal.show" @close="alertModal.show = false">
    <template v-slot:header>
      <h5>
        <span v-if="alertModal.state === 'normal'" class="text-primary"><i class="fas fa-info-circle fa-1x"></i></span>
        <span v-if="alertModal.state === 'warning'" class="text-warning"><i
            class="fas fa-times-circle fa-1x"></i></span>
        <span>系統提示</span>
      </h5>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <span>{{ alertModal.alertInfo }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" @click="alertModal.show = false">確認</button>
      </div>
    </template>
  </modal>
</template>

<script>
import axios from "axios";
import modal from "../../module/Modal/index.vue";

export default {
  name: "programTable",

  components: {
    modal
  },
  data() {

    const deleteModal = {
      show: false,
      deleteInfo: "",
      processId: undefined
    }
    const alertModal = {
      state: "normal",
      show: false,
      alertInfo: "",
    }
    const searchParams = {
      sort: "",
      title: "",
      language: "",
      version: "",
      creator: ""
    }
    this.firstFetchProgramData()
    return {
      listContent: [],
      deleteModal,
      alertModal,
      searchParams
    }
  },
  methods: {
    firstFetchProgramData: function () {
      axios.post('/api/system/code/list').then((result) => {
        this.listContent = result.data.codeInfo
      })
    },
    callDeleteModal: function (deleteId) {
      this.deleteModal.processId = deleteId
      this.deleteModal.deleteInfo = `${this.listContent[this.deleteModal.processId].sort}:${this.listContent[this.deleteModal.processId].title}`
      this.deleteModal.show = true
    },
    callEditPage: function (index) {
      this.$router.push(`/program/${this.listContent[index].id}`)
    },
    deleteCodeInfo: function () {
      this.deleteModal.show = false
      axios.post("/api/system/code/delete", {questionId: this.listContent[this.deleteModal.processId].id}).then((res) => {
        if (res.data.success) {
          this.alertModal.state = "normal"
          this.alertModal.alertInfo = "刪除成功!"
        } else {
          this.alertModal.state = "warning"
          this.alertModal.alertInfo = "刪除失敗!"
        }
        this.alertModal.show = true
        this.fetchProgramData()
      })
    },
    fetchProgramData: function () {
      const request = {
        sort: this.searchParams.sort,
        title: this.searchParams.title,
        lang: this.searchParams.language,
        version: this.searchParams.version,
        creator: this.searchParams.creator
      }
      axios.post('/api/system/code/list', request).then((result) => {
        this.listContent = result.data.codeInfo
      })
    },
    resetSearchParams:function (){
      this.searchParams = {
        sort: "",
        title: "",
        language: "",
        version: "",
        creator: ""
      }
    }
  }
}
</script>

<style scoped>

</style>