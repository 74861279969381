<template>
  <div class="container">
    <navbar hide-bread-crumb="true"></navbar>
    <H1 class="fw-bold mt-5">CloudJudge 程式檢測評斷暨表單考試系統</H1>
    <div>
      <img src="../img/CJ_2.png" alt="js picture" width="300">
    </div>
    <div>
      <img src="../img/java.png" alt="js picture" width="50">
      <img src="../img/js-picture.png" alt="js picture" width="50">
      <img src="../img/python.png" alt="js picture" width="50">
      <img src="../img/cplus.png" alt="js picture" width="50">
    </div>


    <br>
    <router-link to="/login"><button class="btn btn-primary">進入網站</button></router-link>
  </div>
</template>

<script>
import navbar from "./module/navbar"

export default {
  name: "Home",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    navbar
  }
}
</script>

<style scoped>
img {
  margin: 10px;
}
</style>