<template>
  <navbar/>
  <courseSearchCriteria/>
<!--  <a class="btn btn-outline-primary btn-circle go-to-top" href="/createCourse"><i class="fas fa-plus"></i></a>-->
</template>

<script>
import navbar from "@/components/module/navbar/index.vue";
import courseSearchCriteria from "@/components/courseManage/courseSearchCriteria.vue";

export default {
  name: "index",
  components: {
    navbar, courseSearchCriteria
  }
}
</script>

<style scoped>
.btn-circle {
  display: flex;
  justify-content:center;
  align-items:center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
}

.go-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}
</style>