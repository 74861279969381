<template>
<div class="card border-dark" style="height: 100%">
  <h4 class="mt-4 mb-1 mx-2">
    <strong>
      題目數量:
    </strong>
    <span>{{questionAmount}}</span>
  </h4>
  <h4 class="text-danger my-1 mx-2">
    <strong>
      未完成數量:
    </strong>
    <span>{{questionUndone}}</span>
  </h4>
  <h4 class="text-success my-1 mx-2">
    <strong>
      已完成數量:
    </strong>
    <span>{{questionDone}}</span>
  </h4>
  <h4 class=" my-1 mx-2 ">
    <strong >IP</strong> <fetch-ip-module ></fetch-ip-module>
  </h4>
  <h4 class="my-2 mx-2">
    <strong>
      當前時間 <time-module></time-module>
    </strong>
  </h4>
</div>
</template>

<script>
import timeModule from "../../module/timeModule.vue";
import fetchIpModule from "../../module/fetchIpModule.vue";
export default {
  name: "examSolutionInfo",
  components: {timeModule,fetchIpModule},
  data(){
    return{

      // questionAmount : "5",
      // questionUndone : "3",
      // questionDone: "2",
    }
  },
  props:["examData"],
  computed:{
    questionAmount:function (){
      return this.examData.length
    },
    questionUndone: function (){
      return this.questionAmount - this.questionDone
    },
    questionDone: function (){
      let finish = 0
      this.examData.map(function(exam){
        if(exam.examStatus === "Success"){
          finish++
        }
      })
      return finish
    }
  }
}
</script>

<style scoped>

</style>