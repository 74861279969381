<template>
  <navbar/>
  <createTopic/>
</template>

<script>
import navbar from "../../../module/navbar"
import createTopic from "./createTopic.vue"
export default {
  name: "index",
  components:{
    navbar,createTopic
  }
}
</script>

<style scoped>

</style>