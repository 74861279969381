//const axios = require("axios");
/*
    本功能為如果瀏覽者沒有頁面權限，將透過路由守衛將該使用者轉送掉 (本排版為 理論上下層功能先須設定上層功能)
    bypassIfLogin: 如果登入則直接跳轉到course
    needLogin: 訪問該頁面需要登入
        needCourseAuth: 訪問該頁面需要是課程成員
            needCourseSetting: 訪問該頁面需要課程控制權限
            needClassPerm: 訪問該頁面需要課程權限控制權限
            needExamSetting: 訪問該頁面需要考試設定權限
            needQuestionCtrl: 訪問該頁面需要考題控制權限
            needHisCtrl: 訪問該頁面需要考試設定權限
            needMemberCtrl: 訪問該頁面需要考題控制權限
*/
const store = require("../store/auth").default;
module.exports = async function (to, from, next){
    // console.log("AUTH Start ")
    // 延續登入狀態
    if(store.isLoginValid())
        store.updateLoginExpire()

    // 如果使用者已登入則跳轉
    if (to.meta.bypassIfLogin) {
        if(store.isLoginValid())
            next('/course')
    }

    // 需要登入 檢測該使用者是否已於系統上進行登入
    if (to.meta.needLogin) {
        if(!store.isLoginValid())
            next('/401')
    }

    // 檢查有沒有瀏覽課程權限
    if (to.meta.needCourseAuth) {
        const res = await store.tryGetSystemManage()
        //console.log(res)
        // 擁有[系統]課程控制權限，可以無視下列要求
        if (!res.courseManageCtrl && await store.tryGetCourse(to.params.courseId) !== true)
                next('/403')

    }

    // 檢查有沒有課程存取權限
    if (to.meta.needCourseCtrl || to.meta.needClassPerm || to.meta.needExamCtrl || to.meta.needExamCreate
        || to.meta.needQuizCreate || to.meta.needTopicCtrl || to.meta.needOurTopicCtrl) {
        const res = await store.tryGetCourseManage()
        const resSystem = await store.tryGetSystemManage()

        //console.log(res)
        //console.log(resSystem)
        // 擁有[系統]課程控制權限，可以無視下列要求
        if(!resSystem.courseManageCtrl){
            // 是否擁有課程控制權限
            if (to.meta.needCourseCtrl && !res.courseSetting)
                next('/403')
            // 是否擁有課程權限控制權限
            if (to.meta.needClassPerm && !res.classPermCtrl)
                next('/403')
            // 是否擁有考試 試題管理權限
            if (to.meta.needExamCtrl && !res.examManage)
                next('/403')
            // 是否擁有考試 試題管理權限
            if (to.meta.needExamCreate && !res.examCreate)
                next('/403')
            // 是否擁有考試 試題管理權限
            if (to.meta.needQuizCreate && !res.quizCreate)
                next('/403')
            // 是否擁有題庫設定權限
            if (to.meta.needTopicCtrl && !res.topicManage)
                next('/403')
            // 是否擁有外部題庫控制權限
            if (to.meta.needOurTopicCtrl && !res.outTopicManage)
                next('/403')
        }


    }
    // 題庫控制權限,教室控制權限,帳戶管理權限,網頁管理權限
    if (to.meta.needQBCtrl || to.meta.needClassroomCtrl || to.meta.needAccountCtrl || to.meta.needWebsiteCtrl) {
        const res = store.tryGetSystemManage()
        console.log(res)
        // 是否擁有題庫設定權限
        if (to.meta.needQBCtrl && !res.QBControl)
            next('/403')
        // 是否擁有教室管理權限
        if (to.meta.needClassroomCtrl && !res.classroomControl)
            next('/403')
        // 是否擁有帳號設定權限
        if (to.meta.needAccountCtrl && !res.accountControl)
            next('/403')
        // 是否擁有課程控制權限
        if (to.meta.needCourseManageCtrl && !res.courseManageCtrl)
            next('/403')
    }
    // 404導向
    if (to.meta.go404) {
        next('/404')
    }

    // 404導向
    if (to.meta.go503) {
        next('/503')
    }

    //console.log("Auth End")
    // 預設跳轉
    next()
}