<template>
  <navbar></navbar>
<!--  <bread-crumb></bread-crumb>-->
  <course-list-container></course-list-container>


</template>

<script>
import courseBox from "./courseBox.vue";
import navbar from "../module/navbar"
// import BreadCrumb from "./breadCrumb";
//import BreadCrumb from "../page/breadCrumb";
import CourseListContainer from "./courseListContainer.vue";

export default {
  name: "CourseList",
  components: {
    CourseListContainer,
    // BreadCrumb,
    // eslint-disable-next-line vue/no-unused-components
    navbar,
    // eslint-disable-next-line vue/no-unused-components
    courseBox
  },
}
</script>

<style scoped>

</style>