<template>
  <registerNavbar></registerNavbar>
  <div class="form-signIn">
    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </symbol>
    </svg>
    <img src="../../img/icon.svg" height="138" class="mb-3">
    <h2 class="mb-3">登入帳號 </h2>
    <div class="card  mb-sm-2">
      <div class="card-body">
        <div v-if="msg_show" class="alert alert-danger d-flex align-items-center" role="alert">
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
          <div id="msg-show-text" v-html="login_msg">

          </div>
        </div>
        <main>
          <form class=" text-center" method="post" @submit.prevent="login">

            <div class="mb-1 form-floating btn-row">
              <input type="text" class="form-control " id="floatingAccount" v-model="account_value" placeholder="a" required>
              <label for="floatingAccount">帳號</label>
            </div>
            <div class="mb-3 form-floating btn-row">
              <input type="password" class="form-control" id="floatingPassword" v-model="password_value" name="password" placeholder="Password" required>
              <label for="floatingAccount">密碼</label>
            </div>
            <div class="btn-row d-grid ">
              <router-link class="text-center mb-3 link-no-line" to="./recover"><p> 忘記帳號密碼</p></router-link>
              <button  class="btn btn-primary">登入</button>
            </div>
          </form>
        </main>
      </div>
    </div>
    <div class="card">
      <router-link class="btn-signUp " to="/register"><button type="button"  class="me-2 btn btn-warning">註冊</button></router-link>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import axios from "axios";
import registerNavbar from "../module/navbar/Register-navbar.vue";
import store from "../../store/auth"

export default {
  name: "Login",
  data(){
    return{
      account_value : "",
      password_value:"",
      msg_show : false,
      login_msg : ""
    }
  },
  components:{registerNavbar},
  methods:{
    login:function (){
      axios.post("/api/login",{ account : this.account_value , password : this.password_value }).then(async (res)=>{
        if(res.data["error_msg"] === "AccountNotVerify"){
          this.msg_show = true
          document.getElementById('floatingAccount').classList.add('border-danger')
          this.login_msg = "帳號尚未啟用"
        }
        else if(res.data["error_msg"] === "AccountNotExist") {
          this.msg_show = true
          document.getElementById('floatingAccount').classList.add('border-danger')
          this.login_msg = "帳號不存在"
        }
        else if(res.data["error_msg"] === "AccountSuspend") {
          this.msg_show = true
          //document.getElementById('floatingAccount').classList.add('border-danger')
          this.login_msg = "本帳號已被停用"
        }
        else if(res.data["error_msg"] === "WrongPassword"){
          this.msg_show = true
          document.getElementById('floatingPassword').classList.add('border-danger')
          this.login_msg = "密碼輸入錯誤"
        }
        else if(res.data["login"] === "Successful"){
          store.updateLoginExpire()
          await store.fetchAllCourse()
          await store.fetchSystemAuth()
          location.href = "/course"
        }
      })

    }
  }
}
</script>

<style scoped>
form {
  text-align: center !important;
  margin: auto;
  min-width: 270px;
  padding: 10px 10px 10px;
  border-radius: 10px;
}
.btn-row {
  text-align: left;
}
h2 {
  font-weight: 1000;
}
.btn {
  opacity: 0.8;
}
.form-signIn {
  max-width: 330px;
  text-align: center;
  margin: 5% auto;
}
.btn-signUp{
  padding: 10px 10px 10px;
}
.link-no-line{
  text-decoration: none;
}
</style>