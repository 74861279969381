<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
       :class="(this.seatBoolean)?(this.seatNumber%2==0)?'bg--green':'bg--blue':''">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">Cloud Judge</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-3 mb-lg-0">
          <li class="nav-item">
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/course">課程列表</router-link>
          </li>
          <li class="nav-item" v-if="systemPerm.createCourseControl">
            <router-link class="nav-link" aria-current="page" to="/createCourse">創建課程</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/joinCourse">加入課程</router-link>
          </li>
          <li class="nav-item dropdown" v-if="allAuth">
            <a class="nav-link dropdown-toggle" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              系統功能
            </a>
            <ul class="dropdown-menu">
              <li v-if="systemPerm.QBControl">
                <router-link class="dropdown-item" to="/QBManage">題庫管理</router-link>
              </li>
              <li v-if="systemPerm.classroomControl">
                <router-link class="dropdown-item" to="/classroomSetting">教室管理</router-link>
              </li>
              <li v-if="systemPerm.accountControl">
                <router-link class="dropdown-item" to="/accountManage">帳戶管理</router-link>
              </li>
              <li v-if="systemPerm.courseManageCtrl">
                <router-link class="dropdown-item" to="/courseManage">課程管理</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="navbar-nav">
          <li class="dropdown">
            <a href="#" class="dropdown-toggle d-flex justify-content-end align-items-center" id="dropdownUser1"
               data-bs-toggle="dropdown" aria-expanded="false">
              <div v-if="image == ''" class="spinner-border" role="status" style="height: 32px;width: 32px;">
                <span class="visually-hidden">Loading...</span>
              </div>
              <img v-if="image != ''" :src="`/api/img/${image}`" alt="mdo" width="32" height="32"
                   class="rounded-circle">
            </a>
            <ul class="dropdown-menu" style="top:41px; right: -13px; left: auto;">
              <li><a class="dropdown-item" href="/personalInformation">個人資訊</a></li>
              <li class="dropdown-divider"></li>
              <li><a class="dropdown-item" @click="logout">登出</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <br>
  <br>
  <div class="mb-2"></div>
  <toTopButton v-if="showGoToTop"/>
</template>

<script>
import axios from "axios";
import toTopButton from "../toTopButton.vue"
import auth from "@/store/auth";

export default {
  name: "headerNavbar",
  components: {
    toTopButton
  },
  props: {
    //網頁右下角至頂功能開關
    showGoToTop: {
      type: Boolean,
      default: false,
    },
    seatBoolean:{
      type: Boolean,
      default: false,
    },
    seatNumber:{
      type: Number,
      default: 0,
    },
  },
  data() {
    this.getImage()
    this.updateAllPerm()
    return {
      image: '',
      systemPerm: {},
    }
  },
  methods: {
    logout: function () {
      localStorage.clear()
      location.href = "/api/logout"
    },
    getImage() {
      axios.post(`/api/user/image`).then((res) => {
        if (res.data.success) this.image = res.data.path || ''
        else this.image = ''
      })
    },
    updateAllPerm: async function () {
      let data = await auth.tryGetSystemManage()
      this.systemPerm = data
    }
  },
  computed:{
    allAuth :function (){
      return this.systemPerm.QBControl || this.systemPerm.classroomControl || this.systemPerm.accountControl
    }
  }
}
</script>

<style scoped>

.bg--green{
  background-color: #1c5600 !important;
}
.bg--green {
  background-color: #1c5600 !important;
}

.bg--green .dropdown-menu {
  background-color: #1c5600 !important;
}

.bg--green .dropdown-menu > li > a:hover {
  background-color: #000000;
}

.bg--blue{
  background-color: #5555FF !important;
}
.bg--blue {
  background-color: #5555FF !important;
}

.bg--blue .dropdown-menu {
  background-color: #5555FF !important;
}

.bg--blue .dropdown-menu > li > a:hover {
  background-color: #000000;
}

.bg-dark {
  background-color: #343a40;
}

.bg-dark .navbar-nav > li > a {
  color: #ffffff;
}

.bg-dark .navbar-nav {
  color: #ced4da;
}

.bg-dark .dropdown-menu > li > a {
  color: #ffffff;
}

.bg-dark .dropdown-menu {
  background-color: #212529;
}

.dropdown-menu {
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

@media (max-width: 991px) {
  .bg-dark .dropdown-menu > li > a {
    text-align: center;
  }
}

</style>