<template>
  <div class="search--result">
    <table class="table table-striped border border-secondary">
      <thead>
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="course--" scope="col">課程</th>
        <th class="title--" scope="col">模板名稱</th>
        <th class="content--" scope="col">模板介紹</th>
        <th class="sort--" scope="col">模板分類</th>
        <th class="creator--" scope="col">創建者</th>
        <th class="amount--" scope="col">題目</th>
        <th class="operate--" scope="col">操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(data,index) in searchFormExam" :key="index">
        <th class="index--">{{ index + 1 }}</th>
        <td class="course--">{{ data.courseName }}</td>
        <td class="title--">{{ data.title }}</td>
        <td class="content--">{{ data.content }}</td>
        <td class="sort--">{{ data.sort }}</td>
        <td class="creator--">{{ data.creator }}</td>
        <td class="amount--">{{ data.amount }}</td>

        <td class="operate--">
          <button type="button" class="btn btn-outline-primary btn-sm" @click="check(index)">
            <i class="fas fa-eye fa-fw"></i>
          </button>
          <router-link :to="`/course/${this.$route.params.courseId}/form/${data.id}/editFormPaper`">
            <button type="button" class="btn btn-outline-warning btn-sm"><i class="fas fa-pen-square fa-fw"></i></button>
          </router-link>
          <router-link :to="`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/form/${data.id}/createRandomPaper`">
            <button type="button" class="btn btn-outline-success btn-sm"><i class="fas fa-plus-circle fa-fw"></i></button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <checkFormExamMould v-show="isModalVisible" :="formExamMould" @close="closeModal"/>
</template>

<script>
import checkFormExamMould from "./checkFormExamMould.vue";
import axios from "axios";

export default {
  name: "searchTopicResult",
  components:{
    checkFormExamMould
  },
  props: {
    searchFormExam: {}
  },
  data() {
    return {
      formExamMould: [],
      isModalVisible:false,
    }
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.formExamMould = {}
    },
    check: async function (index) {
      this.isModalVisible = true
      await axios.post(`/api/paper/${this.$route.params.courseId}/check/formExam`, {id: this.searchFormExam[index].id,}).then((res) => {
        if (res.data.success) {
          this.formExamMould = res.data.formExamMould
          //console.log(this.formExamMould)
        }
      })
    }
  }
}
</script>

<style scoped>
.search--result {
  width: 90%;
  margin: 20px auto;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle !important;
}

.index-- {
  width: 3%;
}

.course-- {
  width: 10%;
}

.title-- {
  width: 22%;
}

.content-- {
  width: 25%;
}

.sort-- {
  width: 10%;
}

.creator-- {
  width: 8%;
}

.amount-- {
  width: 7%;
}

.operate-- {
  width: 15%;
}

button {
  margin: 0 5px;
}
</style>