<template>
  <navbar/>
  <joinCourse/>
</template>

<script>
import navbar from "../module/navbar"
import joinCourse from "./joinCourse.vue"

export default {
  name: "index",
  components:{
    navbar, joinCourse
  }
}
</script>

<style scoped>

</style>