<template>
  <div v-if="uploadShow" class="card--">
    <div class="row my-0">
      <label class="px-0 col-auto" style="font-size: 22px">
        <span class="fw-bold">題目：</span>{{ information.title }}
      </label>
    </div>
    <div class="row my-0 mb-2">
      <label class="px-0 col-auto" style="font-size: 22px">
        <span class="fw-bold">學生：</span>{{ information.name }}&nbsp;&nbsp;
        <span class="fw-bold">學號：</span>{{ information.stdId }}
      </label>
    </div>
    <a ref="showRecord" class="d-none" target="_blank"></a>
    <div class="card">
      <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">提交紀錄</h5>
      <div class="card-body">
        <div class="card" style="font-size: 16px">
          <ul class="list-group list-group-flush" v-for="(data,index) in record" :key="data">
            <li class="list-group-item" @click="showRecord(index)">
              <a class="pe-3">提交紀錄：{{ data.id }}</a>
              <span v-if="data.ipCheck == 1" class="badge rounded-pill bg-success mx-2">IP PASS</span>
              <span v-if="data.ipCheck == 0" class="badge rounded-pill bg-danger mx-2">IP FAILED</span>
              <span class="badge rounded-pill bg-dark mx-2">{{ data.buildTime }}</span>

              <span v-if="data.passTest == 'Y'" class="badge rounded-pill bg-success mx-2">執行成功</span>
              <span v-if="data.passTest == 'N'" class="badge rounded-pill bg-danger mx-2">執行失敗</span>
              <span v-if="data.passTest == 'K'" class="badge rounded-pill bg-warning text-dark mx-2">只通過關鍵字</span>
              <span v-if="data.passTest == 'P'" class="badge rounded-pill bg-warning text-dark mx-2">只通過程式</span>
            </li>
          </ul>
          <ul v-if="record.length == 0" class="list-group list-group-flush" >
            <li class="list-group-item">無提交紀錄</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadShow = true"></alert-modal>
</template>

<script>
import AlertModal from "../../../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "studentCodeRecord",
  components: {
    AlertModal
  },
  data() {
    this.getAllRecord()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      alertModal,
      record: [],
      information: {
        title: '',
        name: '',
        stdId: '',
      },
      uploadShow: false
    }
  },
  methods: {
    getAllRecord: async function () {
      await axios.post(`/api/program/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.programId}/${this.$route.params.uuid}/getAllRecord`).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.record = res.data.record.reverse()
          this.information = res.data.information
          this.uploadShow = true

        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            confirmEvent: function () {
              location.href=`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/student/${this.$route.params.uuid}/quizInformation`
            }
          }
        }
      })
    },
    showRecord:function (index){
      this.$refs.showRecord.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/program/${this.$route.params.programId}/student/${this.$route.params.uuid}/record/${this.record[index].id}`
      this.$refs.showRecord.click()
    }
  }
}
</script>

<style scoped>
.card-- {
  width: 90%;
  max-width: 90vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.list-group-item>a{
  cursor: pointer;
  text-decoration: none;
}
.list-group-item>a:hover{
  text-decoration: underline;
}
li.list-group-item:hover{
  background-color:rgba(0,0,0,0.075);
}

.list-group-item > .badge {
  float: right;
}
</style>