<template>
  <div class="from--form">

    <button type="button" class="btn btn-primary m-5" @click="show">Show live toast</button>

    <div class="d-grid gap1">
      <div id="full" class="d-flex mb-1">
        <button type="button" class="btn btn-success flex-fill me-1">shared width</button>
        <button type="button" class="btn btn-success flex-fill me-1">shared width button</button>
        <button type="button" class="btn btn-success flex-fill">shared width button</button>
      </div>
      <button type="button" class="btn btn-primary">full width button</button>
    </div>
    <div class="d-grid gap1">
      <div class="d-flex mb-1">
      <button v-for="arrayData in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,46,100,15556,123]" :key="arrayData" type="button"
              class="btn btn-outline-dark m-1 flex-fill me-1">{{ arrayData }}
      </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
  name: "app",
  data() {
    const toast = useToast();
    return {
      toast
    };
  },
  methods: {
    show(){
      this.toast.success("I'm a toast!", {
        position: "bottom-right",
        timeout: 1492,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }
  },
}
</script>

<style scoped>
.from--form {
  width: 60%;
  text-align: center;
  margin: auto;
  padding: 20px;
}

</style>