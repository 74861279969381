<template>
  <h5 class="fw-bold form--div" style="text-align: left;font-size: 26px;">透過模板創建表單考試</h5>
  <div v-if="!loading" class="form--div border border-dark shadow--">
    <div v-if="!loading && detail.title != ''" class="form-group row">
      <label class="col-form-label fw-bold --key col-2">模板名稱</label>
      <label class="col-form-label fw-bold --key col-6">{{ detail.title }}</label>
    </div>
    <div v-if="!loading && detail.title != '' && (product.length + generatedProduct.length) < classNumber"
         class="form-group row">
      <label for="inputName" class="col-form-label fw-bold --key col-2">考卷名稱</label>
      <div class="col-4 --key">
        <input type="text" class="form-control input--label" id="inputName" v-model="name"
               @change="this.nameError = (this.name == '') ? true : false">
      </div>
      <label for="inputAmount" class="col-form-label fw-bold --key col-2">考卷數量</label>
      <div class="col-2 --key">
        <input type="number" class="form-control input--label" id="inputAmount" v-model="number"
               :placeholder="maxNumber">
      </div>
      <div class="col-2 --key">
        <button type="button" class="btn btn-outline-primary" @click="addProduct"
                :disabled="nameError || number <= 0 || number > maxNumber">
          <i class="fas fa-plus-circle"></i>
        </button>
      </div>
    </div>

    <div v-if="!loading && detail.title != '' && maxNumber != 0 && (nameError || number <= 0 || number > maxNumber)"
         class="form-group row text-start">
      <small class="col-2"></small>
      <small v-if="nameError" class="col-4 text-danger">請勿空白</small>
      <small v-if="!nameError" class="col-4"></small>
      <small class="col-2"></small>
      <small v-if="maxNumber != 0 && (number <= 0 || number > maxNumber)"
             class="col-2 text-danger">請填寫正確數量</small>
    </div>
  </div>

  <div v-if="loading && detail.title == ''" class="form--div border border-dark shadow--">
    <div class="form-group row">
      <label class="col-form-label fw-bold --key" style="text-align: center;">請稍等片刻，正在讀取資料</label>
    </div>
  </div>

  <div v-if="product.length == 0 && productVisible" class="form--div border border-dark shadow--">
    <div class="form-group row">
      <label class="col-form-label fw-bold --key" style="text-align: center;">請稍等片刻，資料正在產生中</label>
    </div>
  </div>

  <div v-if="!loading && generatedProduct.length > 0" class="form--div border border-dark shadow--">
    <label class="col-form-label fw-bold --key">已創建的試卷</label>
    <table class="table table-striped border border-secondary">
      <thead>
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="title--" scope="col">考試</th>
        <th class="describe--" scope="col">考試說明</th>
        <th class="name--" scope="col">考卷名稱</th>
        <th class="operate--" scope="col">操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(data,index) in generatedProduct" :key="index">
        <th class="index--">{{ index + 1 }}</th>
        <td class="title--">{{ quiz.title }}</td>
        <td class="describe--">{{ quiz.bewrite }}</td>
        <th class="name--">{{ name }}</th>
        <td class="operate--">
          <button type="button" class="btn btn-outline-primary btn-sm mx-1" @click="showGeneratedProduct(index)">
            <i class="fas fa-eye"></i>
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm mx-1" @click="deleteGeneratedProduct(index)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <button type="button" class="btn btn-outline-danger m-2" @click="deleteAllGeneratedProduct">
        <i class="far fa-trash-alt"></i>
      </button>
    </div>
  </div>

  <div v-if="product.length != 0" class="form--div border border-dark shadow--">
    <label class="col-form-label fw-bold --key">正在創建的試卷</label>
    <table class="table table-striped border border-secondary">
      <thead>
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="title--" scope="col">考試</th>
        <th class="describe--" scope="col">考試說明</th>
        <th class="name--" scope="col">考卷名稱</th>
        <th class="operate--" scope="col">操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(data,index) in product" :key="index">
        <th class="index--">{{ index + 1 }}</th>
        <td class="title--">{{ quiz.title }}</td>
        <td class="describe--">{{ quiz.bewrite }}</td>
        <th class="name--">{{ name }}</th>
        <td class="operate--">
          <button type="button" class="btn btn-outline-primary btn-sm mx-1" @click="showProduct(index)">
            <i class="fas fa-eye"></i>
          </button>
          <button type="button" class="btn btn-outline-danger btn-sm mx-1" @click="deleteProduct(index)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <div>
      <button type="button" class="btn btn-outline-info m-2" @click="createRandomPaper">
        <i class="fas fa-check-circle"></i>
      </button>
      <button type="button" class="btn btn-outline-danger m-2" @click="deleteAllTopic">
        <i class="far fa-trash-alt"></i>
      </button>
    </div>

  </div>


  <checkProduct v-show="isModalVisible" :name="name" :="showProductModal" @close="closeModal"/>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
  <alert-modal v-model="alertModal2.show" :="alertModal2" @remove="remove" @removeAll="removeAll"></alert-modal>
</template>

<script>
import axios from "axios";
import checkProduct from "./checkProduct.vue";
import AlertModal from "../../../module/Modal/alertModal.vue";

export default {
  name: "createTopic",
  components: {
    checkProduct, AlertModal
  },
  data() {
    this.getTitleAndNumber()
    const alertModal = {
      show: false
    }
    const alertModal2 = {
      show: false
    }
    return {
      alertModal,
      alertModal2,
      number: null,
      maxNumber: null,
      classNumber: null,
      name: "",
      nameError: false,
      detail: {
        sort: "",
        title: "",
        describe: ""
      },
      quiz: {
        title: "",
        describe: "",
      },
      product: [],
      productVisible: false,
      isModalVisible: false,
      generatedProduct: [],
      generatedProductId: [],
      showProductModal: {},
      loading: true
    }
  },
  methods: {
    closeModal() {
      this.isModalVisible = false
    },
    showProduct(index) {
      this.showProductModal = this.product[index]
      //console.log(this.showProductModal)
      this.isModalVisible = true
    },
    deleteProduct(index) {
      this.product.splice(index, 1)
      this.maxNumber++
      this.number = this.maxNumber
    },
    showGeneratedProduct: function (index) {
      this.showProductModal = this.generatedProduct[index]
      this.isModalVisible = true
    },
    deleteGeneratedProduct: function (index) {
      this.alertModal2 = {
        show: true,
        title: `刪除考卷`,
        bodyMsg: `是否確定要刪除第${index + 1}份考卷?`,
        confirmEvent: function () {
          this.$emit("remove", index)
        }
      }
    },
    remove(event) {
      let id = []
      id.push(this.generatedProductId[event])
      if(this.generatedProduct.length > 1) {
        axios.post(`/api/paper/delete/produce`, {id: id}).then((res) => {
          if (res.data.success) {
            this.maxNumber++
            this.number = this.maxNumber
            this.generatedProduct.splice(event, 1)
            this.generatedProductId.splice(event, 1)
            this.alertModal = {
              show: true,
              state: "normal",
              title: "刪除成功",
              bodyMsg: "您以刪除成功",
            }
          } else {
            this.alertModal = {
              show: true,
              state: "warning",
              title: "刪除錯誤",
              bodyMsg: "刪除試卷錯誤，請重新嘗試!!",
            }
          }
        })
      }else{
        this.removeAll()
      }
    },
    getTitleAndNumber: async function () {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.formId}/title`).then((res) => {
        if (res.data.success) {
          this.detail = res.data.detail
          this.number = res.data.number
          this.maxNumber = res.data.number
          this.classNumber = res.data.number
        }
      })
      await this.getGeneratedProduct()
      await this.getQuizTitle()
    },
    getQuizTitle: async function () {
      await axios.post(`/api/paper/${this.$route.params.examId}/title`).then((res) => {
       // console.log(res.data)
        if (res.data.success) {
          this.quiz.title = res.data.quiz.title
          this.quiz.bewrite = res.data.quiz.bewrite || '無說明'
        }
        this.loading = false
      })
    },
    getGeneratedProduct: async function () {
      await axios.post(`/api/paper/${this.$route.params.examId}/${this.$route.params.formId}/generated`).then((res) => {
        if (res.data.success) {
          this.generatedProduct = res.data.product
          this.generatedProductId = res.data.id
          this.name = res.data.name
          this.number -= res.data.product.length
          this.maxNumber -= res.data.product.length
        }
      })
    },
    addProduct: async function () {
      this.nameError = (this.name == '') ? true : false
      if (!this.nameError) {
        this.productVisible = true
        await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.formId}/product`, {
          number: this.number,
          detail: this.detail,
        }).then((res) => {
          if (res.data.success) {
            res.data.product.forEach(forEachItem => this.product.push(forEachItem))
            this.maxNumber -= this.number
            this.number = this.maxNumber
            if (this.number == 0) this.number = null
            //console.log(this.product)
          }
        })
      }
    },
    deleteAllTopic: function () {
      this.product = []
      this.maxNumber = this.classNumber - this.generatedProduct.length
      this.number = this.maxNumber
      this.productVisible = false
    },
    deleteAllGeneratedProduct: function () {
      this.alertModal2 = {
        show: true,
        title: `刪除考卷`,
        bodyMsg: `是否確定要刪除所有考卷?`,
        confirmEvent: function () {
          this.$emit("removeAll")
        }
      }
    },
    removeAll: function () {
      let id = this.generatedProductId
      axios.post(`/api/paper/delete/produce`, {
        id: id,
        courseId: this.$route.params.courseId,
        examId: this.$route.params.examId,
        formId: this.$route.params.formId
      }).then((res) => {
        if (res.data.success) {
          this.maxNumber = this.classNumber
          this.number = this.maxNumber
          this.name = ''
          this.generatedProduct = []
          this.generatedProductId = []
          this.alertModal = {
            show: true,
            state: "normal",
            title: "刪除成功",
            bodyMsg: "您以刪除成功",
          }
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "刪除錯誤",
            bodyMsg: "刪除試卷錯誤，請重新嘗試!!",
          }
        }
      })
    },
    createRandomPaper: async function () {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/topic`, {
        name: this.name,
        product: this.product,
        generatedProduct: this.generatedProduct,
        generatedProductId: this.generatedProductId,
      }).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: true,
            state: "normal",
            title: "創建成功",
            bodyMsg: "您已創建試卷成功，將重新整理",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/form/${this.$route.params.formId}/createRandomPaper`
            }
          }
          this.deleteAllTopic()
        } else {
          this.alertModal = {
            show: true,
            state: "danger",
            title: "創建失敗",
            bodyMsg: "本次創建失敗，請重新創建",
            crossShow: false,
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 60%;
  max-width: 60vw;
  margin: 20px auto;
}

.--key {
  font-size: 20px;
}

form {
  margin: 10px 0;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

label {
  text-align: left;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.index-- {
  width: 10%;
}

.title-- {
  width: 25%;
}

.describe-- {
  width: 25%;
}

.name-- {
  width: 20%;
}

.operate-- {
  width: 20%;
}
</style>