<template>
  <navbar></navbar>
  <div v-if="uploadShow">
    <ctrl-panel></ctrl-panel>
    <course-description v-bind="courseInformation"></course-description>
    <course-container :event-list="listOfEvent"></course-container>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadShow = true"></alert-modal>
</template>

<script>
import courseDescription from "./courseDescription.vue";
import courseContainer from "./courseContainer.vue";
import navbar from "../module/navbar"
import ctrlPanel from "./ctrlPanel.vue";
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "index",
  data() {
    // 課程資訊

    // 項目資料
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    this.fetchSVCourseData()
    return {
      alertModal,
      courseInformation: {},
      listOfEvent: [],
      uploadShow: false,
    }
  },
  methods: {
    fetchSVCourseData: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/list`).then(async (response) => {
        if (response.data.success) {
          this.courseInformation = response.data.info.detail
          this.listOfEvent = []
          let now = new Date()
          await axios.post('/api/sysTime').then((timeSet) => {
            if (timeSet.data.success) {
              now = new Date(timeSet.data.systemTime)
            }
          })
          response.data.info.event.map((mapItem) => {
            let start = new Date(mapItem.startTime)
            let end = new Date(mapItem.endTime)
            let startMatch = (mapItem.startTime !== null) ? (start.getTime() <= now.getTime()) : true
            let endMatch = (mapItem.endTime !== null) ? (now.getTime() < end.getTime()) : true
            let mapObj = {
              examName: mapItem.examName,
              examDetail: mapItem.examDetail,
              examHref: mapItem.examHref,
              examStartTime: mapItem.startTime,
              examEndTime: mapItem.endTime,
              type: mapItem.type,
              startMatch: startMatch,
              endMatch: endMatch,
            }
            this.listOfEvent.push(mapObj)
          })
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
        }
        else{
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course`
            }
          }
        }
      })
    }
  },


  components: {
    courseDescription,
    courseContainer,
    navbar,
    ctrlPanel,
    AlertModal
  }
}
</script>

<style scoped>

</style>