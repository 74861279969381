<template>
  <tr v-if="!edit">
    <td>-</td>
    <td><span class="input-group-text inputWidth">{{ keyWord }}</span></td>
    <td><span class="input-group-text inputWidth">{{ min }}</span></td>
    <td><span class="input-group-text inputWidth">{{ max }}</span></td>
    <td>
      <button type="button" class="btn btn-warning me-0" @click="edit=!edit">修改</button>
    </td>
    <td>
      <button type="button" class="btn btn-danger me-0" @click="deleteEmitArray">刪除</button>
    </td>
  </tr>
  <tr v-if="edit">
    <td>-</td>
    <td><input class="input-group-text inputWidth" :placeholder="keyWord" v-model="newKeyWord"></td>
    <td><input type="number" class="input-group-text inputWidth" :placeholder="min" v-model="newMin"></td>
    <td><input type="number" class="input-group-text inputWidth" :placeholder="max" v-model="newMax"></td>
    <td>
      <button type="button" class="btn btn-warning me-0" @click="editEmitArray();edit=!edit">修改</button>
    </td>
    <td>
      <button type="button" class="btn btn-danger me-0" @click="deleteEmitArray">刪除</button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "registerArray",
  props: {
    keyWord: {},
    min: {},
    max: {},
    index: {},
  },
  emits: ["edit-register-array", "delete-register-array"],
  data() {
    return {
      edit: false,
      newKeyWord: "",
      newMin: "",
      newMax: "",
    }
  },
  methods: {
    editEmitArray() {
      if (this.newKeyWord == "")
        this.newKeyWord = this.keyWord
      if (this.newMin == "")
        this.newMin = this.min
      if (this.newMax == "")
        this.newMax = this.max
      this.maxMinSwap()

      this.$emit("edit-register-array", {
        newKeyWord: this.newKeyWord,
        newMin: this.newMin,
        newMax: this.newMax,
        index: this.index,
      });

      this.newKeyWord = ""
      this.newMin = ""
      this.newMax = ""
    },
    deleteEmitArray() {
      this.$emit("delete-register-array", {
        index: this.index,
      });
    },
    maxMinSwap() {
      if (this.newMin >= this.newMax) {
        let minMax = this.newMax
        this.newMax = this.newMin
        this.newMin = minMax
      }
    },
  },
}
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}

.inputWidth {
  width: 80px;
  overflow-x: scroll;
  overflow-y: auto;
  text-overflow: clip;
  margin: 0 auto;
}
</style>