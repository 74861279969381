<template>
  <div class="search--result">
    <table class="table table-hover border border-secondary">
      <thead>
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="account--" scope="col">帳號</th>
        <th class="std_id--" scope="col">學號</th>
        <th class="name--" scope="col">姓名</th>
        <th class="std_mail--" scope="col">信箱</th>
        <th class="role--" scope="col">角色</th>
        <th class="school--" scope="col">學校</th>
        <th class="department--" scope="col">科系</th>
        <th class="operate--" scope="col">操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(AccountInformation,index) in sqlAccountInformation" :key="index">
        <th class="index--">{{ index +1 }}</th>
        <td class="account--">{{ AccountInformation.account }}</td>
        <td class="std_id--">{{ AccountInformation.std_id }}</td>
        <td class="name--">{{ AccountInformation.name }}</td>
        <td class="std_mail--">{{ AccountInformation.std_mail }}</td>
        <td class="role--">{{ AccountInformation.role }}</td>
        <td class="school--">{{ AccountInformation.school }}</td>
        <td class="department--">{{ AccountInformation.department }}</td>

        <td class="operate--">
          <button type="button" class="btn btn-outline-warning btn-sm"
                  @click="editAccountInformation(index)">
            <i class="fas fa-edit"></i>
          </button>
            <button type="button" class="btn btn-outline-info btn-sm"
                    @click="sendEmailConfirm(AccountInformation.name,AccountInformation.uuid)">
                <i class="fas fa-envelope"></i>
            </button>
          <button type="button" class="btn btn-outline-danger btn-sm" disabled>
<!--                  @click="deleteAccountInformation(AccountInformation.id,index)">-->
            <i class="far fa-trash-alt"></i>
          </button>
        </td>
      </tr>
      </tbody>
    </table>
    <editAccountInformation v-if="isModalVisible" :account="this.sqlAccountInformation[this.editIndex]"
                            @close="closeModal" @updateSearch="updateSearch"/>
  </div>
    <modal v-model="modal_msg.show" :="modal_msg"></modal>
</template>

<script>
import editAccountInformation from "./editAccountInformation.vue"
import axios from "axios";
import Modal from "@/components/module/Modal/alertModal.vue";

export default {
  name: "searchResults",
  components: {
    Modal,
    editAccountInformation
  },
  props: {
    sqlAccountInformation: {}
  },
  emits: ["delete-account","updateSearch"],
  data() {
    const modal_msg = {
      show:false
    }
    return {
      modal_msg,
      isModalVisible: false,
      editIndex: "",
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    editAccountInformation(index) {
      this.isModalVisible = true
      this.editIndex = index
      console.log(this.sqlAccountInformation[this.editIndex])
    },
    deleteAccountInformation(id, index) {
      axios.post("/api/user/search/delete", {id: id})
      this.$emit("delete-account", {
        index: index,
      });
    },
    updateSearch(){
      this.$emit("updateSearch")
    },
    sendEmailConfirm(name,uuid){
      this.modal_msg = {
        show :true,
        title: `發送啟動帳號驗證信！`,
        bodyMsg: `即將發送使用者帳號啟用驗證信給 ${name} ，是否確認要發送？`,
        cancelShow:true,
        confirmEvent : async ()=>{
          await axios.post("/api/user/sendVerifyEmail",{uuid:uuid})
        }
      }
    },

  }
}
</script>

<style scoped>
.search--result {
  width: 90%;
  margin: 20px auto;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle !important;
}

.index-- {
  width: 5%;
}

.account-- {
  width: 10%;
}

.std_id-- {
  width: 10%;
}

.std_mail-- {
  width: 20%;
}

.role-- {
  width: 10%;
}

.school-- {
  width: 10%;
}

.department-- {
  width: 10%;
}

.name-- {
  width: 15%;
}

.operate-- {
  width: 10%;
}

button {
  margin: 0 5px;
}
</style>