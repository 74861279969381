<template>
    <span v-if="ipText==null" >
      <div class="spinner-border spinner-border-sm " style="font-size: 20px;display: inline-block;vertical-align: center"></div>
    </span>
  <span v-else>{{ this.ipText }}</span>


</template>

<script>
import axios from "axios";

export default {
  name: "fetchIpModule",
  data() {
    return {
      ipText: null,
    }
  },
  created() {
    axios.post("/api/connect-ip").then((getInfo) => {
      this.ipText = getInfo.data
    })
  }

}
</script>

<style scoped>

</style>