<template>
  <div class="container-fluid mt-2">
    <div class="row" style="text-align: center;">
      <label class="col-2 fw-bold col-form-label" for="inviteCode">課程邀請碼</label>
      <div class="col-3">
        <input id="inviteCode" v-model="inviteCode" class="form-control" placeholder="inviteCode" readonly>
      </div>
      <div class="col-2">
        <button class="btn btn-outline-primary" @click="refreshInviteCode">
          <i class="fas fa-sync-alt fa-fw"></i>刷新
        </button>
      </div>
      <div class="vr px-0"></div>

      <div class="col d-flex justify-content-end" style="text-align: left;">
        <div class="col-3">
          <label class="fw-bold col-form-label px-2">加入學生</label>
        </div>
        <button class="btn btn-outline-primary px-2" @click="addModal.show = true">
          <i class="fas fa-plus-circle fa-fw"></i>
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <table class="table table-striped border">
          <thead>
          <tr>
            <th scope="col" style="width: 5%">#</th>
            <th scope="col" style="width: 15%">學生名稱</th>
            <th scope="col" style="width: 15%">學號</th>
            <th scope="col" style="width: 20%">電子郵件</th>
            <th scope="col" style="width: 20%">學校/系所</th>
            <th scope="col" style="width: 15%">操作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(rowItem,index) in stdList" :key="rowItem">
            <th>{{ rowItem.index }}</th>
            <td>{{ rowItem.name }}</td>
            <td>{{ rowItem.studentId }}</td>
            <td>{{ rowItem.email }}</td>
            <td>{{ rowItem.school }}-{{ rowItem.department }}</td>
            <td>
              <button class="btn py-0 btn-outline-warning mx-1" @click="removeUserModal(index)"><i
                  class="fas fa-trash"></i></button>
              <button v-if="rowItem.isValid" class="btn py-0 btn-outline-danger mx-1" @click="toggleUserValid(index)">
                <i class="fas fa-ban"></i>
              </button>
              <button v-if="!rowItem.isValid" class="btn py-0 btn-outline-primary mx-1" @click="toggleUserValid(index)">
                <i class="fas fa-check-circle"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @emitConfirm="removeUserStore"></alert-modal>
  <alert-modal v-model="alertModal2.show" :="alertModal2"></alert-modal>

  <modal v-if="addModal.show" @close="addModal.show = false">
    <template v-slot:header>
      <h4>新增學生</h4>
    </template>
    <template v-slot:body>
      <div class="container" style="width: 40vw">
        <div class="row">
          <label class=" col-4 col-form-label">成員Email</label>
          <div class="col-8">
            <input v-model="addModal.stdEmail" class="form-control" placeholder="example@gmail.com" type="email">
          </div>

        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button class="btn btn-primary" @click="addStudent">新增成員</button>
    </template>
  </modal>
  <toast ref="toast"></toast>
</template>

<script>
import axios from "axios";
import AlertModal from "../../../module/Modal/alertModal.vue";
import Modal from "../../../module/Modal/index.vue";
import Toast from "@/components/module/toast.vue";

export default {
  name: "studentPanel",
  components: {
    Toast,
    Modal,
    AlertModal,
  },
  emits: ["loadStudentPanel"],
  data() {
    this.fetchStdData()
    const alertModal = {
      state: "normal",
      show: false,
      title: "",
      bodyMsg: "",
    }
    const alertModal2 = {
      state: "normal",
      show: false,
      title: "",
      bodyMsg: "",
    }
    const addModal = {
      show: false,
      stdEmail: "",
    }
    return {
      stdList: [],
      alertModal,
      alertModal2,
      addModal,
      inviteCode: '',
      testState: true,
    }
  },
  methods: {
    fetchStdData: async function () {
      axios.post(`/api/course/${this.$route.params.courseId}/stdList`).then((result) => {
        if (result.data.success) {
          this.stdList = []
          result.data.student.map((mapItem) => {
            this.stdList.push({
              index: mapItem.id,
              uuid: mapItem.uuid,
              name: mapItem.name,
              studentId: mapItem.stdId,
              email: mapItem.email,
              school: mapItem.school,
              department: mapItem.department,
              isValid: mapItem.isValid,
              stdCourseId: mapItem.stdCourseId
            })
          })
          this.inviteCode = result.data.inviteCode
          this.$emit("loadStudentPanel", "loadStudentShow")
        }
      })
    },
    toggleUserValid: function (stdListIndex) {
      axios.post(`/api/course/${this.$route.params.courseId}/stdList/update`,
          {
            stdCourseId: this.stdList[stdListIndex].stdCourseId,
            isValid: !this.stdList[stdListIndex].isValid
          }).then((response) => {
        if (response.data.success) {
          this.stdList[stdListIndex].isValid = !this.stdList[stdListIndex].isValid
        } else {
          this.alertModal = {}
          this.alertModal.title = "修改錯誤"
          this.alertModal.state = "warning"
          this.alertModal.bodyMsg = "切換狀態失敗"
          this.alertModal.show = true
          this.fetchStdData()
        }
      })
    },
    removeUserModal: function (listIndex) {
      this.alertModal = {
        state: "normal",
        title: "刪除學生",
        bodyMsg: `請問是否要刪除 ${this.stdList[listIndex].name} ?`,
        crossShow: false,
        cancelShow: true,
        tempArgs: {listIndex: listIndex},
        confirmEvent: async function () {
          this.$emit("emitConfirm", this.tempArgs.listIndex)
        },
        show: true,
      }
      this.alertModal.state = "normal"
      this.alertModal.title = "刪除學生"
      this.alertModal.bodyMsg = `請問是否要刪除 ${this.stdList[listIndex].name} ?`
      this.alertModal.crossShow = false
      this.alertModal.cancelShow = true
      this.alertModal.show = true
    },
    removeUserStore: async function (stdListIndex) {
      await axios.post(`/api/course/${this.$route.params.courseId}/stdList/remove`,
          {stdCourseId: this.stdList[stdListIndex].stdCourseId}).then(result => {
        console.log(this.alertModal2)
        if (result.data.success) {
          this.alertModal2 = {
            state: "normal",
            title: "刪除成功",
            bodyMsg: "已刪除完成",
            show: true,
          }
        } else {
          this.alertModal2 = {
            state: "danger",
            title: "刪除失敗",
            bodyMsg: "刪除失敗，請聯絡管理員!!",
            show: true,
          }
        }
        this.fetchStdData()
      })
    },
    addStudent: function () {
      this.addModal.show = false
      axios.post(`/api/course/${this.$route.params.courseId}/stdList/addStudent`,
          {stdEmail: this.addModal.stdEmail}).then(response => {
        if (response.data.success) {
          this.alertModal = {
            state: "normal",
            title: "新增通知",
            bodyMsg: `新增學生 ${response.data.username} 完成!!`,
            show: true,
          }
        } else {
          this.alertModal = {
            state: "danger",
            title: "新增通知",
            bodyMsg: "新增失敗，請檢查是否輸入正確或學生已存在並重新嘗試，若一直出現錯誤請聯絡管理員。",
            show: true,
          }
        }
        this.addModal.stdEmail = ""
        this.fetchStdData()
      })
    },
    refreshInviteCode: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/inviteCode/update`).then((res) => {
        if (res.data.success) {
          this.$refs.toast.show_toast("更新成功")
        } else {
          this.alertModal = {
            show: true,
            state: "danger",
            title: "刷新失敗",
            bodyMsg: "課程邀請碼刷新失敗，請重新嘗試",
            crossShow: false,
          }
        }
        this.fetchStdData()
      })
    }
  },

}
</script>

<style scoped>

</style>