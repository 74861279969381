
<template>
  <div class=" container">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link fw-bold fs-5" :class="{'active':nowSelectType==='program'}" @click="clickProgram">程式試題</a>
      </li>
      <li class="nav-item">
        <a class="nav-link fw-bold fs-5" :class="{'active':nowSelectType==='form'}" @click="clickForm">表單試卷</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "typeSelector",
  props:{
    nowSelectType:{
      type: String,
      default:"program"
    }
  },
  data(){
  },
  methods:{
    clickProgram:function (){
      this.$emit('update:nowSelectType','program')
    },
    clickForm:function (){
      this.$emit('update:nowSelectType','form')
    }
  }
}
</script>

<style scoped>

</style>