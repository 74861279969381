import classRoomSetting from "../../components/classRoomSetting/index.vue";
import accountManage from "../../components/accountManage/index.vue";
import QBManage from "../../components/QBManage/index.vue";
import courseManage from "../../components/courseManage/index.vue";

export default [

    // 教室管理
    {
        path: '/classroomSetting',
        name: 'classRoomSetting',
        component: classRoomSetting,
        meta: {needLogin: true},
    },
    // 題庫管理
    {
        path: '/QBManage',
        name: 'QBManage',
        component: QBManage,
        meta: {needLogin: true},
    },
    // 帳戶管理
    {
        path: '/accountManage',
        name: 'accountManage',
        component: accountManage,
        meta: {needLogin: true},
    },
    // 課程管理
    {
        path: '/courseManage',
        name: 'courseManage',
        component: courseManage,
        meta: {needLogin: true},
    }

]