<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Cloud Judge</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              @click="isClick();" v-bind:class="displayClass" :disabled="isButtonClick">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" href="/Register">註冊</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/Login">登入</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!--<div class="slide" v-if="isShow" style="height: 100px"></div>-->
  <transition name="fade">
    <div v-if="this.isShow" style="height: 100px"></div>
  </transition>
  <br>
  <br>
  <br>
  <div class="mb-2"></div>
</template>

<script>
export default {
  name: "registerNavbar",
  data() {
    return {
      isShow: false,
      isButtonClick: false,
      displayClass: '',
    }
  },
  methods: {
    isClick() {
      let self = this
      // binding event with Button cool down to
      if (this.isButtonClick == false) {
        self.isShow = !this.isShow;
        this.isButtonClick = true
        setTimeout(() => {
          this.isButtonClick = false
        }, 350) // set button timeout
      }
    },

  }
}
</script>

<style scoped>
.bg-dark {
  background: #343a40;

}

.bg-dark .navbar-nav > li > a {
  color: #ffffff;
}

@media (max-width: 991px) {
  .bg-dark .dropdown-menu > li > a {
    text-align: center;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>