<template>
  <div class="card my-2">
    <div class="card-header pt-2">

      <a class="text-black pure-link text-start" data-bs-toggle="collapse" :href="'#collapse'+ collapseId" role="button">
        <div class="d-flex justify-content-between">
          <span class="d-flex"><strong>{{ collapseTitle }}</strong></span>
          <i class="fas fa-angle-down"></i>
        </div>
      </a>


    </div>
    <div :id="'collapse' + collapseId" class="collapse" :class="{'show':collapseActive}">
      <div class="card-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseMenuCollapse",
  props:{
    collapseTitle:{
      default: "NoDefine",
      type:String,
    },
    collapseActive:{
      type:Boolean,
      default:false
    },
    id:{
      default:0,
      type:Number,
    }
  },
  data(){
    return{
      collapseId:+this.id,
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

</style>