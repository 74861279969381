<template>
  <div class="card">

    <div class="container-fluid py-2">
      <div class="row ">
        <label class="form-label col-form-label col-3">選擇儲存座位表</label>
        <div class=" col-5">
          <select class="form-select" :value="selectSetting"
                  @change="$emit('update:selectSetting',$event.target.value);">
            <option value="-1" disabled>已儲存座位表</option>
            <option v-for="(classRoom,index) in listClassroomSave" :key="classRoom" :value="index">
              {{ classRoom.name }}
            </option>
          </select>
        </div>
        <div class="col-3 ">
          <button class="btn btn-outline-primary mx-2" @click="saveCourseSeatSetting"><i class="fas fa-save"></i></button>
          <button class="btn btn-outline-danger mx-2" @dblclick="deleteSaveSetting"><i class="fas fa-trash"></i></button>
        </div>

      </div>
      <div class="row mt-1 ">
        <label class="form-label col-form-label col-3">選擇教室座位表</label>
        <div class=" col-5">
          <select class="form-select" :value="selectClassroom"
                  @change="$emit('update:selectClassroom',$event.target.value)">
            <option value="-1" disabled>教室座位</option>
            <option v-for="(classRoom,index) in listSeatSetting" :key="classRoom" :value="index">
              {{ classRoom.classroomName }}
            </option>
          </select>
        </div>
        <label class="form-check-label my-2 col-3">不設定座位表</label>
        <div class="col-1 form-check my-2">
          <input type="checkbox" class="form-check-input" :checked="settingDisable"
                 @change="$emit('update:settingDisable',$event.target.checked)">
        </div>
      </div>
      <div class="row mt-2 overflow-auto">
        <table>
          <tr v-for="(seatSet,index) in modelValue" :key="seatSet">
            <td v-for="(seatData,index2 ) in seatSet" :key="seatData">
              <student-table :std-select-disable="settingDisable" :modelValue="modelValue[index][index2]"
                             @update:modelValue="value=>$emit(`update:modelValue[${index}][${index2}]`,value)"></student-table>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>

</template>

<script>
import studentTable from "./stdSeatComponent.vue";
import axios from "axios";
import AlertModal from "../../module/Modal/alertModal.vue";
import dayjs from "dayjs";

export default {
  name: "seatDistribute",
  components: {AlertModal, studentTable},
  props:{
    modelValue:{
      type: Object,
      default: ()=> {},
    },
    settingDisable:{
      type:Boolean,
      default:false,
    },
    settingTitle:{
      type: String,
    },
    selectClassroom:{
      default:-1,
    },
    selectSetting:{
      default:-1,
    },
  },
  watch:{
    selectClassroom:{
      handler:function(){
        this.fetchClassroomSetting()
      }
    },
    selectSetting:{
      handler:function(){
        this.loadSaveSetting()
      }
    }
  },
  data()
  {
    this.fetchClassroomDetail()
    this.fetchAllSaveSetting()
    const alertArgs = {
      show: false
    }
    return {
      seatSetDisable: true,
      modalCtrl: false,
      // 顯示的座位設定
      classRoomSeatData: [],
      // 儲存在系統的座位設定
      listSeatSetting :[],
      // 儲存在課程的座位設定
      listClassroomSave:[],
      alertArgs
    }
  },
  methods: {
    test:function (){
      console.log('test')
    },
    // 讀取全部課程中的資料
    fetchAllSaveSetting:async  function ()
    {
        await axios.post(`/api/classroom/save-set/${this.$route.params.courseId}/info`).then((res) =>
        {
          if(res.data.success){
            this.listClassroomSave = res.data.info
          }
        })
    },
    // 讀取教室設定
    fetchClassroomSetting: function ()
    {
      if (this.selectClassroom != -1)
      {
        let classroomID = this.listSeatSetting[this.selectClassroom].classroomId
        axios.post(`/api/classroom/setting`,{classroomId:classroomID}).then((res) =>
        {
          if(res.data.success){
            this.$emit('update:modelValue',res.data.info.seatSetting)
          }

        })
      }
    },
    // 讀取教室資料
    fetchClassroomDetail: function ()
    {
      axios.post("/api/classroom/info").then((res) =>
      {
        if(res.data.success){
          this.listSeatSetting = res.data.info
      }

      })
    },
    // 儲存課程中的設定
    saveCourseSeatSetting:function (){
      if(this.modelValue.length != 0){
        const request = {
          name:`${this.settingTitle} ${dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss")}`,
          seatSet:this.modelValue,
        }
        axios.post(`/api/classroom/save-set/${this.$route.params.courseId}/save`,request).then(res=>{
          if(res.data.success){
            this.alertArgs = {
              show: true,
              title:"儲存成功",
              bodyMsg:`該設定已儲存成功，儲存名稱為 ${request.name}`
            }
          }
          else {
            this.alertArgs = {
              show: true,
              state:'warning',
              title:"儲存失敗",
              bodyMsg:`設定檔儲存失敗，請重新嘗試!!`
            }
          }
          this.fetchAllSaveSetting()
        })
      }
    },
    // 載入課程中的設定
    loadSaveSetting:function (){
      if(this.selectSetting != -1){
        const request = {
          settingId: this.listClassroomSave[this.selectSetting].id
        }
        axios.post(`/api/classroom/save-set/${this.$route.params.courseId}/load`,request).then(response=>{
          if(response.data.success){
            this.$emit('update:modelValue',response.data.seat)
          }
          else {
            this.alertArgs = {
              show: true,
              state:'warning',
              title:"載入失敗",
              bodyMsg:`設定檔載入失敗，請重新嘗試!!`
            }
            this.fetchAllSaveSetting()
            this.$emit("update:selectSetting",-1)
          }
        })
      }
    },
    deleteSaveSetting:async function (){
      if(this.selectSetting != -1){
        const request = {
          settingId: this.listClassroomSave[this.selectSetting].id
        }
        await axios.post(`/api/classroom/save-set/${this.$route.params.courseId}/remove`,request).then(async response=>{
          if(response.data.success){
            this.alertArgs = {
              show: true,
              state:'normal',
              title:"刪除完成",
              bodyMsg:`設定檔刪除成功!!`
            }
          }
          else {
            this.alertArgs = {
              show: true,
              state:'warning',
              title:"刪除失敗",
              bodyMsg:`設定檔刪除失敗，請重新嘗試!!`
            }
          }
        })
        await this.fetchAllSaveSetting()
        this.$emit("update:selectSetting",-1)
      }
    }
  }

}
</script>

<style scoped>

</style>