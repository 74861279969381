<template>
  {{this.nowTimeText}}
</template>

<script>
import axios from "axios";
import dayjs from "dayjs"
export default {
  name: "timeModule",
  data(){
    return {
      nowTimeText:"00:00:00",
      nowTime:dayjs()
    }
  },
  props:{
    timeFormat:{
      default: "HH:mm:ss",
    }
  },
  mounted()
  {
    setInterval(()=>{
      this.nowTime = this.nowTime.add(1,'second')
      this.updateTime()
    },1000)
  },
  created()
  {
    axios.post('/api/sysTime').then((res)=>{
      if(res.data.success){
        this.nowTime = dayjs(new Date(res.data.systemTime))
      }

    })

  },
  methods:{
    updateTime(){
      this.nowTimeText = this.nowTime.format(this.timeFormat);
    }
  }
}
</script>

<style scoped>

</style>