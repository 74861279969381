<template>
  <div v-if="isReady" class="container">
    <nav aria-label="breadcrumb" class="mt-4" style="--bs-breadcrumb-divider: '>';">
      <ol class="breadcrumb">
        <li v-for="index in listOfRoute.length-1" :key="index" class="breadcrumb-item">
          <router-link class="fw-bold no_line" :to="listOfRoute[index-1].link"> {{ listOfRoute[index - 1].name }}</router-link>
        </li>
        <li class="breadcrumb-item active">
          <span v-if="force_active">
            <router-link class="fw-bold no_line" :to="listOfRoute[listOfRoute.length - 1].link"> {{ listOfRoute[listOfRoute.length - 1].name }}</router-link>
          </span>
          <span class="fw-bold" v-else>{{ listOfRoute[listOfRoute.length - 1].name }}</span>
        </li>
      </ol>
    </nav>
  </div>

</template>


<script>
import axios from "axios";

export default {
  name: "breadCrumb",
  props: {
    // listOfRoute:{
    //   type:Array,
    //   default:[{name:"首頁",link:"/"},{name:"課程列表",link:"/course"},{name:"當前頁面",link:"/"}],
    // }
  },
  data() {
    this.get_breadcrumb()
    //console.log(`current route: ${this.$route.path}`)
    return {
      listOfRoute: [],
      isReady: false,
      force_active:false
    }

  },
  methods: {
    get_breadcrumb: async function () {
      //console.log("call methods")
      try {
        await axios.get(`/api/guide${this.$route.path}`).then(response => {
          //console.log(response.data)
          if (response.data.success) {
            this.listOfRoute = response.data.routing_data
            this.force_active = response.data.force_active || false
          }else{
            this.listOfRoute = [{name: "首頁", link: "/"}]
            this.force_active = true
          }
        })
      } catch (e) {
        this.listOfRoute = [{name: "首頁", link: "/"}]
        this.force_active = true
      }
      this.isReady = true
    }

  }
}
</script>

<style scoped>
.no_line{
  text-decoration: none;
}
</style>