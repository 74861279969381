<template>
  <div v-if="uploadShow" class="form--div">
    <div class="row my-0">
      <label class="px-0 col-auto" style="font-size: 22px"><span class="fw-bold">課程：</span>({{ title.course.dep }})&nbsp;{{
          title.course.name
        }}</label>
    </div>
    <div class="row my-0 mb-2">
      <label class="px-0 col-auto" style="font-size: 20px"><span class="fw-bold">查閱學生考試：</span>{{ title.quiz }}</label>
    </div>

    <!--  搜尋參數  -->
    <div class="row" style="font-size: 24px">
      <div class="fw-bold px-0">
        <button type="button" class="btn btn-outline-dark me-2" @click="collapse('setting')">
          <i class="fas fa-search me-1"></i>搜尋條件
        </button>
        <button type="button" class="btn btn-outline-dark me-2" @click="collapse('allFormQuiz')">
          <i class="fas fa-search me-1"></i>顯示此次題組擁有的表單試卷
        </button>
      </div>
    </div>

    <button ref="allFormQuiz" class="d-none" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#allFormQuiz'" aria-expanded="false" :aria-controls="'allFormQuiz'">
    </button>
    <div class="collapse p-0 mb-3" :id="'allFormQuiz'">
        <div class="card">
          <h5 class="card-header bg-dark bg-gradient text-white" style="font-size: 20px">此次題組擁有的表單試卷</h5>
          <div class="card-body">
            <div class="card" style="font-size: 16px">
              <ul class="list-group list-group-flush" v-for="(data,index) in formQuiz" :key="data">
                <li class="list-group-item" @click="teacherForm(index)">
                  <a class="pe-3">試卷名稱：{{ data.title }}</a>
                  <span v-if="data.sort == ''" class="badge rounded-pill bg-success text-white mx-2">無分類</span>
                  <span v-if="data.sort != ''" class="badge rounded-pill bg-success text-white mx-2">{{ data.sort }}</span>
                </li>
              </ul>
              <ul v-if="formQuiz.length == 0" class="list-group list-group-flush" >
                <li class="list-group-item">無表單試卷</li>
              </ul>
            </div>
          </div>
        </div>
    </div>
    <a ref="showTeacherForm" class="d-none" target="_blank"></a>

    <button ref="setting" class="d-none" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#setting'" aria-expanded="false" :aria-controls="'setting'">
    </button>
    <div class="collapse p-0" :id="'setting'">
      <div class="border border-dark shadow--">
        <div class="row">
          <label for="std" class="col-sm-1 col-form-label">學號</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" id="std" v-model="search.std">
          </div>
          <label for="name" class="col-sm-1 col-form-label">姓名</label>
          <div class="col-sm-5">
            <input type="text" class="form-control" id="name" v-model="search.name">
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-outline-primary me-3" @click="searchStdAndName">
            查詢&nbsp;<i class="fas fa-search"></i>
          </button>
          <button type="button" class="btn btn-outline-danger me-3" @click="deleteAllSearch">
            清空&nbsp;<i class="far fa-trash-alt"></i>
          </button>
        </div>

      </div>
    </div>
    <a ref="showStudent" class="d-none" target="_blank"></a>

    <table class="table table-hover border border-secondary table-bordered">
      <thead class="bg-dark bg-gradient text-light">
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="std_id--" scope="col">學號</th>
        <th class="name--" scope="col">姓名</th>
        <th class="code--" scope="col">程式題目(
          <span class="text-success">已完成</span>/
          <span class="text-warning">編輯中</span>/
          <span class="text-danger">未開始</span>)
        </th>
        <th class="form--" scope="col">表單題目(
          <span class="text-success">已上傳</span>/
          <span class="text-danger">未上傳</span>)
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(product,index) in searchProduct" :key="product" @click="showStudent(index)">
        <th class="index--">{{ index + 1 }}</th>
        <td class="std_id--">{{ product.std_id }}</td>
        <td class="name--">{{ product.name }}</td>
        <td class="code-- fw-bold">
          <span class="text-success">{{ product.eventFinish.code.success }}</span>&nbsp;/
          <span class="text-warning">{{ product.eventFinish.code.warning }}</span>&nbsp;/
          <span class="text-danger">{{ product.eventFinish.code.none }}</span>&nbsp;
          <span>(總共{{ product.eventFinish.code.total }}題)</span>
        </td>
        <td class="form-- fw-bold">
          <span class="text-success">{{ product.eventFinish.form.success }}</span>&nbsp;/
          <span class="text-danger">{{ product.eventFinish.form.none }}</span>&nbsp;
          <span>(總共{{ product.eventFinish.form.total }}題)</span>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadShow = true"></alert-modal>
</template>

<script>
import AlertModal from "../module/Modal/alertModal.vue";
import {useToast} from "vue-toastification";
import axios from "axios";

export default {
  name: "getAllQuiz",
  components: {
    AlertModal
  },
  data() {
    this.getQuiz()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    const toast = useToast()
    const options = {
      position: "bottom-right",
      timeout: 1492,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: true,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    return {
      alertModal,
      toast,
      options,
      title: {},
      product: [],
      search: {
        std: '',
        name: '',
      },
      formQuiz:[],
      searchProduct: [],
      uploadShow: false,
    }
  },
  methods: {
    getQuiz: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/${this.$route.params.examId}/getQuiz`).then(async(res) => {
        if (res.data.success) {
          this.title = res.data.title
          this.product = res.data.product
          this.searchProduct = res.data.product
          await axios.post(`/api/course/${this.$route.params.courseId}/${this.$route.params.examId}/getFormQuiz`).then((res2) => {
            if (res2.data.success) {
              this.alertModal = {
                show: false,
              }
              this.uploadShow = true
              this.formQuiz = res2.data.form
            } else {
              this.alertModal = {
                show: true,
                state: "warning",
                title: "讀取失敗",
                bodyMsg: "請重新嘗試",
                confirmEvent: function () {
                  location.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}`
                }
              }
            }
          })
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}`
            }
          }
        }
      })
    },
    collapse(name) {
      this.$refs[name].click()
    },
    searchStdAndName() {
      if (this.search.std == '' && this.search.name == '') {
        this.deleteAllSearch()
      } else {
        this.searchProduct = this.product.filter(item => item.std_id.includes(this.search.std))
        this.searchProduct = this.searchProduct.filter(item => item.name.includes(this.search.name))
        this.search = {
          std: '',
          name: '',
        }
        this.collapse('setting')
        this.toast.success(`查詢成功`, this.options);
      }
    },
    deleteAllSearch() {
      this.search = {
        std: '',
        name: '',
      }
      this.searchProduct = this.product
      this.collapse('setting')
      this.toast.success(`重置成功`, this.options);
    },
    showStudent(index) {
      this.$refs.showStudent.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/student/${this.searchProduct[index].uuid}/quizInformation`
      this.$refs.showStudent.click()
    },
    teacherForm:function (index){
      this.$refs.showTeacherForm.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/form/${this.formQuiz[index].id}/teacher`
      this.$refs.showTeacherForm.click()
    }
  },
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.text-success {
  color: #56ca00 !important;
}

.index-- {
  width: 5%;
}

.std_id-- {
  width: 25%;
}

.name-- {
  width: 25%;
}

td.name-- {
  cursor: pointer;
}

.code-- {
  width: 25%;
}

.form-- {
  width: 20%;
}


.list-group-item>a{
  cursor: pointer;
  text-decoration: none;
}
.list-group-item>a:hover{
  text-decoration: underline;
}
li.list-group-item:hover{
  background-color:rgba(0,0,0,0.075);
}

.list-group-item > .badge {
  float: right;
}
</style>