<template>
  <tr v-if="!edit">
    <td v-if="index==0"><span class="input-group-text inputWidthNumber">{{ numberCode+1 }}</span></td>
    <td v-else><span class="input-group-text inputWidthNumber"><strong></strong></span></td>
    <td><span class="input-group-text inputWidth">{{ keyWord }}</span></td>
    <td><span class="input-group-text inputWidth">{{ min }}</span></td>
    <td><span class="input-group-text inputWidth">{{ max }}</span></td>
    <td v-if="index == length && !add">
      <button type="button" class="btn btn-primary me-0" @click="add=!add"><i
          class="fas fa-plus-circle"></i>
      </button>
    </td>
    <td v-else></td>
    <td>
      <button type="button" class="btn btn-warning me-0" @click="edit=!edit">修改</button>
    </td>
    <td>
      <button type="button" class="btn btn-danger me-0" @click="deleteKeyWordArray()">刪除</button>
    </td>
  </tr>

  <tr v-if="edit">
    <td v-if="index==0"><span class="input-group-text inputWidthNumber">{{ numberCode+1 }}</span></td>
    <td v-else><span class="input-group-text inputWidthNumber"><strong></strong></span></td>
    <td><input class="input-group-text inputWidth" :placeholder="keyWord" v-model="newKeyWord"></td>
    <td><input type="number" class="input-group-text inputWidth" :placeholder="min" v-model="newMin"></td>
    <td><input type="number" class="input-group-text inputWidth" :placeholder="max" v-model="newMax"></td>
    <td v-if="index == length && !add">
      <button type="button" class="btn btn-primary me-0" @click="add=!add"><i
          class="fas fa-plus-circle"></i>
      </button>
    </td>
    <td v-else></td>
    <td>
      <button type="button" class="btn btn-warning me-0" @click="editKeyWordArray();edit=!edit">修改</button>
    </td>
    <td>
      <button type="button" class="btn btn-danger me-0" @click="deleteKeyWordArray()">刪除</button>
    </td>
  </tr>
  <tr v-if="add">
    <td>-</td>
    <td><input type="text" class="form-control inputWidth" v-model="addKeyWord" placeholder="關鍵字"></td>
    <td><input type="number" class="form-control inputWidth" v-model="addMin" placeholder="最少"></td>
    <td><input type="number" class="form-control inputWidth" v-model="addMax" placeholder="最多"></td>
    <td>
      <button type="button" class="btn btn-primary me-0" @click="addKeyWordArray();add=!add"><i class="fas fa-check"></i>
      </button>
    </td>
    <td></td>
    <td>
      <button id="group" type="button" class="btn btn-danger me-0">刪除</button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "group",
  emits: ["add-key-word-array", "edit-key-word-array", "delete-key-word-array"],
  props: {
    numberCode: {},
    keyWord: {},
    min: {},
    max: {},
    index: {},
    length: {},
  },
  data() {
    return {
      edit: false,
      add: false,
      newKeyWord: "",
      newMin: "",
      newMax: "",
      addKeyWord: "",
      addMin: 1,
      addMax: 99,
    }
  },
  methods: {
    addKeyWordArray() {
      if (this.addMin >= this.addMax) {
        let minMax = this.addMax
        this.addMax = this.addMin
        this.addMin = minMax
      }

      if (this.addKeyWord != "") {
        this.$emit("add-key-word-array", {
          addKeyWord: this.addKeyWord,
          addMin: this.addMin,
          addMax: this.addMax,
        });
      }

      this.addKeyWord = ""
      this.addMin = 1
      this.addMax = 99
    },
    editKeyWordArray() {
      if (this.newKeyWord == "")
        this.newKeyWord = this.keyWord
      if (this.newMin == "")
        this.newMin = this.min
      if (this.newMax == "")
        this.newMax = this.max
      this.maxMinSwap()

      this.$emit("edit-key-word-array", {
        newKeyWord: this.newKeyWord,
        newMin: this.newMin,
        newMax: this.newMax,
        index: this.index,
      });

      this.newKeyWord = ""
      this.newMin = ""
      this.newMax = ""
    },
    deleteKeyWordArray() {
      this.$emit("delete-key-word-array", {
        index: this.index,
      });
    },
    maxMinSwap() {
      if (this.newMin >= this.newMax) {
        let minMax = this.newMax
        this.newMax = this.newMin
        this.newMin = minMax
      }
    },
  }
}
</script>

<style scoped>

::-webkit-scrollbar {
  display: none;
}

button {
  width: 60px;
}

.inputWidthNumber {
  width: 35px;
  margin: 0 auto;
  border: 0px;
  background-color: #ffffff;
}

.inputWidth {
  width: 80px;
  overflow-x: scroll;
  overflow-y: auto;
  text-overflow: clip;
  margin: 0 auto;
}

</style>