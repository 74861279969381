<template>
  <div v-if="uploadButtonShow" class="form--div">
    <div class="row">
      <label class="fw-bold px-0 col-auto" style="font-size: 24px">查閱學生試卷：{{title}}</label>
    </div>
    <a ref="showPaper" class="d-none" target="_blank"></a>

    <table class="table table-hover border border-secondary table-bordered">
      <thead class="bg-dark bg-gradient text-light">
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="std_id--" scope="col">學號</th>
        <th class="name--" scope="col">姓名</th>
        <th class="score--" scope="col">分數</th>
        <th class="state--" scope="col">題目狀態</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(account,index) in showStudent" :key="index" @click="show(index)">
        <th class="index--">{{ index +1 }}</th>
        <td class="std_id--">{{ account.std_id }}</td>
        <td class="name--">{{ account.name }}</td>
        <td :class="['score-- fw-bold',(account.score==0)?'text-danger':'']">
          {{ account.score }}&nbsp;/&nbsp;<span class="text-success">{{totalPoint}}</span>
        </td>
        <td :class="['state-- fw-bold',(account.state =='success')?'text-success':(account.state =='none')?'text-danger':'']">
          {{(account.state =='success')?'已上傳':(account.state =='none')?'未上傳':''}}
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div v-if="!uploadButtonShow" class="container form--div mt-3" style="text-align: center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="showModel"></alert-modal>
</template>

<script>
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "showStudent",
  components:{
    AlertModal
  },
  data(){
    this.getStudent()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return{
      alertModal,
      title:'',
      showStudent:[],
      totalPoint:0,
      uploadButtonShow:false,
    }
  },
  methods:{
    getStudent: async function () {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/showStudent`).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.uploadButtonShow = true
          this.title = res.data.title
          this.showStudent = res.data.showStudent
          this.totalPoint = res.data.totalPoint
        }
        else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
          }
        }
      })
    },
    showModel() {
      this.uploadButtonShow = true
    },
    show(index){
      this.$refs.showPaper.href=`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/form/${this.$route.params.formId}/student/${this.showStudent[index].uuid}`
      this.$refs.showPaper.click()
    }
  },
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.index--{
  width: 10%;
}

.std_id--{
  width: 30%;
}

.name--{
  width: 30%;
}

td.name--{
  cursor: pointer;
}

.score--{
  width: 15%;
}

.state--{
  width: 15%;
}
</style>