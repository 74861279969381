<template>
  <div class="card container border-dark " style="height: 100%">
    <table class="table">
      <tbody>
      <tr>
        <th>
          試卷名稱:
        </th>
        <td>{{examTitle}}</td>
      </tr>
      <tr>
        <th>
          考試資訊:
        </th>
        <td>{{examStartTime}} ~ {{examEndTime}}</td>
      </tr>

      <tr>
        <th>
          出題方式:
        </th>
        <td>{{examType}}</td>
      </tr>
      <tr>
        <th>
          試卷內容:
        </th>
        <td>{{examContent}}</td>
      </tr>
      <tr>
        <th>
          試題數量:
        </th>
        <td>{{examAmount}}</td>
      </tr>
      </tbody>

    </table>
  </div>
</template>

<script>
export default {
  name: "examDescription",
  data() {
    return {
      // examInfo:{
      //   examTitle : "第九章 實習練習",
      //   examStartTime: "2022/03/24",
      //   examEndTime: "2022/09/22",
      //   examType:"普通試卷",
      //   examContent: "程式設計-第九章",
      //   examAmount:"3"
      // }
    }
  },
  props:['examTitle','examStartTime','examEndTime','examType','examContent','examAmount']
}
</script>

<style scoped>

</style>