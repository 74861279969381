import page404 from "../../components/basicPage/page404.vue";
import page401 from "../../components/basicPage/page401.vue";
import page403 from "../../components/basicPage/page403.vue";
import page500 from "../../components/basicPage/page500.vue";
import page503 from "../../components/basicPage/page503.vue";


export default [

    // 頁面404
    {
        path: '/404',
        name: '404',
        component: page404,
    },
    // 頁面401
    {
        path: '/401',
        name: '401',
        component: page401,
    },
    // 頁面403
    {
        path: '/403',
        name: '403',
        component: page403
    },
    // 頁面500
    {
        path: '/500',
        name: '500',
        component: page500
    },
    // 頁面503
    {
        path: '/503',
        name: '503',
        component: page503
    },
]