<template>
  <div class="card container-fluid" style="width: 150px" :class="{'card-disable':seatData.disable}">
    <div class="row ">
      <p class="col m-0">座位 {{ seatLocate }}</p>
    </div>
    <div class="row">
      <label class="col-form-label col ">IP</label>
    </div>
    <div class="row">
      <div class="col">
        <input type="text" class="form-control text-center" v-model="this.seatData.seatIp" :disabled="seatData.disable"
        :class="{disabled:seatData.disable}" @change="updateSeat">
      </div>
    </div>
    <div class="row">
      <p>停用<input type="checkbox" class="ms-1" @change="updateSeat" v-model="seatData.disable"></p>
    </div>


  </div>
</template>

<script>
export default {
  name: "seatComponent",
  props: ["passSeatData"],
  data() {
    let seatData = this.passSeatData
    return {
      seatData
    }
  },
  methods:{
    updateSeat:function (){
      this.$emit("emit-update",this.seatData)
    }
  },
  computed:{
    seatLocate:function (){
      return this.seatData.seatRow+ "-" +this.seatData.seatCol
    }
  },
  watch:{
    "seatData.disable":{
      handler: function (){
        if(this.seatData.disable == true)
          this.seatData.seatIp = ""
      },
      immediate:true
    }
  }
}
</script>

<style scoped>
.card
{
  padding: 5px;
}
.form-control
{
  margin:1px;
}
.card-disable{
  background-color: rgba(0, 0, 0, 0.4);
}
</style>