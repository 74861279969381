<template>
  <headerNavbar/>
  <information/>
</template>

<script>
import headerNavbar from "../module/navbar/header-navbar.vue"
import information from "./information.vue"

export default {
  name: "index",
  components:{
    headerNavbar,information
  }
}
</script>

<style scoped>

</style>