<template>
  <modal v-if="modelValue" @close="$emit('update:modelValue',false)" :cross-show="crossShow">
    <template v-slot:header>
      <h3>
        <span v-if="state === 'normal'" class="text-primary"><i class="fas fa-info-circle fa-1x me-2"></i></span>
        <span v-if="state === 'warning'" class="text-warning"><i class="fas fa-exclamation-triangle fa-1x me-2"></i></span>
        <span v-if="state === 'danger'" class="text-danger"><i class="fas fa-exclamation-triangle fa-1x  me-2"></i></span>
        <span class="fw-bold">{{ title }}</span>
      </h3>
    </template>
    <template v-slot:body>
      <div :style="bodyStyle">
        <div v-if="bodyHtml.length !== 0" v-html="bodyHtml">
        </div>
        <span>{{ bodyMsg }}</span>
        <div v-if="state === 'loading'" class="spinner-border" role="status" style="text-align: center">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" v-if="confirmShow" @click="confirmBtnEvent">{{ confirmBtnText }}</button>
        <button class="btn btn-warning mx-2" v-if="cancelShow" @click="cancelBtnEvent">{{ cancelBtnText }}</button>
      </div>
    </template>
  </modal>
</template>

<script>
import modal from "./index.vue";
export default {
  name: "alertModal",
  components:{
    modal
  }
  ,
  props:{
    // 顯示 X
    crossShow:{
      type:Boolean,
      default:false,
    },
    // 顯示與否
    modelValue:{
      type:Boolean,
    },
    // 標題
    title:{
      default:"訊息"
    },
    // 訊息
    bodyMsg:{
      default:""
    },
    // Body 程式碼
    bodyHtml:{
      default:""
    },
    // body的CSS設定
    bodyStyle:{
      default:"width:40vw;"
    },
    // 狀態參數 normal,warning,danger,loading
    state:{
      default:"normal"
    },
    // 確認按鈕顯示
    confirmShow:{
      type:Boolean,
      default:true,
    },
    // 確認按鈕文字
    confirmBtnText:{
      type:String,
      default: "確認",
    },
    // 確認事件
    confirmEvent:{
      type:Function,
      default: null
    },
    // 取消按鈕文字
    cancelBtnText:{
      type:String,
      default: "取消"
    },
    // 取消按鈕顯示
    cancelShow:{
      type:Boolean,
      default:false,
    },
    // 取消按鈕事件
    cancelEvent:{
      type:Function,
      default: null,
    },
    // 暫存資料
    tempArgs:{
      default:{},
    },


  }
  ,
  methods:{
    confirmBtnEvent:function (){
      if(this.confirmEvent === null){
        this.$emit('update:modelValue',false)
      }
      else{
        this.$emit('update:modelValue',false)
        this.confirmEvent()
      }
    },
    cancelBtnEvent:function (){
      if(this.cancelEvent === null){
        this.$emit('update:modelValue',false)
      }
      else{
        this.$emit('update:modelValue',false)
        this.cancelEvent()
      }
    }
  }
}
</script>

<style scoped>

</style>