<template>
  <div class="form--div">
    <div class="form-group">
      <label class="col-form-label fw-bold --key">搜尋條件
        <button type="button" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOfSearch">
          <i class="fas fa-search --key"></i>
        </button>
      </label>
    </div>
  </div>
  <div id="collapseOfSearch" class="form--div border border-dark collapse">
    <form>
      <div class="form-group">
        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label-6" id="inputCourseSchool" v-model="courseSchool"
                 :placeholder="'中國文化大學'" list="schoolOptions">
          <datalist id="schoolOptions">
            <option v-for="(data,index) in school_List" :key="index" :value="data"></option>
          </datalist>
          <label for="inputCourseSchool" class="fw-bold ms-2">學校</label>
        </div>

        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label-6" id="inputCourseDep" v-model="courseDep"
                 :placeholder="'資訊工程學系'" list="departmentOptions">
          <datalist id="departmentOptions">
            <option v-for="(data,index) in department_List" :key="index" :value="data"></option>
          </datalist>
          <label for="inputCourseDep" class="fw-bold ms-2">科系</label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label-6" id="inputCourseName" v-model="courseName"
                 :placeholder="'程式設計'">
          <label for="inputCourseName" class="fw-bold ms-2">課程名稱</label>
        </div>

        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label-6" id="inputCourseDescription" v-model="courseDescription"
                 :placeholder="'程式的設計'">
          <label for="inputCourseDescription" class="fw-bold ms-2">課程說明</label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-3 form-floating">
          <input type="text" class="form-control input--label-3" id="inputCourseTeacher" v-model="courseTeacher"
                 :placeholder="'課程教授'">
          <label for="inputCourseTeacher" class="fw-bold ms-2">課程教授</label>
        </div>
        <div class="col-sm-3 form-floating">
          <input type="text" class="form-control input--label-3" id="inputCourseLang" v-model="courseLang"
                 :placeholder="'開課語言'">
          <label for="inputCourseLang" class="fw-bold ms-2">開課語言</label>
        </div>

        <div class="col-sm-2 form-floating">
          <input type="text" class="form-control input--label-2" id="inputCourseClass" v-model="courseClass"
                 :placeholder="'開課班級'">
          <label for="inputCourseClass" class="fw-bold ms-2">開課班級</label>
        </div>
        <div class="col-sm-2 form-floating">
          <input type="text" class="form-control input--label-2" id="inputCourseSemester" v-model="courseSemester"
                 :placeholder="'開課學年'">
          <label for="inputCourseSemester" class="fw-bold ms-2">開課學年</label>
        </div>
        <div class="col-sm-2 form-floating">
          <input type="text" class="form-control input--label-2" id="inputCourseTerm" v-model="courseTerm"
                 :placeholder="'開課學期'">
          <label for="inputCourseTerm" class="fw-bold ms-2">開課學期</label>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-outline-primary me-3" @click="search">
          <i class="fas fa-search"></i>
        </button>
        <button type="button" class="btn btn-outline-danger me-3" @click="deleteAllSearch">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </form>
  </div>
  <courseSearchResults :courseInformationList="courseInformationList"/>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>

import courseSearchResults from "@/components/courseManage/courseSearchResults.vue";
import axios from "axios";
import AlertModal from "@/components/module/Modal/alertModal.vue";

export default {
  name: "courseSearchCriteria",
  components: {
    courseSearchResults, AlertModal
  },
  created() {
    this.search()
    this.getSchoolAndDepartmentList()
  },
  data() {
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      alertModal,
      school_List: [],
      department_List: [],
      courseSchool: "",
      courseDep: "",
      courseName: "",
      courseDescription: "",
      courseTeacher: "",
      courseLang: "",
      courseClass: "",
      courseSemester: "",
      courseTerm: "",
      courseInformationList: {}
    }
  },
  methods: {
    search() {
      this.alertModal = {
        show: true,
        state: "loading",
        title: "搜尋中",
        crossShow: false,
        confirmShow: false
      }
      axios.post("/api/course/search", {
        course_school: this.courseSchool,
        course_dep: this.courseDep,
        course_name: this.courseName,
        course_description: this.courseDescription,
        course_teacher: this.courseTeacher,
        course_lang: this.courseLang,
        course_class: this.courseClass,
        course_semester: this.courseSemester,
        course_term: this.courseTerm,
      }).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.courseInformationList = res.data.data
        }
      })
    },
    getSchoolAndDepartmentList() {
      axios.get('/api/info/get/schoolAndDepartmentList').then((res) => {
        let sql_school_list = []
        res["data"].forEach((school) => {
          sql_school_list.push(school.school)
        })
        sql_school_list = sql_school_list.filter((ele, pos) => sql_school_list.indexOf(ele) == pos);
        this.school_List = sql_school_list

        let sql_department_list = []
        res["data"].forEach((dep) => {
          sql_department_list.push(dep["department"])
        })
        sql_department_list = sql_department_list.filter((ele, pos) => sql_department_list.indexOf(ele) == pos);
        this.department_List = sql_department_list
      })
    },
    deleteAllSearch() {
      this.courseSchool = ""
      this.courseDep = ""
      this.courseName = ""
      this.courseDescription = ""
      this.courseTeacher = ""
      this.courseLang = ""
      this.courseClass = ""
      this.courseSemester = ""
      this.courseTerm = ""
    }
  }

}
</script>


<style scoped>
.form--div {
  width: 90%;
  margin: 20px auto;
}

.--key {
  font-size: 20px;
}

form {
  margin: 10px 0;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}

label {
  text-align: left;
}

.input--label-6 {
  margin: auto;
  width: 97%;
}

.input--label-3 {
  margin: auto;
  width: 94%;
}

.input--label-2 {
  margin: auto;
  width: 91%;
}

</style>