<template>
  <div v-show="uploadShow" class="container mt-4">
    <div class="row ">
      <div class="col d-flex justify-content-start">
        <h3><strong>程式考題管理</strong></h3>
        <button v-if="false" class="btn btn-danger" @click="debug">DEBUG</button>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-5 p-3 pb-1">
        <div class="card p-2 ">
          <h5 class="fw-bold">題庫</h5>
          <div class="panel-h-vh35 overflow-auto">
            <table class="table table-border table-hover">
              <tbody>
              <tr v-for="(question,index) in dataStorage.questionBank" :key="question"
                  :class="{'on-select-ques': index == selectHandler.quesSelect}"
                  @click="selectQBQuestion(index)">
                <td class="p-1" style="font-size: 13px;width: 40%">{{ question.sort }}</td>
                <td class="p-1" style="font-size: 13px;width: 60%">{{ question.title }}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <div class="col-md-2 p-3 pb-1">
        <div class="card p-2 h-100">
          <h5 class="fw-bold">控制</h5>
          <div class="container-fluid">
            <div class="row mt-1 justify-content-center">
              <button class="col-md-8 btn btn-primary btn-size" @click="addQuestion">
                <span>加入</span>
                <i class="fas fa-plus mx-1"></i>
              </button>
            </div>
            <div class="row mt-2 justify-content-center">
              <button class="col-md-8 btn btn-danger btn-size" @click="removeQuestion">
                <span>移出</span>
                <i class="fas fa-minus mx-1"></i>
              </button>
            </div>
            <div class="row mt-2 justify-content-center">
              <button class="col-md-8 btn btn-info p-1 btn-size" @click="previewCollapse">
                <span>預覽</span>
                <i class="fas fa-eye mx-1"></i>
              </button>
            </div>
            <div class="row mt-2 justify-content-center">
              <button class="col-md-8 btn btn-secondary p-1 btn-size" @click="toEditProgramPage">
                <span>修改</span>
                <i class="fas fa-edit mx-1"></i>
              </button>
            </div>
            <div class="row mt-2 justify-content-center">
              <span>版本選擇</span>
            </div>
            <div class="row mt-2 justify-content-center">
              <select class="form-select" v-model="selectHandler.versionSelect">
                <option value="-1" disabled>版本</option>
                <option v-for="(data,index) in selectedQuesVersionList" :key='index' :value="index">{{
                    data.version
                  }}
                </option>
              </select>
            </div>
            <div class="row mt-2 justify-content-center">
              <button class="btn btn-outline-primary mx-1" style="width: min-content" @click="saveExamData"><i
                  class="fas fa-save"></i></button>
              <button class="btn btn-outline-warning mx-1" style="width: min-content"><i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 p-3 pb-1">
        <div class="card p-2 " style="overflow-y: auto">
          <h5 class="fw-bold">考試題目</h5>
          <div class="panel-h-vh35 overflow-auto">
            <table class="table table-border table-hover">
              <tbody>
              <tr v-for="(exam,index) in dataStorage.examBank" :key="exam"
                  :class="{'on-select-ques': index === selectHandler.examSelect}"
                  @click="selectHandler.examSelect = index">
                <td class="p-1" style="font-size: 13px;width: 30%">{{ exam.sort }}</td>
                <td class="p-1" style="font-size: 13px;width: 50%">{{ exam.title }}</td>
                <td class="p-1" style="font-size: 13px;width: 20%">{{ exam.version }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md p-3">
        <display-panel ref="displayPanel" :illustrate="this.illustrate" :files="this.fileData"></display-panel>
      </div>
    </div>
  </div>
  <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
</template>

<script>
import DisplayPanel from "./displayPanel.vue";
import axios from "axios";
import AlertModal from "../../../module/Modal/alertModal.vue";

export default {
  name: "managePanel",
  components: {AlertModal, DisplayPanel},
  data() {
    const alertArgs = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    const selectHandler = {
      quesSelect: -1,
      examSelect: -1,
      versionSelect: -1,
    }
    const dataStorage = {
      questionBank: [],
      examBank: []
    }
    this.fetchExamBank()
    this.fetchQuestionBank()
    return {
      dataStorage,
      selectHandler,
      alertArgs,
      illustrate: {},
      fileData: [],
      uploadShow: false,
    }
  },
  methods: {
    debug: function () {
      console.log(this.dataStorage.examBank)
      console.log(this.dataStorage.questionBank)
      console.log(this.selectHandler)
    },
    //`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.quizId}/code/list`
    fetchExamBank: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/code/list`).then(response => {
        if (response.data.success) {
          response.data.info.map(mapItem => {
            this.dataStorage.examBank.push({
              sort: mapItem.sort,
              title: mapItem.title,
              version: mapItem.version,
              examQuestionId: mapItem.examQuestionId
            })
          })
        }
      })
    },
    fetchQuestionBank: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/code/list`)
          .then(response => {
            if (response.data.success) {
              let info = response.data.info
              const infoKey = Object.keys(info)
              infoKey.map(mapSort => {
                const codeName = Object.keys(info[mapSort])
                codeName.map(mapCodeName => {
                  let versionData = []
                  info[mapSort][mapCodeName].map(mapVersionData => {
                    versionData.push({
                      examQuestionId: mapVersionData.examQuestionId,
                      version: mapVersionData.examVersion
                    })
                  })
                  this.dataStorage.questionBank.push({
                    sort: mapSort,
                    title: mapCodeName,
                    version: versionData
                  })
                })
              })
              this.alertArgs = {
                show: false,
              }
              this.uploadShow = true
            }
          })
    },
    selectQBQuestion: function (index) {
      this.selectHandler.quesSelect = index
      this.selectHandler.versionSelect = this.dataStorage.questionBank[this.selectHandler.quesSelect].version.length - 1
    },
    addQuestion: function () {
      let isRepeat = this.isQuestionRepeat(this.dataStorage.questionBank[this.selectHandler.quesSelect].sort
          , this.dataStorage.questionBank[this.selectHandler.quesSelect].title)
      if (this.selectHandler.versionSelect != -1) {
        // 題目是否重複了
        if (isRepeat) {
          let listOfRemove = []
          // 搜尋重複的題目
          this.dataStorage.examBank.map((mapItem, index) => {
            if (mapItem.sort == this.dataStorage.questionBank[this.selectHandler.quesSelect].sort &&
                mapItem.title == this.dataStorage.questionBank[this.selectHandler.quesSelect].title)
              listOfRemove.push(index)
          })
          // 移除重複的題目
          listOfRemove.map(mapItem => this.dataStorage.examBank.splice(mapItem, 1))
        }
        this.dataStorage.examBank.push({
          sort: this.dataStorage.questionBank[this.selectHandler.quesSelect].sort,
          title: this.dataStorage.questionBank[this.selectHandler.quesSelect].title,
          version: this.dataStorage.questionBank[this.selectHandler.quesSelect].version[this.selectHandler.versionSelect].version,
          examQuestionId: this.dataStorage.questionBank[this.selectHandler.quesSelect].version[this.selectHandler.versionSelect].examQuestionId
        })
      }
      this.selectHandler.examSelect = null
    },
    removeQuestion: function () {
      this.dataStorage.examBank.splice(this.selectHandler.examSelect, 1)
      this.selectHandler.examSelect = null
    },
    saveExamData: async function () {
      let codeList = []
      this.dataStorage.examBank.map(mapItem => {
        codeList.push(mapItem.examQuestionId)
      })
      await axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/code/save`, {examQuestion: codeList}).then(response => {
        if (response.data.success) {
          this.alertArgs = {
            show: true,
            crossShow: false,
            title: "儲存成功",
            bodyMsg: "儲存考題資訊成功"
          }
        } else {
          this.alertArgs = {
            show: true,
            crossShow: false,
            state: "warning",
            title: "儲存失敗",
            bodyMsg: "儲存考題資訊失敗，請重新嘗試"
          }
        }
      })
    },
    toEditProgramPage:function (){
      if(this.selectHandler.quesSelect >= 0 && this.selectHandler.versionSelect >= 0){
        const programId = this.dataStorage.questionBank[this.selectHandler.quesSelect].version[this.selectHandler.versionSelect].examQuestionId
        this.$router.push(`/course/${this.$route.params.courseId}/program/${programId}`)
      }
    },
    previewCollapse: async function () {
      if (this.selectHandler.versionSelect != -1) {
        this.fileData = []
        await axios.post(`/api/course/code/information`, {
          id: this.dataStorage.questionBank[this.selectHandler.quesSelect].version[this.selectHandler.versionSelect].examQuestionId
        }).then((res) => {
          console.log(res.data)
          if (res.data.success) {
            this.illustrate = res.data.illustrate
            this.fileData = res.data.fileData
            this.$refs.displayPanel.viewOn();
          } else {
            this.alertArgs = {
              show: true,
              state: "warning",
              title: "讀取失敗",
              bodyMsg: "請重新嘗試",
            }
          }
        })
      }
    },
    isQuestionRepeat: function (sort, title) {
      let isRepeat = false
      this.dataStorage.examBank.map((mapItem) => {
        if (mapItem.sort == sort && mapItem.title === title)
          isRepeat = true
      })
      return isRepeat
    }
  },
  computed: {
    selectedQuesVersionList: function () {
      let versionList = []
      if (this.selectHandler.quesSelect != -1 && this.selectHandler.quesSelect != null) {
        this.dataStorage.questionBank[this.selectHandler.quesSelect].version.map(mapItem => {
          versionList.push(mapItem)
        })
      }
      return versionList
    }
  }
}
</script>

<style scoped>
.panel-h-vh35 {
  height: 290px;
}

.on-select-ques {
  background-color: rgba(243, 190, 56, 0.5);
}

.btn-size {
  padding: 3px;
  size: 10px;
}
</style>