<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header class="modal-header">
          <span class="fw-bold">修改帳號</span>
          <button type="button" class="btn-close" @click="close"></button>
        </header>

        <section class="modal-body">
          <div class="form-group">
            <div class="col-sm-6 form-floating">
              <input type="text" class="form-control input--label" id="staticEmail" v-model="form_data.stdMail"
                     :placeholder="'A8250351@g.pccu.edu.tw'">
              <label for="staticEmail" class="fw-bold">信箱</label>
            </div>

            <div class="col-sm-3 form-floating">
              <input type="text" class="form-control input--label col-sm--3" id="inputStdId" v-model="form_data.stdId"
                     :placeholder="'A8222820'">
              <label for="inputStdId" class="fw-bold">學號</label>
            </div>

            <div class="col-sm-3 form-floating">
              <input type="text" class="form-control input--label col-sm--3" id="inputPhone" v-model="form_data.phone"
                     :placeholder="'0979777777'">
              <label for="inputPhone" class="fw-bold">電話</label>
            </div>
          </div>

          <div class="row mb-sm-1 text-start">
            <small v-if="stdMailError" class="col ms-3 text-danger">信箱已存在，請重新輸入</small>
            <small v-if="stdIdError" class="col ms-1 text-danger">學號已存在，請重新輸入</small>
          </div>

          <div class="form-group">
            <div class="col-sm-6 form-floating">
              <select id="school" class="form-select select--role input--label" v-model="form_data.school"
                      @change="updateDepart" @click="updateDepart">
                <option value="" :selected="true" disabled>學校</option>
                <option v-for="(data,index) in school_List" :key="index" :value="data">{{ data }}</option>
              </select>
              <label for="school" class="fw-bold">學校</label>
            </div>

            <div class="col-sm-6 form-floating">
              <select id="inputDepartment" class="form-select select--role input--label" v-model="form_data.department">
                <option value="" :selected="true" disabled>科系</option>
                <option v-for="(data,index) in department_List" :key="index" :value="data">{{ data }}</option>
              </select>
              <label for="inputDepartment" class="fw-bold">科系</label>
            </div>
          </div>

          <div class="form-group">

            <div class="col-sm-4 form-floating">
              <input type="text" class="form-control input--label col-sm--4" id="inputAcademicYear"
                     v-model="academicYear"
                     :placeholder="'111'">
              <label for="inputAcademicYear" class="fw-bold">學年</label>
            </div>

            <div class="col-sm-4 form-floating">
              <input type="text" class="form-control input--label col-sm--4" id="inputTerm" v-model="form_data.term"
                     :placeholder="'1'">
              <label for="inputTerm" class="fw-bold">學期</label>
            </div>

            <div class="col-sm-4 form-floating">
              <select id="verify" class="form-select select--role input--label col-sm--4" v-model="form_data.verify">
                <option value="" :selected="true" disabled>驗證</option>
                <option v-for="(data,index) in verifyArray" :key="index" :value="data">{{ data }}</option>
              </select>
              <label for="verify" class="fw-bold">驗證</label>
            </div>
          </div>


          <div class="form-group">
            <div class="col-sm-4 form-floating">
              <input type="text" class="form-control input--label" id="inputAccount" v-model="form_data.stdAccount"
                     :placeholder="'帳號'">
              <label for="inputAccount" class="fw-bold">帳號</label>
            </div>

            <div class="col-sm-4 form-floating">
              <input type="text" class="form-control input--label" id="inputName" v-model="form_data.name"
                     :placeholder="'姓名'">
              <label for="inputName" class="fw-bold">姓名</label>
            </div>

            <div class="col-sm-2 form-floating">
              <select id="role" class="form-select select--role input--label" v-model="form_data.role">
                <option value="" :selected="true" disabled>角色</option>
                <option v-for="(data,index) in roleArray" :key="index" :value="data">{{ data }}</option>
              </select>
              <label for="role" class="fw-bold">角色</label>
            </div>

              <div class="col-sm-2 form-floating">
                  <select id="role" class="form-select select--role input--label" v-model="form_data.state">
                      <option value="" :selected="true" disabled>狀態</option>
                      <option v-for="(data,index) in accountState" :key="index" :value="data">{{ data }}</option>
                  </select>
                  <label for="role" class="fw-bold">角色</label>
              </div>
          </div>

          <div v-if="stdAccountError" class="row mb-sm-1 text-start">
            <small class="col ms-3 text-danger">帳號已重複</small>
          </div>

        </section>

        <footer class="modal-footer">
          <button type="button" class="btn btn-outline-success btn-sm me-2" @click="change"><i class="fas fa-check"></i></button>
          <button type="button" class="btn btn-outline-danger btn-sm me-2" @click="close"><i class="fas fa-undo"></i></button>
        </footer>
      </div>
    </div>
  </div>
  <toast ref="toast"></toast>
</template>

<script>
import axios from "axios";
import Toast from "@/components/module/toast.vue";

export default {
  name: "Modal",
  components: {Toast},
  props: {
    account: {},
  },
  emits: ["updateSearch", "close"],
  data() {
    this.getPersonalInformation()
    this.updateSchool()
    const form_data = {
      uuid:"",
      stdMail:"",
      stdId:"",
      stdAccount:"",
      name:"",
      role:"",
      phone:"",
      school:"",
      department:"",
      academicYear:"",
      term:"",
      verify:"",
      state:""
    }
    return {
      form_data,
      school_List: [],
      department_List: [],
      verifyArray: ['0', '1'],
      roleArray: ['Guest', 'Student', 'Teacher', 'Admin'],
      accountState : ["Active","Suspend"],
      stdIdError: false,
      stdMailError: false,
      stdAccountError: false
    }
  },
  methods: {
    updateSchool: function () {
      axios.get('/api/info/get/school').then((res) => {
        let sql_school_list = []
        res["data"].forEach((school) => {
          sql_school_list.push(school.school)
        })
        sql_school_list = sql_school_list.filter((ele, pos) => sql_school_list.indexOf(ele) == pos);
        this.school_List = sql_school_list
      })

      let dep = this.account.school
      axios.get(`/api/info/get/school/${dep}`).then((res) => {
        let sql_department_list = []
        res["data"].forEach((dep) => {
          sql_department_list.push(dep["department"])
        })
        this.department_List = sql_department_list
      })
    },
    updateDepart: function () {
      let elem = document.getElementById("school")
      let dep = elem.value

      axios.get(`/api/info/get/school/${dep}`).then((res) => {
        let sql_department_list = []
        res["data"].forEach((dep) => {
          sql_department_list.push(dep["department"])
        })
        this.department_List = sql_department_list
        this.department = this.department_List[0]
      })
    },
    getPersonalInformation() {
      axios.post("/api/user/search/personalInformation", {
        id: this.account.id,
      }).then((res) => {
        this.form_data.uuid = res.data.uuid
        this.form_data.stdMail = res.data.std_mail
        this.form_data.stdId = res.data.std_id
        this.form_data.phone = res.data.phone_number
        this.form_data.school = res.data.school
        this.form_data.department = res.data.department
        this.form_data.stdAccount = res.data.account
        this.form_data.academicYear = res.data.academic_year
        this.form_data.term = res.data.term
        this.form_data.verify = res.data.verify
        this.form_data.name = res.data.name
        this.form_data.role = res.data.role
        this.form_data.state = res.data.state
      })
    },
    close() {
      this.$emit('close');
    },
    change() {
      this.stdIdError = false
      this.stdMailError = false
      this.stdAccountError = false
      axios.post("/api/user/search/personalInformation", {id: this.account.id,}).then((resAccount) => {
        axios.post("/api/user/search/findRepeat", {
          std_id: this.stdId,
          account: this.stdAccount,
          std_mail: this.stdMail,
        }).then((res) => {
          res.data.forEach(accountInformation => {
            if (this.stdId == accountInformation.std_id && this.stdId != resAccount.data.std_id)
              this.stdIdError = true
            if (this.stdMail == accountInformation.std_mail && this.stdMail != resAccount.data.std_mail)
              this.stdMailError = true
            if (this.stdAccount == accountInformation.account && this.stdAccount != resAccount.data.account)
              this.stdAccountError = true
          })
          if (!this.stdAccountError && !this.stdMailError && !this.stdIdError) {
            const update_data = {
              uuid: this.account.uuid,
              stdMail: this.form_data.stdMail,
              stdId: this.form_data.stdId,
              phone: this.form_data.phone,
              school: this.form_data.school,
              department: this.form_data.department,
              stdAccount: this.form_data.stdAccount,
              academicYear: this.form_data.academicYear,
              term: this.form_data.term,
              verify: this.form_data.verify,
              name: this.form_data.name,
              role: this.form_data.role,
              state:this.form_data.state
            }
            axios.post("/api/user/search/edit/accountInformation", update_data).then(response=>{
              if(response.data.success){
                this.$refs.toast.show_toast("修改成功","success")
                this.$emit("updateSearch")
                this.$emit('close')
              }else{
                this.$refs.toast.show_toast("修改失敗","danger")
                this.$emit("updateSearch")
                this.$emit('close')
              }
            })


          }
        })
      })

    }
  }
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}


.modal-body {
  margin: 20px 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


form {
  margin: 10px 0;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}

label {
  text-align: left;
}

.input--label {
  margin: auto;
  width: 97%;
}

.col-sm--3 {
  width: 94%;
}

.col-sm--4 {
  width: 95.5%;
}

#inputName {
  width: 94%;
}

#role {
  width: 94%;
}

.select--role {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

</style>