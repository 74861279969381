<template>
  <navbar></navbar>
  <create-course></create-course>
</template>

<script>
import navbar from "../module/navbar"
import createCourse from "./createCourse.vue"
export default {

  components:{
    // eslint-disable-next-line vue/no-unused-components
    navbar,
    createCourse
  },
  name: "index"
}
</script>

<style scoped>

</style>