<template>
    <div class="card" >
      <div class="card-header">
        <strong>時間設定</strong>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item list-group-item-action" v-for="(data,index) in dataObj" :key="index"
            @click="this.$emit('selectItem',index)" :class="{'list-group-item-select':nowChoose === index}">{{ data.title }}
        </li>
        <li class="list-group-item list-group-item-action" @click="newTime"
            :class="{'list-group-item-select':nowChoose === dataObj.length}"><i class="fas fa-plus"></i>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: "timeSideBar",
  props: ["dataObj","nowChoose"]
  ,
  data() {
  },
  methods: {
    newTime: function () {
      this.$emit("addTime",'新的設定檔')
    }
  },
}
</script>

<style scoped>
.list-group-item-action:hover {
  background-color: rgba(6, 245, 245, 0.5);
}
.list-group-item-select {
  background-color: rgba(6, 245, 30, 0.5);
}

</style>