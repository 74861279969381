<template>
  <navbar></navbar>
  <manage-panel></manage-panel>
</template>

<script>
import navbar from "../../../module/navbar";
import ManagePanel from "./managePanel.vue";
export default {
  name: "index",
  components: {ManagePanel, navbar}
}
</script>

<style scoped>

</style>