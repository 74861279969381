<template>
  <div class="container">
    <table class="table table-striped" v-if="nowSelectType==='program'">
      <thead>
        <tr>
          <th scope="col" style="width: 5%">#</th>
          <th scope="col" style="width: 20%" v-if="mode==='import'">來源課程</th>
          <th scope="col" style="width: 10%">分類</th>
          <th scope="col" :style="{'width':(mode==='import')?'15%':'35%'}">題目</th>
          <th scope="col" style="width: 10%">使用語言</th>
          <th scope="col" style="width: 5%">版本號</th>
          <th scope="col" style="width: 10%">建立人</th>
          <th scope="col" style="width: 15%">操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data,index) in programData" :key="index">
          <td class="align-middle">{{index+1}}</td>
          <td class="align-middle" v-if="mode==='import'">{{ data.course_semester }}-{{ data.course_name }}-{{ data.course_class }}</td>
          <td class="align-middle">{{ data.sort }}</td>
          <td class="align-middle">{{ data.title }}</td>
          <td class="align-middle">{{ data.lang }}</td>
          <td class="align-middle">{{ data.version }}</td>
          <td class="align-middle">{{ data.creator }}</td>
          <td>
            <button class="btn btn-outline-info mx-1 disabled " v-if="mode==='import'" disabled><i class="fas fa-eye"></i></button>
            <button class="btn btn-outline-primary mx-1" v-if="mode==='manage'" @click="$router.push(`program/${data.id}`)">
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-outline-danger mx-1 disabled" v-if="mode==='manage'" disabled><i class="fas fa-trash"></i></button>
            <button class="btn btn-outline-success mx-1" v-if="mode==='import'" @click="importQuest(data.id)"><i class="fas fa-file-import"></i></button>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-striped" v-else>
      <thead>
      <tr>
        <th scope="col" style="width: 5%">#</th>
        <th scope="col" style="width: 20%">分類</th>
        <th scope="col" style="width: 30%">題目</th>
        <th scope="col" style="width: 10%">數量</th>
        <th scope="col" style="width: 15%">建立人</th>
        <th scope="col" style="width: 20%">操作</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data,index) in formData" :key="index">
        <td class="align-middle">{{index+1}}</td>
        <td class="align-middle">{{ data.sort }}</td>
        <td class="align-middle">{{ data.title }}</td>
        <td class="align-middle">{{ data.amount }}</td>
        <td class="align-middle">{{ data.creator }}</td>
        <td>
          <button class="btn btn-outline-info mx-1 disabled" v-if="mode==='import'" disabled><i class="fas fa-eye"></i></button>
          <button class="btn btn-outline-primary mx-1" v-if="mode==='manage'" @click="$router.push(`form/${data.id}/editFormPaper`)">
            <i class="fas fa-edit"></i>
          </button>
          <button class="btn btn-outline-danger mx-1 disabled" v-if="mode==='manage'" disabled><i class="fas fa-trash"></i></button>
          <button class="btn btn-outline-success mx-1 disabled" v-if="mode==='import'" disabled><i class="fas fa-file-import"></i></button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>


<script>
export default {
  name: "displayResult",
  props:{
    nowSelectType:{
      type: String,
      default:"program"
    },
    mode: {type: String, default: "manage"},
    formData:{
      type: Array,
      default :[],
    },
    programData:{
      type: Array,
      default :[],
    }
  },
  methods:{
    importQuest:function (id){
      this.$emit("importQuest",id)
    }
  }
}
</script>

<style scoped>

</style>