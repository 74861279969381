<template>
  <navbar></navbar>
  <q-b-interface></q-b-interface>
</template>

<script>
import navbar from "../module/navbar";
import QBInterface from "./QBInterface.vue";
export default {
  name: "index",
  components:{QBInterface, navbar}
}
</script>

<style scoped>

</style>