<template>
  <div class="row mt-2">
    <div class="col">
      <div class="card" v-if="allPerm.examManage">
        <div class="d-flex justify-content-end px-2">
          <span>
            <a class="link-primary mx-1" :href="`${$route.params.examId}/edit`">設定考試</a>
          </span>
          <span>
            <a class="link-primary mx-1" :href="`${$route.params.examId}/ques-group-setting`">題組設定</a>
          </span>
          <span>
            <a class="link-primary mx-1" :href="`${$route.params.examId}/code-manage`">設定程式考題</a>
          </span>
          <span>
            <a class="link-primary mx-1" :href="`${$route.params.examId}/examinationPaper`">設定表單試卷</a>
          </span>
          <span>
            <a class="link-primary mx-1" :href="`${$route.params.examId}/showQuiz`">顯示考卷學生資料</a>
          </span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import auth from "../../store/auth";

export default {
  name: "examCtrlBar",
  data() {
    this.updateAllPerm()
    return {
      allPerm: {}
    }
  },
  methods: {
    updateAllPerm: async function () {
      let data = await auth.tryGetCourseManage(this.$route.params.courseId)
      this.allPerm = data
    }
  },
  computed:{
  }
}
</script>

<style scoped>

</style>