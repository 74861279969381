<template>
  <div v-if="uploadShow" class="card--">
    <div class="row card-group">
      <div class="col-sm-4">
        <div class="card h-100">
          <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">提交資訊</h5>
          <div class="card-body" style="text-align: left;">
            <div class="row my-1">
              <label for="sort" class="col-4 fw-bold col-form-label px-0">考試分類：</label>
              <div class="col-8 px-0">
                <input id="sort" class="form-control readonly" v-model="information.sort" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="title" class="col-4 fw-bold col-form-label px-0">考試題目：</label>
              <div class="col-8 px-0">
                <input id="title" class="form-control readonly" v-model="information.title" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="version" class="col-4 fw-bold col-form-label px-0">版本號：</label>
              <div class="col-8 px-0">
                <input id="version" class="form-control readonly" v-model="information.version" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="name" class="col-4 fw-bold col-form-label px-0">姓名：</label>
              <div class="col-8 px-0">
                <input id="name" class="form-control readonly" v-model="information.name" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="stdId" class="col-4 fw-bold col-form-label px-0">學號：</label>
              <div class="col-8 px-0">
                <input id="stdId" class="form-control readonly" v-model="information.stdId" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="commit" class="col-4 fw-bold col-form-label px-0">提交時間：</label>
              <div class="col-8 px-0">
                <input id="commit" class="form-control readonly" v-model="record.buildTime" placeholder="Commit at"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="status" class="col-4 fw-bold col-form-label px-0">提交狀態：</label>
              <div class="col-8 px-0">
                <input id="status" class="form-control readonly" v-model="record.passText"
                       placeholder="Status" readonly>
              </div>
            </div>
            <div class="row my-1">
              <label for="IPAddress" class="col-4 fw-bold col-form-label px-0">作答IP：</label>
              <div class="col-8 px-0">
                <input id="IPAddress" class="form-control readonly" v-model="record.ipAddress" placeholder="IP Address"
                       readonly>
              </div>
            </div>
            <div class="row my-1">
              <label class="col-4 fw-bold col-form-label px-0">IP 檢查：</label>
              <label class="col-2 fw-bold col-form-label" style="padding: 0.375rem 0.75rem;">
                <span v-if="record.ipCheck" class="text-success"><i class="fas fa-check fa-fw"></i></span>
                <span v-if="!record.ipCheck" class="text-danger"><i class="fas fa-times fa-fw"></i></span>
              </label>
              <div class="col-6 px-0">
                <button :class="['btn btn-outline-secondary border float-end',(uploadIp)?'d-none':'']"
                        type="button" @click="uploadIp = !uploadIp"><i class="fas fa-edit fa-fw"></i></button>


                <button :class="['btn btn-outline-secondary border float-end',(uploadIp)?'':'d-none']"
                        type="button" @click="uploadIp = !uploadIp">取消&nbsp;<i class="fas fa-undo fa-fw"></i></button>

                <button :class="['btn btn-outline-danger border float-end',(uploadIp)?'':'d-none']"
                        type="button" @click="changeIp(0)"><i class="fas fa-times fa-fw"></i></button>
                <button :class="['btn btn-outline-success border float-end',(uploadIp)?'':'d-none']"
                        type="button" @click="changeIp(1)"><i class="fas fa-check fa-fw"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-8">
        <div class="card h-100">
          <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">學生程式碼</h5>
          <div class="card-body">
            <div class="row my-0">
              <ul class="nav nav-tabs dark-tabs">
                <li v-for="(data,index) in fileData" :key="index" :class="{'active':pageArgs.selectedFile === index}"
                    class="nav-item">
                  <button class="nav-link" style="color: inherit" @click="selectFile(index)">
                    <i class="far fa-file-code mx-2"></i>
                    <span>{{ data.fileName }}</span>
                  </button>
                </li>
              </ul>
            </div>
            <div class="row my-0">
              <ace-editor ref="ace" v-model:value="fileData[pageArgs.selectedFile].code"></ace-editor>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="card h-100">
          <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">關鍵字警訊</h5>
          <div class="card-body">
            <table v-if="record.keywordInfo.failKeyword.length!=0" class="table table-hover table-borderless">
              <thead class="bg-dark bg-gradient text-light tbody--border">
              <tr>
                <th class="keyword--" scope="col">keyword</th>
                <th class="use--" scope="col">use</th>
                <th class="min--" scope="col">min</th>
                <th class="max--" scope="col">max</th>
              </tr>
              </thead>

              <tbody class="tbody--border" v-for="(product) in record.keywordInfo.failKeyword" :key="product">
              <tr v-for="(keyword) in product" :key="keyword">
                <th class="keyword">{{ keyword.keyword }}</th>
                <td class="use--">{{ keyword.matchAmount }}</td>
                <td class="min--">{{ keyword.requireMin }}</td>
                <td class="max--">{{ keyword.requireMax }}</td>
              </tr>
              </tbody>
            </table>
            <h5 v-if="record.keywordInfo.failKeyword.length==0" class="card-title">無關鍵字錯誤</h5>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card h-100">
          <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">預設輸入與輸出</h5>
          <div class="card-body">
            <h5 class="card-title fw-bold">預設輸入</h5>
            <p v-if="JSON.stringify(record.defaultInput) === '{}'" class="card-text text-danger">error</p>
            <pre v-if="JSON.stringify(record.defaultInput) !== '{}'"
                 class="card-text fs18--">{{ this.Base64.decode(record.defaultInput.execute_output) }}</pre>
            <hr>
            <h5 class="card-title fw-bold">預設輸出</h5>
            <p v-if="JSON.stringify(record.defaultOutput) === '{}'" class="card-text text-danger">error</p>
            <pre v-if="JSON.stringify(record.defaultOutput) !== '{}'"
                 class="card-text fs18--">{{ this.Base64.decode(record.defaultOutput.execute_output) }}</pre>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="card h-100">
          <h5 class="card-header bg-success bg-gradient text-white" style="font-size: 20px">學生輸出</h5>
          <div class="card-body">
            <p v-if="JSON.stringify(record.studentOutput) === '{}'" class="card-text text-danger">error</p>
            <pre v-if="JSON.stringify(record.studentOutput) !== '{}'"
                 class="card-text fs18--">{{ this.Base64.decode(record.studentOutput.execute_output) }}</pre>
          </div>
        </div>
      </div>
    </div>

  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadShow = true"></alert-modal>
</template>

<script>
import aceEditor from "../../../module/aceEditor.vue";
import AlertModal from "../../../module/Modal/alertModal.vue";
import axios from "axios";
import {useToast} from "vue-toastification";

export default {
  name: "showCodeRecord",
  components: {
    aceEditor, AlertModal
  },
  data() {
    this.getRecord()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    const pageArgs = {
      selectedFile: 0,
    }
    const Base64 = require('js-base64')
    const toast = useToast()
    const options = {
      position: "bottom-right",
      timeout: 1492,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: true,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    return {
      alertModal,
      pageArgs,
      Base64,
      toast,
      options,
      information: {},
      record: {},
      uploadShow: false,
      uploadIp: false,
      fileData: [],
    }
  },
  methods: {
    getRecord: async function () {
      await axios.post(`/api/program/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.programId}/${this.$route.params.uuid}/${this.$route.params.recordId}/getRecord`).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.information = res.data.information
          this.record = res.data.record
          this.fileData = res.data.fileData
          this.uploadShow = true
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/student/${this.$route.params.uuid}/submitRecord`
            }
          }
        }
      })
    },
    selectFile: function (fileIndex) {
      this.pageArgs.selectedFile = fileIndex
      this.$refs.ace.reloadData(this.fileData[this.pageArgs.selectedFile].code)
    },
    changeIp: function (ipCheck) {
      axios.post(`/api/program/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/record/${this.$route.params.recordId}/changeIp`, {ipCheck: ipCheck}).then((res) => {
        if (res.data.success) {
          this.record.ipCheck = ipCheck
          this.uploadIp = !this.uploadIp
          this.toast.success(`變更成功`, this.options);
        } else {
          this.record.ipCheck = ipCheck
          this.uploadIp = !this.uploadIp
          this.toast.error(`變更失敗`, this.options);
        }
      })
    }
  },
}
</script>

<style scoped>
.card-- {
  width: 94%;
  max-width: 94vw;
  margin: 20px auto;
  text-align: left;
}

.fs18-- {
  font-size: 18px
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.dark-tabs {
  margin: 0px 0px -1px 0px;
  border-radius: 10px 10px 0px 0px;
  color: #FFFFFF !important;
  background-color: rgb(58, 59, 58) !important;
}

.active {
  color: #88dcea !important;
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(15, 17, 15) !important;
  border-color: white !important;
}

.nav-link {
  color: #dee2e6;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.readonly {
  background: #ffffff;
  border: 0px;
}

.readonly:focus {
  box-shadow: none;
}

.keyword-- {
  width: 70%;
}

.use-- {
  width: 10%;
}

.min-- {
  width: 10%;
}

.max-- {
  width: 10%;
}

.tbody--border {
  border: 1px solid #212529 !important;
}


::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}
</style>