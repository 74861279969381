<template>
  <registerNavbar></registerNavbar>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import axios from "axios";
import registerNavbar from "../module/navbar/Register-navbar.vue";
import AlertModal from "../module/Modal/alertModal.vue";
export default {
  name: "Active",
  components: {AlertModal, registerNavbar},
  data(){
    this.verify()
    return{
      alertModal : {}
    }
  },
  methods:{
    verify(){
      axios.get(`/api/active/${this.$route.query.token}`,{timeout:10000}).then((res)=>{
        if(res.data.success){
          this.alertModal = {
            show : true,
            title: "啟用成功",
            bodyMsg:"帳號啟用成功，將跳轉至登入頁面進行登入。",
            crossShow:false,
            confirmEvent:function (){
              this.$router.push('/login')
            }
          }
        }else{
          this.alertModal = {
            show : true,
            state:"danger",
            title: "啟用失敗",
            bodyMsg:"帳號啟用失敗，請聯絡網站管理員。",
            crossShow:false,
            confirmEvent:function (){
              this.$router.push('/login')
            }
          }
        }
      }).catch(e=>{
        this.alertModal = {
          show : true,
          state:"danger",
          title: "啟用失敗",
          bodyMsg:"帳號啟用失敗，請聯絡網站管理員。",
          crossShow:false,
          confirmEvent:function (){
            this.$router.push('/login')
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>