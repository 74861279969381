<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header class="modal-header">
          <slot name="header">
            <div class="row">
              <label class="fw-bold" style="font-size: 20px">{{ detail.title }}</label>
            </div>
          </slot>
          <button type="button" class="btn-close" @click="close"></button>
        </header>

        <section class="modal-body">
          <slot name="body">
            <div v-for="(data,index) in content" :key="index">
              <div class="border border-dark shadow--">
                <div class="row">
                  <label class="col-2 col-form-label fw-bold label--topic--type--select">題型</label>
                  <div class="col-2 input-group input--topic--type--select">
                    <input type="text" readonly class="form-control readonly" :value="type[data.type]">
                  </div>
                  <label class="col-2 col-form-label fw-bold label--topic--type">分數</label>
                  <div class="col-2 input-group input--topic--type">
                    <input type="number" readonly class="form-control readonly" :value="data.point">
                  </div>
                  <label class="col-2 col-form-label fw-bold label--topic--type">顯示題數</label>
                  <div class="col-2 input-group input--topic--type">
                    <input type="number" readonly class="form-control readonly" :value="data.mixedAmount">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-2 col-form-label fw-bold label--topic">題目</label>
                  <div class="col-10 input-group input--topic">
                    <input type="text" readonly class="form-control readonly" :value="data.question">
                  </div>
                </div>
                <div v-for="(answerData,answerIndex) in data.answer" :key="answerIndex">
                  <div class="form-group row">
                    <label class="col-2 col-form-label fw-bold label--topic">隨機答案{{ answerIndex + 1 }}</label>
                    <div class="col-10 input-group input--topic">
                      <input type="text" readonly class="form-control readonly" :value="answerData">
                    </div>
                  </div>
                </div>
                <div v-if="data.type != 'filling'" class="form-group row">
                  <label class="col-sm-2 col-form-label fw-bold label--topic">錯誤選項</label>
                  <div class="col-sm-10 input-group input--topic">
                    <input type="text" readonly class="form-control readonly" :value="data.mixed">
                  </div>
                </div>


              </div>
            </div>
          </slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer">

          </slot>
          <button type="button" class="btn btn-outline-primary" @click="close">
            <i class="fas fa-check-circle"></i>
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          sort: "讀取中請稍後",
          title: "讀取中請稍後",
          describe: "讀取中請稍後",
        }
      }
    },
    content: {
      default: [],
    },
  },
  data() {
    return {
      type: {
        select: "選擇題",
        filling: "填充題",
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
  }
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  max-height: 90vh;
  overflow: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}


.modal-body {
  margin: 20px 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


label {
  text-align: left;
}

.col-3 {
  width: 17%;
}

.col-9 {
  width: 83%;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.input-group {
  padding: 0;
}

.label--topic--type--select {
  width: 17%;
}

.input--topic--type--select {
  width: 17%;
}

.label--topic--type {
  width: 13%;
}

.input--topic--type {
  width: 20%;
}

.label--topic {
  width: 17%;
}

.input--topic {
  width: 83%;
}

small.input--topic--type {
  padding: 0;
}

small.input--topic {
  padding: 0;
}

.input--topic > button {
  width: 8%;
}

.readonly {
  background: #ffffff;
}
</style>