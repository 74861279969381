<template>
  <div class="container">
    <div class="d-flex justify-content-start ms-3 my-2">
      <button class="btn btn-outline-dark" type="button" data-bs-toggle="collapse" data-bs-target="#searchBox" >
        <i class="fas fa-search"></i>
      </button>
    </div>

    <div class="collapse my-3" id="searchBox" >
      <div class="card border-dark  card-body container" v-if="nowSelectType==='program'">
        <div class="row my-1">
          <label  class="col-1 col-form-label fw-bold">分類</label>
          <div class="col-3">
            <input class="form-control" v-model="searchProgramCondition.sort">
          </div>
          <label  class="col-1 col-form-label fw-bold" >題目名稱</label>
          <div class="col-4">
            <input class="form-control" v-model="searchProgramCondition.title">
          </div>
          <label  class="col-1 col-form-label fw-bold">版本號</label>
          <div class="col-2">
            <input class="form-control"  v-model="searchProgramCondition.version">
          </div>
        </div>
        <div class="row my-1">
          <label class="col-1 col-form-label fw-bold">使用語言</label>
          <div class="col-3">
            <input class="form-control" v-model="searchProgramCondition.language">
          </div>
          <label class="col-1 col-form-label fw-bold">建立者</label>
          <div class="col-3">
            <input class="form-control" v-model="searchProgramCondition.creator">
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <button class="btn btn-primary col-2 mx-2 fw-bold fs-5" @click="search_program_button">
            <i class="fas fa-search mx-2"></i>搜尋
          </button>
          <button class="btn btn-warning col-2 mx-2 fw-bold fs-5" @click="clear_button">
            <i class="fas fa-backspace mx-2"></i> 清空
          </button>
        </div>
      </div>

      <div class="card border-dark  card-body container" v-else>
        <div class="row my-1">
          <label class="col-1 col-form-label fw-bold">分類</label>
          <div class="col-5">
            <input class="form-control" v-model="searchFormCondition.sort">
          </div>
          <label class="col-1 col-form-label fw-bold">題目名稱</label>
          <div class="col-5">
            <input class="form-control" v-model="searchFormCondition.title">
          </div>
        </div>
        <div class="row my-1">
          <label class="col-1 col-form-label fw-bold">題目數量</label>
          <div class="col-5">
            <input class="form-control" v-model="searchFormCondition.amount">
          </div>
          <label class="col-1 col-form-label fw-bold">建立者</label>
          <div class="col-5">
            <input class="form-control" v-model="searchFormCondition.creator">
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <button class="btn btn-primary col-2 mx-2 fw-bold fs-5" @click="search_form_button">
            <i class="fas fa-search mx-2"></i>搜尋
          </button>
          <button class="btn btn-warning col-2 mx-2 fw-bold fs-5" @click="clear_button">
            <i class="fas fa-backspace mx-2"></i> 清空
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "searchEngine",
  data(){
    const searchProgramCondition = this.searchProgramCriteria
    const searchFormCondition = this.searchFormCriteria
    return {
      searchProgramCondition,
      searchFormCondition
    }
  },
  props:{
    search_type: {type: String,default:"form"},
    nowSelectType:{
      type: String,
      default:"form"
    },
    searchProgramCriteria: {
      type:Object
    },
    searchFormCriteria: {
      type:Object
    }
  },
  watch:{
    searchProgramCondition:{
      handler:function (){
        this.$emit("update:searchProgramCriteria",this.searchProgramCondition)
      },
      deep:true,
    },
    searchFormCondition:{
      handler:function (){
        this.$emit("update:searchFormCriteria",this.searchFormCondition)
      },
      deep:true,
    }
  },
  methods:{
    search_program_button:function (){
      this.$emit("searchProgramBtn")
    },
    search_form_button:function (){
      this.$emit("searchFormBtn")
    },
    clear_button:function (){
      this.searchProgramCondition = {
        sort:"",
        title:"",
        version:"",
        language:"",
        creator:"",
      }
      this.searchFormCondition = {
        sort:"",
        title:"",
        amount:"",
        creator:"",
      }
    },
  }


}
</script>

<style scoped>

</style>