<template>
  <navbar/>
  <searchCriteria/>
</template>

<script>
import navbar from "../module/navbar"
import searchCriteria from "./accountSearchCriteria.vue"

export default {
  name: "index",
  components:{
    navbar,searchCriteria
  }
}
</script>

<style scoped>

</style>