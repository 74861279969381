<template>
  <div class="col-lg-3">
    <div class="card my-3 mx-3 p-2 border-2 course-box">

      <a href="/joinCourse">
        <div class="card-body">
          <h3 class="card-title">新增課程</h3>
          <br>
          <i class="fas fa-plus fa-6x"></i>
        </div>
      </a>
    </div>
  </div>

</template>

<script>
export default {
  name: "courseBoxAddNew",
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.course-box{
  width: 100%;
  height: 16rem;
  display: inline-block;
  padding: 10px;
}
.course-box:hover{
  background-color: rgba(217, 217, 217, 0.7);
}
a{
  text-decoration: none;
  color:inherit;
}
</style>