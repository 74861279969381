<template>
  <a class="btn btn-outline-primary btn-circle go-to-top" href="/createCourse"><i
      class="fas fa-plus"></i></a>

  <div class="search--result">
    <table class="table table-hover border border-secondary">
      <thead>
      <tr>
        <th class="table-navigation-index" scope="col">#</th>
        <th class="table-navigation-course-number" scope="col">課程編號</th>
        <th class="table-navigation-teacher" scope="col">教授</th>
        <th class="table-navigation-information" scope="col">課程資訊</th>
        <th class="table-navigation-lang" scope="col">開課語言</th>
        <th class="table-navigation-class" scope="col">班級</th>
        <th class="table-navigation-semester" scope="col">學年</th>
        <th class="table-navigation-term" scope="col">學期</th>
        <th class="table-navigation-operate" scope="col">操作</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(courseInformation,index) in courseInformationList" :key="index">
        <th class="table-navigation-index" scope="col">{{ index + 1 }}</th>
        <td class="table-navigation-teacher">{{ courseInformation.id }}</td>
        <td class="table-navigation-course-number">{{ courseInformation.course_teacher }}</td>
        <td class="table-navigation-information">
          <div class="border border-dark border-3 rounded text-left p-3 ">
            <h5 class="fw-bold my-0">{{ courseInformation.course_name }}</h5>
          </div>
          <div class="text-left mt-2">
            {{ courseInformation.course_school }}&nbsp;&nbsp;&nbsp;{{ courseInformation.course_dep }}
          </div>
        </td>
        <td class="table-navigation-lang">{{ courseInformation.course_lang }}</td>
        <td class="table-navigation-class">{{ courseInformation.course_class }}</td>
        <td class="table-navigation-semester">{{ courseInformation.course_semester }}</td>
        <td class="table-navigation-term">{{ courseInformation.course_term }}</td>
        <td class="table-navigation-operate">

          <a class="btn btn-outline-warning mx-1 button-width" :href="`/course/${courseInformation.id}/setting`"
             title="課程編輯"><i class="far fa-edit"></i>
          </a>
          <a class="btn btn-outline-success mx-1 button-width" :href="`/course/${courseInformation.id}/permission`"
             title="權限設定"><i class="fas fa-lock"></i>
          </a>
          <a class="btn btn-outline-danger mx-1 button-width disabled" title="刪除課程">
            <i class="far fa-trash-alt"></i>
          </a>

        </td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<script>
export default {
  name: "courseSearchResults",
  props: {
    courseInformationList: {}
  },
  data() {
    return {
      isModalVisible: false,
      editIndex: "",
    }
  },
}
</script>

<style scoped>
.search--result {
  width: 90%;
  margin: 20px auto;
}

.table th, .table td {
  text-align: center;
  vertical-align: middle !important;
}

.table-navigation-index {
  width: 3%;
}

.table-navigation-course-number {
  width: 3%;
}

.table-navigation-information {
  width: 43%;
}

.table-navigation-teacher {
  width: 8%;
}

.table-navigation-lang {
  width: 8%;
}

.table-navigation-class {
  width: 5%;
}

.table-navigation-semester {
  width: 5%;
}

.table-navigation-term {
  width: 5%;
}

.table-navigation-operate {
  width: 20%;
}


button {
  margin: 0 5px;
}

.text-left {
  text-align: left;
}

.te {
  float: right
}



.button-width {
width: 40px;
}

.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
}

.go-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}

</style>