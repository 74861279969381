<template>
  <navbar></navbar>
  <per-manage-panel></per-manage-panel>
</template>

<script>
import navbar from "../../../module/navbar";
import PerManagePanel from "./perManagePanel.vue";
export default {
  name: "index",
  components:{
    PerManagePanel,
    navbar
  }
}
</script>

<style scoped>

</style>