<template>
  <div v-if="uploadShow" class="form--div">
    <div class="row my-0">
      <label class="px-0 col-auto"
             style="font-size: 20px"><span class="fw-bold">課程：</span>({{ title.course.dep }})&nbsp;{{
          title.course.name
        }}
      </label>
    </div>
    <div class="row my-0">
      <label class="px-0 col-auto" style="font-size: 20px"><span class="fw-bold">考試：</span>{{ title.quiz }}</label>
    </div>
    <div class="row my-0 mb-2">
      <label class="px-0 col-auto" style="font-size: 20px">
        <span class="fw-bold">學生：</span>{{ name }}&nbsp;&nbsp;
        <span class="fw-bold">學號：</span>{{ std_id }}
      </label>
    </div>
    <a ref="showExam" class="d-none" target="_blank"></a>

    <table class="table table-hover border border-secondary table-bordered">
      <thead class="bg-dark bg-gradient text-light">
      <tr>
        <th class="index--" scope="col">#</th>
        <th class="type--" scope="col">題目類型</th>
        <th class="name--" scope="col">考試名稱</th>
        <th class="score--" scope="col">分數</th>
        <th class="state--" scope="col">狀態</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(data,index) in product" :key="data" @click="showExam(index)">
        <th class="index--">{{ index + 1 }}</th>
        <td class="type--">
          <span v-if="data.examType =='program'"><i class="fas fa-code fa-fw"></i></span>
          <span v-if="data.examType =='form'"><i class="fas fa-list fa-fw"></i></span>
          {{ (data.examType == 'program') ? 'code' : (data.examType == 'form') ? 'form' : '' }}
        </td>
        <td class="name--">{{ data.examName }}</td>
        <td class="score-- fw-bold">
          <span :class="(data.examScore==0)?'text-danger':'text-success'">{{ data.examScore }}</span>&nbsp;/
          <span class="text-success">{{ data.examTotalScore }}</span>
        </td>
        <td :class="['state-- fw-bold',(data.examStatus =='Success')?'text-success':'',
        (data.examStatus =='Warning')?'text-warning':'',(data.examStatus =='None')?'text-danger':'']">
          {{
            (data.examStatus == 'Success') ? '已上傳' : (data.examStatus == 'Warning') ? '編輯中' : (data.examStatus == 'None') ? '未上傳' : ''
          }}
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadShow = true"></alert-modal>
</template>

<script>
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "showQuiz",
  components: {
    AlertModal,
  },
  data() {
    this.getQuiz()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      alertModal,
      title: {},
      name: '',
      std_id: '',
      product: [],
      uploadShow: false,
    }
  },
  methods: {
    getQuiz: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.uuid}/getQuiz`).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
          this.title = res.data.title
          this.name = res.data.name
          this.std_id = res.data.std_id
          this.product = res.data.product
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            confirmEvent: function () {
              location.href=`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/showQuiz`
            }
          }
        }
      })
    },
    showExam(index) {
      if (this.product[index].examType == 'form')
        this.$refs.showExam.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/form/${this.product[index].examLink}/student/${this.$route.params.uuid}`
      if (this.product[index].examType == 'program')
        this.$refs.showExam.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/program/${this.product[index].examLink}/student/${this.$route.params.uuid}/submitRecord`
      this.$refs.showExam.click()
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.index-- {
  width: 5%;
}

.type-- {
  width: 15%;
}

.name-- {
  width: 40%;
}

td.name-- {
  cursor: pointer;
}

.score-- {
  width: 20%;
}

.state-- {
  width: 20%;
}

</style>