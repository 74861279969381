import test from "../../components/timeIpManage/index.vue";
import test2 from "../../components/test/lyt Test2.vue";
import test3 from "../../components/test/lyt Test.vue";
import test4 from "../../components/test/index.vue";

export default [
    // 測試頁面1
    {
        path: '/test1',
        name: 'test Page',
        component: test,
    },
    // 測試頁面2
    {
        path: '/test2',
        name: 'test2 Page',
        component: test2,
    },
    // 測試頁面3
    {
        path: '/test3',
        name: 'test3 Page',
        component: test3,
    },
    // 測試頁面4
    {
        path: '/test4',
        name: 'test4 Page',
        component: test4,
    },
]