<template>
  <registerNavbar></registerNavbar>
  <div class="container">
    <h2 class="title">忘記帳號密碼</h2>
    <div class="nav-selection text-center">
      <nav>
        <div class="nav nav-tabs " id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-password-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-forgetPassword" type="button" role="tab" aria-controls="nav-forgetPassword"
                  aria-selected="true">忘記密碼
          </button>
          <button class="nav-link" id="nav-account-tab" data-bs-toggle="tab" data-bs-target="#nav-forgetAccount"
                  type="button" role="tab" aria-controls="nav-forgetPassword" aria-selected="false">忘記帳號
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-forgetPassword" role="tabpanel"
             aria-labelledby="nav-password-tab">
          <div class="card">
            <form method="post">
              <p><input type="hidden" name="forget-type" value="password"></p>
              <div class="row mb-4">
                <label class="col-3 col-form-label">電子郵件</label>
                <div class="col-9">
                  <input type="email" class="form-control " v-model="mail" name="recover-text" placeholder="E-Mail">
                </div>
              </div>
              <button type="button" class="btn btn-primary me-2" @click="forgetPassword">找回密碼</button>
              <button type="button" @click="goToLogin" class="btn btn-primary me-2">返回登入</button>
            </form>
          </div>
        </div>
        <div class="tab-pane fade" id="nav-forgetAccount" role="tabpanel" aria-labelledby="nav-account-tab">
          <div class="card">

            <form method="post">
              <p><input type="hidden" name="forget-type" value="account"></p>
              <div class="row mb-4">
                <label class="col-3 col-form-label">電子郵件</label>
                <div class="col-9">
                  <input type="email" class="form-control " name="recover-text" placeholder="E-Mail">
                </div>
              </div>
              <button type="submit" class="btn btn-primary me-2">找回帳號</button>
              <button type="button" @click="goToLogin" class="btn btn-primary me-2">返回登入</button>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import axios from "axios";
import AlertModal from "../module/Modal/alertModal.vue";
import registerNavbar from "../module/navbar/Register-navbar.vue";
export default {
  name: "ForgetPassword",
  components: {AlertModal,registerNavbar},
  data() {
    const alertModal = {
      show :false
    }
    return {
      mail: "",
      alertModal
    }
  },
  methods: {
    goToLogin: function () {
      location.href = "/login";
    },
    forgetPassword() {
      axios.post("/api/forgetPasswordMail", {mail: this.mail}).then((result)=>{
        if(result.data.success){
          this.alertModal = {
            show:true,
            title:"送信完成",
            crossShow:false,
            bodyMsg:"已成功提交發送認證信要求，若您的帳號存在於系統中，則會發送重製密碼函至您的信箱!",
            confirmEvent:function (){
              this.$router.push("/login")
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
form {
  padding: 20px;
}

.nav-selection {
  align-items: center;
  margin: auto;
  width: 500px;
}

.card {
  border-top: 0px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.title {
  font-weight: 600;
}
</style>