<template>
  <div class="container-fluid mt-2">
    <div class="row d-flex justify-content-center">
      <div class="card" style="width: 50vw">
        <form class="container-fluid" name="courseInfo">
          <div class="row mt-3">
            <h4 class="text-start">課程資訊</h4>
          </div>
          <hr class="row">
          <div class="row mt-2">
            <label class="col-form-label col-sm-3">課程名稱</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="infoFormData.title">
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-form-label col-sm-3">課程工具</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="infoFormData.tool">
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-form-label col-sm-3">課程介紹</label>
            <div class="col-sm-8">
              <textarea class="form-control" style="resize: none " v-model="infoFormData.describe"></textarea>
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-form-label col-sm-3">指導教授</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="infoFormData.teacher">
            </div>
          </div>
          <div class="row mt-2">
            <label class="col-form-label col-sm-3">開課班級</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="infoFormData.classes">
            </div>
          </div>
          <div class="row mt-2 mb-3">
            <label class="col-form-label col-sm-3">開課學期</label>
            <div class="col-sm-4">
              <div class="input-group">
                <span class="input-group-text">學年</span>
                <input type="text" class="form-control" v-model="infoFormData.semester">
              </div>
            </div>
            <div class="col-sm-4">
              <div class="input-group">
                <span class="input-group-text">學期</span>
                <input type="text" class="form-control" v-model="infoFormData.term">
              </div>
            </div>
          </div>
          <div class="row justify-content-center mb-3">
            <button class="col-sm-3 btn btn-outline-primary mx-2" type="button" @click="saveInfoFormData">儲存<i
                class="fas fa-save mx-2"></i>
            </button>
            <button class="col-sm-3 btn btn-outline-warning mx-2" type="button" @click="clearInfoForm">取消<i
                class="fas fa-backspace mx-2"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <modal v-if="alertModal.show" @close="alertModal.show = false">
    <template v-slot:header>
      <h5>
        <span v-if="alertModal.state === 'normal'" class="text-primary"><i class="fas fa-info-circle fa-1x"></i></span>
        <span v-if="alertModal.state === 'warning'" class="text-warning"><i
            class="fas fa-times-circle fa-1x"></i></span>
        <span>{{ alertModal.alertTitle }}</span>
      </h5>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <span>{{ alertModal.alertInfo }}</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex">
        <button class="btn btn-primary mx-2" @click="alertModal.show = false">確認</button>
      </div>
    </template>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "../../../module/Modal/index.vue";

export default {
  name: "coursePanel",
  components: {
    Modal
  },
  emits: ["loadCoursePanel"],
  data() {
    this.fetchPageData()
    const pageData = {
      title: "",
      tool: "",
      describe: "",
      classes: "",
      teacher: "",
      semester: "",
      term: "",
      what: ""
    }
    const infoFormData = {
      title: "",
      tool: "",
      describe: "",
      classes: "",
      teacher: "",
      semester: "",
      term: ""
    }
    const alertModal = {
      state: "normal",
      show: false,
      alertTitle: "",
      alertInfo: "",
    }
    return {
      pageData,
      infoFormData,
      alertModal
    }
  },
  methods: {
    fetchPageData: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/detail`).then(response => {
        if (response.data.success) {
          this.pageData = Object.assign({}, response.data.detail)
          this.infoFormData = Object.assign({}, response.data.detail)
          this.$emit("loadCoursePanel", "loadCourseShow")
        }
      })
    },
    saveInfoFormData: async function () {
      let request = {
        title: this.infoFormData.title,
        lang: this.infoFormData.tool,
        describe: this.infoFormData.describe,
        teacher: this.infoFormData.teacher,
        semester: this.infoFormData.semester,
        term: this.infoFormData.term,
      }
      await axios.post(`/api/course/${this.$route.params.courseId}/detail/set`, request).then(response => {
        if (response.data.success) {
          this.alertModal.state = "normal"
          this.alertModal.alertTitle = "修改提示"
          this.alertModal.alertInfo = "修改成功!"
          this.alertModal.show = true
          this.fetchPageData()
        } else {
          this.alertModal.state = "warning"
          this.alertModal.alertTitle = "修改提示"
          this.alertModal.alertInfo = "修改失敗!"
          this.alertModal.show = true
        }
      })
    },
    clearInfoForm: function () {
      this.infoFormData = Object.assign({}, this.pageData)
    }
  }
}
</script>

<style scoped>

</style>