<template>

  <div class="card my-1">
    <div class="card-header pt-1"
         :class="{'process':examStatus === 'process','ready':examStatus === 'ready','invalid':examStatus === 'invalid'}">
      <div class="d-flex">
        <a :href="`${$route.params.courseId}/exam/${examHref}`" class="p-1 me-auto" v-if="examStatus === 'process'">
          <div>
            <span class="d-flex">
              <strong>
                [{{ (type == 'practice') ? '練習' : (type == 'exam') ? '考試' : '' }}] {{examName }}
              </strong>
            </span>
          </div>
        </a>
        <div v-else class="p-1 me-auto">
          <a :href="`${$route.params.courseId}/exam/${examHref}`" v-if="coursePerm && coursePerm.examManage"><strong> {{examName }} </strong></a>
          <span v-else class="d-flex"><strong>  {{ examName }} </strong></span>
        </div>
        <div class="p-1">
          <a class="text-black pure-link text-start" data-bs-toggle="collapse" :href="'#exam-collapse' + examIndex"
             role="button">
            <strong>考試資訊 <i class="fas fa-angle-down"></i></strong>
          </a>
        </div>

      </div>


    </div>
    <div :id="'exam-collapse'+examIndex" class="collapse">
      <div class="card-body container-fluid">
        <div class="row">
          <p class="col text-start">{{ examDetail }}</p>

        </div>
        <div class="row">
          <p class="col text-start"><strong>開放時間:</strong> {{ startTimeStr }} ~ {{ endTimeStr }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import auth from "@/store/auth"

export default {
  name: "examEntry",
  props: {
    examName: {
      default: "undefined"
    },
    examIndex: {
      default: "collapse1"
    },
    examHref: {
      default: "1"
    },
    examDetail: {},
    examStartTime: {},
    examEndTime: {},
    examStatus: {
      default: "process"
    },
    type: {}
  },
  data(){
    const coursePerm = {}
    this.getCoursePerm()
    return{
      coursePerm
    }
  },
  methods: {
    getCoursePerm : async function(){
      this.coursePerm = await auth.tryGetCourseManage(this.$route.params.courseId)
    }
  },
  computed: {
    startTimeStr: function () {
      if (this.examStartTime === null) {
        return "未設置"
      } else {
        let time = dayjs(new Date(this.examStartTime))
        return time.format('YYYY/MM/DD A hh:mm:ss')
      }
    },
    endTimeStr: function () {
      if (this.examEndTime === null) {
        return "未設置"
      } else {
        let time = dayjs(new Date(this.examEndTime))
        return time.format('YYYY/MM/DD A hh:mm:ss')
      }
    }
  }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: inherit;
}

.process {
    background: rgba(118, 237, 246, 0.3)
}

.ready {
    background: rgba(230, 245, 98, 0.3)
}

.invalid {
    background: rgba(0, 0, 0, 0.2)
}
</style>