<template>
  <div v-if="uploadButtonShow && !this.check" class="form--div">
    <div class="row">
      <label class="fw-bold px-0" style="font-size: 20px">{{ this.produce.title }}</label>
    </div>

    <div v-for="(data,index) in produce.content" :key="index">
      <div class="border border-dark shadow--">
        <dd class="mb-0">
          <dl class="row my-0">
            <dt class="col-auto px-0" style="font-size: 18px;">{{ index + 1 }}.</dt>
            <dd class="col" style="font-size: 18px;text-align:justify;text-justify:inter-word;">{{ data.heading }}</dd>
          </dl>
        </dd>
        <div>
          <label class="col-6 col-form-label text-success">此題分數({{ data.point }}分)</label>
        </div>


        <div class="form-check" v-for="(dataAnswer,indexAnswer) in data.answer" :key="indexAnswer"
             :style="[(data.type == 'filling')?'padding:0;':'']">

          <div v-if="data.type == 'oneSelect'" class="row my-1">
            <input class="form-check-input col-auto px-0" type="radio" :name="'question'+index"
                   @change="changeChoose(index,indexAnswer)">
            <label class="form-check-label col-auto px-1">{{ ascii(indexAnswer) }}.</label>
            <label class="form-check-label col ps-0" style="text-align:justify;text-justify:inter-word;">{{ dataAnswer.content }}</label>
          </div>

          <div v-if="data.type == 'moreSelect'" class="row my-1">
            <input class="form-check-input col-auto px-0" type="checkbox"
                   v-model="produce.content[index].answer[indexAnswer].choose"
                   :true-value="true" :false-value="false" :name="'question'+index">
            <label class="form-check-label col-auto px-1">{{ ascii(indexAnswer) }}.</label>
            <label class="form-check-label col ps-0">{{ dataAnswer.content }}</label>
          </div>

          <div v-if="data.type == 'filling'" class="form-group row">
            <label class="col-form-label fw-bold col-auto px-0">Ans:&nbsp;</label>
            <div class="col">
              <textarea class="form-control" rows="1" @input="getTextareaHeight($event,index,indexAnswer)"></textarea>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div v-if="uploadButtonShow && !this.check" style="text-align: center;">
      <button type="button" class="btn btn-outline-primary m-2" @click="uploadTestPaper">
        上傳&nbsp;<i class="fas fa-check-circle"></i></button>
    </div>

  </div>
  <div v-if="uploadButtonShow && this.check && this.checkProduct.state =='success'" class="form--div">
    <div class="row my-0">
      <label class="fw-bold px-0" style="font-size: 24px">{{this.checkProduct.name}}的試卷：{{ this.checkProduct.title }}</label>
    </div>
    <div>
      <label class="col-form-label fw-bold text-success py-0 mb-2" style="font-size: 20px">總分：{{ this.checkProduct.score }}</label>
    </div>

    <div v-for="(data,index) in checkProduct.answer.content" :key="index">
      <div class="border border-dark shadow--">
        <dd class="mb-0">
          <dl class="row my-0">
            <dt class="col-auto px-0" style="font-size: 18px;">{{ index + 1 }}.</dt>
            <dd class="col" style="font-size: 18px;text-align:justify;text-justify:inter-word;">{{ data.heading }}</dd>
          </dl>
        </dd>
        <div>
          <label class="col-auto col-form-label fw-bold">此題得分：
            <span :class="[(data.score!=0)?'text-success':'text-danger']">{{ data.score }}分
            </span>&nbsp;(上限{{data.point}}分)
          </label>
        </div>

        <hr/>

        <div class="row my-0">
          <div class="col-6 px-0">
            <div class="form-check px-0" v-for="(dataAnswer,indexAnswer) in data.answer" :key="indexAnswer"
                 :style="[(data.type == 'filling')?'padding:0;':'']">

              <div v-if="data.type != 'filling'" :class="['row my-1',(dataAnswer.choose)?'text-success':'text-danger']">
                <label v-if="dataAnswer.choose" class="col-auto px-1"><i class="fas fa-check-circle"></i></label>
                <label v-if="!dataAnswer.choose" class="col-auto px-1"><i class="fas fa-times-circle"></i></label>
                <label class="col-auto px-1">{{ ascii(indexAnswer) }}.</label>
                <label class="col ps-0" style="text-align:justify;text-justify:inter-word;">{{
                    dataAnswer.content
                  }}</label>
              </div>

              <div v-if="data.type == 'filling'" class="form-group row my-0">
                <label class="col-form-label fw-bold col-auto px-0">Ans:&nbsp;</label>
                <div class="col px-0">
                  <label class="col-form-label col-auto"><pre class="my-0">{{ dataAnswer.content }}</pre></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="uploadButtonShow && this.check && this.checkProduct.state =='none'" class="form--div">
    <div class="row my-0">
      <label class="fw-bold px-0" style="font-size: 24px">{{this.checkProduct.name}}的試卷：{{ this.checkProduct.title }}</label>
    </div>
    <div>
      <label class="col-form-label fw-bold py-0 mb-2" style="font-size: 24px">{{this.checkProduct.name}}尚未上傳試卷</label>
    </div>
    <div>
      <label class="col-form-label fw-bold py-0 mb-2" style="font-size: 20px">總分：
        <span class="text-danger">{{ this.checkProduct.score }}</span>
      </label>
    </div>
  </div>
  <div v-if="!uploadButtonShow" class="border border-dark shadow-- form--div" style="text-align: center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="uploadButtonShow = true"></alert-modal>
</template>

<script>
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "getUserPaper",
  components: {
    AlertModal
  },
  props:{
    check:{type: Boolean},
  },
  data() {
    this.getPaper()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      alertModal,
      produce: {},
      checkProduct:{},
      uploadButtonShow: false,
    }
  },
  methods: {
    getPaper: async function () {
      if(this.check){
        await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/${this.$route.params.uuid}/check/userPaper`).then((res) => {
          if (res.data.success) {
            this.alertModal = {
              show: false,
            }
            this.uploadButtonShow = true
            this.checkProduct = res.data.product
          } else {
            this.alertModal = {
              show: true,
              state: "warning",
              title: "讀取失敗",
              crossShow: false,
              bodyMsg: "請重新嘗試",
              confirmEvent: function () {
                window.close()
              }
            }
          }
        })
      }
      else{
        await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/userPaper`).then((res) => {
          if (res.data.success) {
            this.alertModal = {
              show: false,
            }
            this.uploadButtonShow = true
            this.produce = res.data.produce
            //for(let i=0;i<this.produce.content.length;i++)
          } else {
            this.alertModal = {
              show: true,
              state: "warning",
              title: "讀取失敗",
              crossShow: false,
              bodyMsg: "請重新嘗試",
              confirmEvent: function () {
                window.close()
              }
            }
          }
        })
      }
    },
    ascii: function (indexAnswer) {
      return String.fromCharCode(65 + indexAnswer)
    },
    getTextareaHeight: function (event, index, indexAnswer) {
      event.target.rows = Math.floor((event.target.scrollHeight - 12) / 24)
      this.produce.content[index].answer[indexAnswer].content = event.target.value
    },
    changeChoose(index, indexAnswer) {
      for (let i = 0; i < this.produce.content[index].answer.length; i++)
        this.produce.content[index].answer[i].choose = (i == indexAnswer) ? true : false
    },
    uploadTestPaper: async function () {
      this.alertModal = {
        show: true,
        state: "loading",
        title: "上傳中",
        crossShow: false,
        confirmShow: false,
      }
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/uploadPaper`, {
        produce: this.produce,
      }).then((res) => {
        if (res.data.success) {
          this.alertModal = {
            show: true,
            state: "normal",
            title: "上傳完成",
            bodyMsg: `試卷上傳成功，分數為${res.data.score}，考試結束`,
            confirmEvent: function () {
              this.$router.push(`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}`)
            }
          }
        } else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "上傳失敗",
            bodyMsg: "請重新嘗試",
          }
        }
      })
    }
  },
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}
</style>