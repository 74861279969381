<template>
  <div class="card mt-3 border-dark">


    <table class="table table-striped">
      <thead>
      <tr>
        <th>題目類型</th>
        <th>題目名稱</th>
        <th>題目狀態</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(data,index) in tableData" :key="index">
        <td v-if="data.examType==='program'">
          <i class="fas fa-code fa-1x"></i>
        </td>
        <td v-if="data.examType==='form'">
          <i class="fas fa-list fa-1x"></i>
        </td>
        <td>
          <a v-if="data.examType==='program'" :href="`${$route.params.examId}/program/${data.examLink}`" class="onSelect">
            {{ data.examName }}
          </a>
          <a v-if="data.examType==='form'" :href="`${$route.params.examId}/form/${data.examLink}`" class="onSelect">
            {{ data.examName }}
          </a>
        </td>
        <td class="fw-bold" :class="{'text-danger':data.examStatus === 'Danger','text-warning':data.examStatus === 'Warning','text-success':data.examStatus === 'Success'}">
          {{ (data.examStatus === 'Danger')?'未作答':(data.examStatus === 'Warning')?'有錯誤':(data.examStatus === 'Success')?'完成':'' }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "examListVer2",
  data()
  {
    return {
      // tableData: [
      //
      // ]
    }
  },
  props:["tableData"]
}
</script>

<style scoped>
.onSelect {
  text-decoration: none;
  color: inherit;
}

.onSelect:hover {
  color: blue;
}

.text-warning{
  color: #fd7e14 !important;
}
</style>