<template>
  <navbar></navbar>
  <quiz-manage-panel></quiz-manage-panel>
</template>

<script>
import QuizManagePanel from "./quizManagePanel.vue";
import navbar from "../../../module/navbar";
export default {
  name: "index",
  components:{navbar, QuizManagePanel}
}
</script>

<style scoped>

</style>