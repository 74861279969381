<template>
  <div class="register-body container ">
    <form method="post" @submit.prevent="submitCreateCourse">
      <div class="row">
        <h1 class="mb-3 fw-bolder">開課</h1>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">課程名稱</label>
        <div class=" col-sm-9">
          <input type="text" id="courseName" v-model="courseName" class="form-control" placeholder="請輸入開課課程的名稱"
                 required>
        </div>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">課程說明</label>
        <div class=" col-sm-9">
          <textarea rows="3" id="courseIllustrate" v-model="courseIllustrate" class="form-control" placeholder="課程須知"
                    required></textarea>
        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">開課工具</label>
        <div class=" col-sm-9">
          <input type="text" v-model="courseInstrument" id="courseInstrument" class="form-control" placeholder="開課使用語言"
                 required>
        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">開課學校</label>
        <div class=" col-sm-9 text-start">
          <select required class="form-select" id="school" @change="updateDepart" @click="updateDepart"
                  v-model="courseSchool">
            <option value="" selected disabled>學校</option>
            <option v-for="(data,index) in school_List" :key="index" :value="data">{{ data }}</option>
          </select>

        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">開課系所</label>
        <div class=" col-sm-9 text-start">
          <select required id="department" class="form-select" v-model="courseDepartment">
            <option value="" selected disabled>科系</option>
            <option v-for="(data,index) in department_List" :key="index" :value="data">{{ data }}</option>
          </select>
        </div>
      </div>

      <div class="row data-brick mb-sm-1">
        <label class="col-sm-3 col-form-label fw-bold">開課班級</label>
        <div class=" col-sm-9">
          <input type="text" v-model="courseClass" id="courseClass" class="form-control" placeholder="班級" required>
        </div>
      </div>

      <div class="row data-brick mb-sm-2">
        <label class="col-sm-3 col-form-label fw-bold">開課學期</label>
        <div class=" col-sm-9">
          <input type="text" v-model="courseTerm" id="courseTerm" class="form-control" placeholder="學期"
                 pattern="^[0-9]{4}"
                 required>
        </div>
      </div>



      <div>
        <button type="submit" class="btn btn-primary me-3">開課</button>
        <button type="reset" class="btn btn-warning me-3">清除</button>

      </div>

    </form>

    <modal v-model="modalArgs.show" :="modalArgs" ></modal>

  </div>
</template>

<script>
import axios from "axios";
import modal from "../module/Modal/alertModal.vue";
import auth from "@/store/auth";

export default {
  name: "joinCourse",
  data() {
    this.updateSchool()
    const modalArgs = {
      show: false
    }
    return {
      school_List: [],
      department_List: [],
      courseName: "",
      courseIllustrate: "",
      courseInstrument: "",
      courseClass: "",
      courseTerm: "",
      courseSchool: "",
      courseDepartment: "",
      modalArgs
    }
  },
  methods: {
    updateSchool: function () {
      axios.post('/api/info/list/school').then((res) => {

        let listTmp = []
        if(res.data.success){
          res.data.school_list.forEach((school) => {
            listTmp.push(school)
          })
        }
        this.school_List = listTmp
      })
    },
    updateDepart: function () {
      let school = this.courseSchool
      if(!school || school  == "")
        return
      axios.post(`/api/info/list/school/${school}`).then((res) => {
        let listTmp = []
        if(res.data.success){
          res.data.dep.forEach((dep) => {
            listTmp.push(dep)
          })
        }
        this.department_List = listTmp
      })
    },
    submitCreateCourse: function () {
      axios.post("/api/system/createCourse", {
        courseName: this.courseName,
        courseIllustrate: this.courseIllustrate,
        courseInstrument: this.courseInstrument,
        courseClass: this.courseClass,
        courseTerm: this.courseTerm,
        courseSchool: this.courseSchool,
        courseDepartment: this.courseDepartment,
      }).then((res) => {
        auth.fetchAllCourse()
        if(res.data.success){
          this.modalArgs = {
            show: true,
            crossShow:false,
            state: "normal",
            title: "新增課程成功",
            bodyMsg: "此課程已新增成功，即將將網頁導向課程頁面",
            confirmEvent: function (){
              this.$router.push(`/course/${res.data.course}`)
            }
          }

        }
        else{
          this.modalArgs = {
            show: true,
            crossShow:false,
            state: "warning",
            title: "新增課程失敗",
            bodyMsg: "此課程新增失敗，請重新確認資料或洽尋管理員"
          }
        }
      })
    }
  },
  components: {
    modal,
  },
}
</script>

<style scoped>
form {
  text-align: center;
  margin: auto;
  padding: 20px;
}

.register-body {
  text-align: center;
  margin: auto;
}

.data-brick {
  text-align: center;
  width: 580px;
  margin: auto;
}

label {
  text-align: left;
}

ul > .dropdown-menu {
  width: 300px;
}
</style>