<template>
  <div v-show="loadSeatColor">
    <navbar :showGoToTop="true" :seatBoolean="seatBoolean" :seatNumber="seatNumber"/>
  </div>
  <getUserPaper v-if="mode == 'student'" :check="check"/>
  <getTeacherPaper v-if="mode=='teacher'"/>
  <reviewPaper v-if="mode=='review'"/>
  <showStudent v-if="mode=='show'"/>
</template>

<script>
import navbar from "../module/navbar"
import getUserPaper from "./getUserPaper.vue";
import getTeacherPaper from "./getTeacherPaper.vue";
import reviewPaper from "./teacher/reviewPaper.vue"
import showStudent from "./teacher/showStudent.vue"
import axios from "axios";

export default {
  components: {
    navbar, getUserPaper, getTeacherPaper, reviewPaper, showStudent
  },
  props: {
    mode: {type: String},
    check: {type: Boolean, default: false},
  },
  name: "index",
  data() {
    this.getColor()
    return {
      loadSeatColor: false,
      seatBoolean: false,
      seatNumber: 0,
    }
  },
  methods: {
    getColor: async function () {
      await axios.post(`/api/course/${this.$route.params.examId}/color`).then((res) => {
        if (res.data.success && this.mode == 'student' && this.check == false) {
          this.seatBoolean = res.data.seatBoolean
          this.seatNumber = res.data.seatNumber
        }
      })
      this.loadSeatColor = true
    }
  }
}
</script>

<style scoped>

</style>