<template>
  <navbar/>
  <div>exam</div>
</template>

<script>
import navbar from "../../module/navbar"

export default {
  name: "index",
  components: {
    navbar,
  }
}
</script>

<style scoped>

</style>