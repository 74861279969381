<template>
  <div v-if="uploadShow">
    <div class="container mt-4">
      <div class="row">
        <div class="col d-flex justify-content-start">
          <h3><strong>權限管理</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-end">
          <button class="btn btn-outline-primary mx-2 py-1" @click="addModal.show = true"><i
              class="fas fa-plus-circle"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-striped">
            <thead>
            <tr>
              <th style="width: 5%">#</th>
              <th style="width: 10%">學號</th>
              <th style="width: 30%">電子郵件</th>
              <th style="width: 20%">姓名</th>
              <th style="width: 10%">權限</th>
              <th style="width: 5%">移除</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data , index) in listPermUser" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ data.std_id }}</td>
              <td>{{ data.email }}</td>
              <td>{{ data.name }}</td>
              <td class="container-fluid">
                <div class="row">
                  <div class=" col-lg-6 form-check form-switch">
                    <label class="form-check-label">課程設定</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.courseSetting"
                           @change="updateUserPerm(index)">
                  </div>
                  <div class="col-lg-6 form-check form-switch">
                    <label class="form-check-label">權限管理</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.classPermCtrl"
                           @change="updateUserPerm(index)">
                  </div>
                  <div class="col-lg-6 form-check form-switch">
                    <label class="form-check-label">考試管理</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.examManage"
                           @change="updateUserPerm(index)">
                  </div>
                  <div class="col-lg-6 form-check form-switch">
                    <label class="form-check-label">建立考題</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.quizCreate"
                           @change="updateUserPerm(index)">
                  </div>
                  <div class="col-lg-6 form-check form-switch">
                    <label class="form-check-label">題庫管理</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.topicManage"
                           @change="updateUserPerm(index)">
                  </div>
                  <div class="col-lg-6 form-check form-switch">
                    <label class="form-check-label">外部題庫導入</label>
                    <input type="checkbox" class="form-check-input" v-model="data.perm.outTopicManage"
                           @change="updateUserPerm(index)">
                  </div>

                </div>
              </td>
              <td>
                <button class="btn py-0 px-2 btn-outline-danger" @click="callRemoveModal(index)"><i
                    class="fas fa-trash"></i></button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal v-if="addModal.show" @close="addModal.show = false">
      <template #header>
        <h4><strong>選擇學生</strong></h4>
      </template>
      <template #body>
        <div style="width: 70vw;height: 70vh;overflow-y: auto">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">學號</th>
              <th scope="col">電子郵件</th>
              <th scope="col">名稱</th>
              <th scope="col">校系</th>
              <th scope="col">加入</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,index) in listStudent" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td> {{ data.studentId }}</td>
              <td> {{ data.email }}</td>
              <td> {{ data.name }}</td>
              <td> {{ data.school }} - {{ data.department }}</td>
              <td>
                <button class="btn btn-outline-primary p-0 px-1" @click="addToPerm(data.stdCourseId)">
                  <i class="fas fa-plus-square"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </template>
    </modal>

    <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
    <alert-modal v-model="alertArgs2.show" :="alertArgs2" @removeUser="removeUserPerm"></alert-modal>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
  <toast :="toast_data" ref="toast"></toast>
</template>

<script>
import Modal from "../../../module/Modal/index.vue";
import axios from "axios";
import AlertModal from "../../../module/Modal/alertModal.vue";
import Toast from "@/components/module/toast.vue";

export default {
  name: "perManagePanel",
  components: {Toast, AlertModal, Modal},

  data() {
    const toast_data = {
      timeout:1000,
    }
    const alertArgs = {
      show: false,
      title: "",
      bodyMsg: "",
    }
    const addModal = {
      show: false,
    }
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    this.fetchAllStudent()
    this.fetchAllPermUser()
    return {
      toast_data,
      alertModal,
      uploadShow: false,
      listStudent: [],
      listPermUser: [],
      alertArgs,
      alertArgs2: {show: false},
      addModal
    }
  },
  methods: {
    fetchAllStudent: function () {
      axios.post(`/api/course/${this.$route.params.courseId}/stdList`).then(result => {
        if (result.data.success) {
          this.listStudent = []
          result.data.student.map((mapItem) => {
            this.listStudent.push({
              index: mapItem.id,
              uuid: mapItem.uuid,
              name: mapItem.name,
              studentId: mapItem.stdId,
              email: mapItem.email,
              school: mapItem.school,
              department: mapItem.department,
              stdCourseId: mapItem.stdCourseId
            })
          })
        }
      })
    },
    fetchAllPermUser: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/permManage/list`).then(result => {
        if (result.data.success) {
          this.listPermUser = []
          result.data.listUser.map((mapItem) => {
            this.listPermUser.push({
              index: mapItem.index,
              name: mapItem.name,
              email: mapItem.email,
              std_id: mapItem.std_id,
              perm: mapItem.perm
            })
          })
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
        }
        else{
          this.alertModal = {
            show: true,
            state: "danger",
            title: "讀取失敗",
            bodyMsg: "此頁面讀取失敗，請重新嘗試",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}`
            }
          }
        }
      })

    },
    addToPerm: async function (stdCourseId) {
      this.addModal.show = false
      let request = {
        stdCourseId: stdCourseId
      }
      await axios.post(`/api/course/${this.$route.params.courseId}/permManage/add`, request).then((response) => {
        if (response.data.success) {
          this.alertArgs = {
            show: true,
            state: "normal",
            title: '新增成員',
            bodyMsg: '新增權限控管成員成功!!',
            crossShow:false
          }
        } else {
          this.alertArgs = {
            show: true,
            state: "danger",
            title: '新增失敗',
            bodyMsg: '新增權限控管成員失敗，請重新嘗試!!',
            crossShow:false
          }
        }
      })
      this.fetchAllPermUser()
    },
    updateUserPerm: async function (permListIndex) {
      let request = {
        permCtrlId: this.listPermUser[permListIndex].index,
        auth: {
          courseSetting: this.listPermUser[permListIndex].perm.courseSetting,
          classPermCtrl: this.listPermUser[permListIndex].perm.classPermCtrl,
          examManage: this.listPermUser[permListIndex].perm.examManage,
          quizCreate: this.listPermUser[permListIndex].perm.quizCreate,
          topicManage: this.listPermUser[permListIndex].perm.topicManage,
          outTopicManage: this.listPermUser[permListIndex].perm.outTopicManage,
        }
      }
      await axios.post(`/api/course/${this.$route.params.courseId}/permManage/update`, request).then((response) => {
        if (response.data.success){
          this.$refs.toast.show_toast("權限更新成功！！")
        }
        else{
          if(response.data.errMsg === "update not allow"){
            this.alertArgs = {
              show: true,
              state: "warning",
              title: "修改錯誤",
              bodyMsg: "不合法的修改！！（請勿修改自己的權限）",
              crossShow:false
            }
          }else{
            this.alertArgs = {
              show: true,
              state: "danger",
              title: "修改錯誤",
              bodyMsg: "修改權限錯誤，請重新嘗試!",
              crossShow:false
            }
          }

          this.fetchAllPermUser()
        }
      })
    },
    callRemoveModal: function (permListIndex) {
      this.alertArgs2 = {
        show: true,
        crossShow: false,
        title: "刪除權限",
        bodyMsg: `是否確定要移除 ${this.listPermUser[permListIndex].name} 的權限?`,
        cancelShow: true,
        tempArgs: this.listPermUser[permListIndex].index,
        confirmEvent: function () {
          this.$emit("removeUser", this.tempArgs)
        }
      }
    },
    removeUserPerm: async function (permCtrlIndex) {
      let request = {
        permCtrlId: permCtrlIndex
      }
      axios.post(`/api/course/${this.$route.params.courseId}/permManage/remove`, request).then((response) => {
        if (response.data.success) {
          this.alertArgs = {
            show: true,
            title: "移除權限",
            bodyMsg: "該權限已移除成功!!",
          }
        } else {
          if(response.data.errMsg === "remove not allow"){
            this.alertArgs = {
              show: true,
              state: 'warning',
              title: "移除錯誤",
              bodyMsg: "不合法的移除!!（請勿修改自己的權限）",
            }
          }
          else{
            this.alertArgs = {
              show: true,
              state: 'danger',
              title: "移除錯誤",
              bodyMsg: "權限移除錯誤，請重新嘗試!!",
            }
          }

        }
        this.fetchAllPermUser()
      })
    }
  }
}
</script>

<style scoped>
</style>