<template>
  <div class="container my-3">
    <div class="row">
      <div class="card">
        <div class="container">
          <div class="row my-2">
            <label class="col-form-label col-2" style="font-size: 20px">教室</label>
            <div class="col-5">
              <select class="form-select form-select-lg" v-model="onSelectClassroom" @change="loadClassroom">
                <option value="-1" selected disabled>教室選擇</option>
                <option v-for="classroom in classroomInfo" :key="classroom.classroomId" :value="classroom.classroomId">
                  {{ classroom.classroomName }}
                </option>
              </select>
            </div>
            <button class="btn btn-primary col-1 ms-2" @click="createClassroom">新增</button>
            <button class="btn btn-danger col-1 ms-2" @click="deleteModal.show = true" v-if="onSelectClassroom != -1">刪除</button>
          </div>
        </div>

      </div>
    </div>
    <div class="row mt-2" v-if="compStatus.detailShow">
      <div class="card container p-2">
        <div class="row">
          <label class="col-form-label col-1 fw-bolder">教室名稱</label>
          <div class="col-3">
            <input type="text" class="form-control" v-model="classRoomData.classroomName">
          </div>
          <label class="col-form-label col-1">教室格局</label>
          <div class="col-1 ">
            <input type="text" class="form-control text-center" v-model="classRoomData.seatRow" placeholder="row">
          </div>
          <label class="col-form-label col-1 ">*</label>
          <div class="col-1">
            <input type="text" class="form-control text-center" v-model="classRoomData.seatCol" placeholder="col">
          </div>
          <button class="btn btn-outline-info col-1 ms-2" @click="setTable">建立</button>
          <button class="btn btn-outline-danger col-1 ms-2" @click="clearTable">清除</button>
          <button class="btn btn-outline-warning col-1 ms-2" @click="reloadIp">重置IP</button>
        </div>
        <div class="row mt-2">
          <label class="col-form-label col-1">自動IP</label>
          <div class="col-3">
            <input type="text" class="form-control " v-model="classRoomData.originSeatIp">
          </div>

          <label class="col-form-label col-1">教室註解</label>
          <div class="col-3">
            <input type="text" class="form-control" v-model="classRoomData.classroomComment">
          </div>
          <button class="btn btn-info col-1 ms-2" @click="saveData">儲存設定</button>

        </div>
      </div>
    </div>
    <div class="row my-3" v-if="compStatus.detailShow">
      <div class="card py-2" style="overflow-x: auto;">
        <table style="table-layout: fixed">
          <tr v-for="(row) in seatData" :key="row">
            <td v-for="(col) in row" :key="col">
              <div>
                <seat-component :pass-seat-data="col" @emit-update="emitUpdateSeat"></seat-component>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <modal name="alertModal" v-if="alertModal.show" @close="alertModal.show = false">
    <template v-slot:header>
      <h3>
        <span>
          <i class="fas fa-exclamation-triangle"
             :class="{'text-warning':alertModal.status == 'warning','text-primary':alertModal.status == 'normal'}"></i>
        </span>
        {{alertModal.title}}
      </h3>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
        <p>{{alertModal.msg}}</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center">
        <button class="btn mx-2 btn-info" @click="alertModal.show = false">確定</button>
      </div>
    </template>
  </modal>

  <modal name="deleteModal" v-if="deleteModal.show" @close="deleteModal.show = false">
    <template v-slot:header>
      <h3>刪除課程</h3>
    </template>
    <template v-slot:body>
      <div style="width: 40vw">
          <p>是否確定要刪除<span class="text-danger">{{classRoomData.classroomName}}</span>的教室設定嗎?</p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="d-flex justify-content-center">
        <button class="btn mx-2 btn-danger" @click="deleteClassroom">刪除</button>
        <button class="btn mx-2 btn-primary" @click="deleteModal.show = false">取消</button>
      </div>
    </template>
  </modal>
</template>

<script>
import seatComponent from "./seatComponent.vue";
import ClassBox from "../courseList/courseBox.vue";
import axios from "axios";
import Modal from "../module/Modal/index.vue";


export default {
  name: "createSeating",
  components: {
    Modal,
    // eslint-disable-next-line
    ClassBox,
    // eslint-disable-next-line
    seatComponent
  },
  data() {
    this.getClassroomInfo()
    const compStatus = {
      detailShow: false,
      classroomDisable: false,
    }
    const classRoomData = {
      classroomName: '',
      classroomComment: '',
      seatRow: null,
      seatCol: null,
      originSeatIp: null,
      classroomId: -1,
    }
    const deleteModal = {
      show:false
    }
    const alertModal = {
      show:false,
      status:"normal",
      title:"",
      msg:"",
    }
    return {
      onSelectClassroom: "-1",
      deleteModal,
      alertModal,
      classroomInfo: [],
      seatData: [],
      compStatus,
      classRoomData,
    }
  },
  methods: {
    createClassroom: function () {
      this.compStatus.detailShow = true
      this.classRoomData = {
        classroomName: '',
        classroomComment: '',
        seatRow: null,
        seatCol: null,
        originSeatIp: null,
        classroomId: -1,
      }
      this.seatData = []
      this.onSelectClassroom = -1
    },
    loadClassroom: async function () {
      if (this.onSelectClassroom != -1) {
        await axios.post(`/api/classroom/setting`, {classroomId: this.onSelectClassroom}).then((response) => {
          if (response.data.success) {
            this.compStatus.detailShow = true
            let seatData = response.data.info.seatSetting
            this.classRoomData = {
              classroomName: response.data.info.classroomTitle,
              classroomComment: response.data.info.classroomComment,
              seatRow: seatData.length,
              seatCol: seatData[0].length,
              originSeatIp: null,
              classroomId:  response.data.info.classroomId,
            }
            this.seatData = seatData
          }
        })
      }
    },
    setTable: function () {
      this.seatData = []
      let seatIndex = 0;
      for (let i = 0; i < this.classRoomData.seatRow; i++) {
        this.seatData.push([])
        for (let j = 0; j < this.classRoomData.seatCol; j++) {
          let seatObj = {
            id: seatIndex,
            seatRow: i + 1,
            seatCol: j + 1,
            seatIp: this.getSeatIP(seatIndex),
            disable: false
          }
          seatIndex++
          this.seatData[i].push(seatObj)
        }
      }
    },
    clearTable: function () {
      this.seatData = []
    },
    reloadIp: function () {
      let ipStep = 0;
      for (let i = 0; i < this.classRoomData.seatRow; i++) {
        for (let j = 0; j < this.classRoomData.seatCol; j++) {
          if (!this.seatData[i][j].disable) {
            this.seatData[i][j].seatIp = this.getSeatIP(ipStep++);
          }
        }
      }
    },
    getSeatIP: function (seatId) {
      if (/^\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}$/.test(this.classRoomData.originSeatIp)) {
        let ipStr = this.classRoomData.originSeatIp.split(".")
        let ip = ipStr.map(x => parseInt(x))
        ip[3] += seatId;
        if (ip[3] > 255) {
          ip[3] = ip[3] - 255
          ip[2] += 1
        }
        if (ip[2] > 255) {
          ip[2] = ip[2] - 256
          ip[1] += 1
        }
        if (ip[1] > 255) {
          ip[1] = ip[1] - 256
          ip[0] += 1
        }
        if (ip[0] > 255)
          ip[0] = 255
        return `${ip[0]}.${ip[1]}.${ip[2]}.${ip[3]}`
      }
    },
    emitUpdateSeat: function (seatData) {
      for (let i = 0; i < this.classRoomData.seatRow; i++) {
        for (let j = 0; j < this.classRoomData.seatCol; j++) {
          if (this.seatData[i][j].id == seatData.id) {
            this.seatData[i][j] = seatData
          }
        }
      }
    },
    saveData: function () {
      let reqData = {
        classroomId: this.classRoomData.classroomId,
        classroomName: this.classRoomData.classroomName,
        classroomComment: this.classRoomData.classroomComment,
        seatSetting: this.seatData
      }
      axios.post("/api/classroom/save", reqData).then((response)=>{
        if(response.data.success)
        {
          this.alertModal.title = "儲存成功"
          this.alertModal.status = "normal"
          this.alertModal.msg = "設定儲存完成"
          this.alertModal.show = true
          if( response.data.createId != undefined){
            this.classRoomData.classroomId = response.data.createId
            this.onSelectClassroom = response.data.createId
            this.getClassroomInfo()
          }

        }
        else {
          this.alertModal.title = "儲存失敗"
          this.alertModal.status = "warning"
          this.alertModal.msg = "儲存教室失敗"
          this.alertModal.show = true
        }
      })

    },
    getClassroomInfo: function () {
      axios.post('/api/classroom/info').then((res) => {
        this.classroomInfo = res.data.info
      })
    },
    deleteClassroom:async function (){
      this.deleteModal.show = false
      await axios.post('/api/classroom/delete',{classroomId:this.classRoomData.classroomId}).then((response)=>{
        if(response.data.success){
          this.alertModal.title = "刪除成功"
          this.alertModal.status = "normal"
          this.alertModal.msg = "刪除教室成功"
          this.alertModal.show = true
        }
        else {
          this.alertModal.title = "刪除失敗"
          this.alertModal.status = "warning"
          this.alertModal.msg = "刪除教室失敗"
          this.alertModal.show = true
        }
      })
      this.getClassroomInfo()
      this.createClassroom()
      this.compStatus.detailShow = false

    }
  }
}
</script>

<style scoped>

</style>