/* eslint-disable */
import dayjs from "dayjs";
import axios from "axios";

/* Field
    LoginExpire
    Course

*/

export default {
    // fetcher ==============
    // 取得全部班級權限(包含進階功能)
    fetchAllCourse: async function () {
        await axios.post("/api/auth/courseInfo/all")
            .then((res) => {
                this.setAllCourse(res.data)
            })
    },
    // 取得班級權限(包含進階功能)
    fetchCourse: async function (courseId) {
        await axios.post("/api/auth/courseInfo", {courseId: courseId})
            .then((res) => {
            this.setCourse(courseId, res.data)
        })
    },
    // 取得系統權限
    fetchSystemAuth: async function () {
        await axios.post("/api/auth/systemCtrl").then((res) => {
            this.setSystemAuth(res.data)
        })
    },

    // SETTER ==============
    // 更新逾期時間
    updateLoginExpire: function () {
        let expire = dayjs(new Date()).add(1, 'h').toDate()
        localStorage.setItem("LoginExpire", expire.getTime().toString())
    },
    // 設定特定課程
    setCourse: function (courseId, data) {
        let courses = this.getAllCourse();
        courses[`course${courseId}`] = data
        localStorage.setItem("Courses", JSON.stringify(courses))
    },
    // 設定全部課程
    setAllCourse: function (data) {
        let courses = this.getAllCourse();
        courses = data
        localStorage.setItem("Courses", JSON.stringify(courses))
    },
    // 設定系統權限
    setSystemAuth: function (data) {
        localStorage.setItem("System", JSON.stringify(data))
    },

    // GETTERS ==============

    // 取得登入是否有效
    getLoginExpire: function () {
        let expireTime = localStorage.getItem("LoginExpire")
        return parseInt(expireTime)
    },
    // 取得所有課程
    getAllCourse: function () {
        let courseJson = localStorage.getItem("Courses") || "{}"
        let courses = JSON.parse(courseJson)
        return courses
    },
    // 取得特定課程
    getCourse: function (courseId) {
        let courseJson = localStorage.getItem("Courses") || "{}"
        let courses = JSON.parse(courseJson)
        return courses[`course${courseId}`] || {}
    },
    // 取得系統權限
    getSystemAuth: function () {
        let systemJson = localStorage.getItem("System") || "{}"
        let systemAuth = JSON.parse(systemJson)
        return systemAuth
    },

    // ACTION ==============
    // 登入逾期了嗎
    isLoginValid: function () {
        if((new Date()).getTime() < this.getLoginExpire()){
            return true
        }
        else {
            localStorage.clear()
            return false
        }
    },
    // 有課程瀏覽權限嗎
    tryGetCourse: async function (courseId){
        let course = this.getCourse(courseId)
        // 如果沒有權限 嘗試取得權限
        if(JSON.stringify(course) === "{}" || course.auth === false)
        {
            // 透過API詢問權限
            await this.fetchCourse(courseId)
            // 再次取得權限
            course = this.getCourse(courseId)
        }
        return course.auth
    },
    // 有課程管理權限嗎
    tryGetCourseManage: async function (courseId){
        let course = this.getCourse(courseId)
        // 如果沒有權限 嘗試取得權限
        if(JSON.stringify(course.perm) === "{}")
        {
            // 透過API詢問權限
            await this.fetchCourse(courseId)
            // 再次取得權限
            course = this.getCourse(courseId)
        }
        return course.perm
    },
    // 有課程管理權限嗎
    tryGetSystemManage: async function (){
        let systemAuth = this.getSystemAuth()
        // 如果沒有權限 嘗試取得權限
        if(JSON.stringify(systemAuth) === "{}")
        {
            // 透過API詢問權限d
            await this.fetchSystemAuth()
            // 再次取得權限
            systemAuth = this.getSystemAuth()
        }
        return systemAuth
    },
    logout:function (){
        localStorage.clear()
    }


}