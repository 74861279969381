<template>
  <div class="container mt-5">
    <div class="row my-3 mx-2">
      <h2 class="d-flex justify-content-start"><strong>題庫管理</strong></h2>
    </div>
    <div class="row">
      <div class="col">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="{'active' : navTabSelect == '0'}" aria-current="page" href="#"
               @click="navTabSelect = '0'">程式考題</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="{'active' : navTabSelect == '1'}" href="#"
               @click="navTabSelect = '1'">表單考卷</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="border">
          <program-table v-if="navTabSelect == '0'"></program-table>
          <form-table v-if="navTabSelect == '1'"></form-table>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import ProgramTable from "./component/programTable.vue";
import FormTable from "./component/formTable.vue";
export default {
  name: "QBInterface",
  components: {FormTable, ProgramTable},
  data() {
    return {
      navTabSelect: "0"
    }
  }
}
</script>

<style scoped>
.border {
  border-top: 0px !important;
}
</style>