<template>
  <navbar></navbar>
  <create-problem :mode="mode"></create-problem>
</template>

<script>
import navbar from "../module/navbar"
import createProblem from "./createProblem.vue";

export default {
  name: "index",
  props:{
    mode: {
      type: String
    }
  },
  components:{
    navbar,
    createProblem,
  },
}
</script>

<style scoped>

</style>