<template>
  <div class="container mt-4" >
    <div class="card " v-if="allAuth">
      <div class="row d-flex justify-content-end">
        <p class="w-auto m-1">
          <span v-if="allPerm.courseSetting">
          <router-link :to="`/course/${this.$route.params.courseId}/setting`" class="link-dark mx-1" >課程設定</router-link>

          </span>
          <span v-if="allPerm.coursePermCtrl">
            <router-link :to="`/course/${this.$route.params.courseId}/permission`" class="link-dark mx-1">權限管理</router-link>

          </span>
          <span v-if="allPerm.examManage">
            <router-link :to="`/course/${this.$route.params.courseId}/examManage`" class="link-dark mx-1">考試管理</router-link>

          </span>
          <span v-if="allPerm.questionSetting">
            <router-link :to="`/course/${this.$route.params.courseId}/QBank`" class="link-dark mx-1">題庫管理</router-link>

          </span>

          <span v-if="allPerm.hisQuestionSetting">
            <router-link :to="`/course/${this.$route.params.courseId}/QBankImport`" class="link-dark mx-1">外部題庫管理</router-link>

          </span>
          <span v-if="allPerm.examManage">
            <router-link :to="`/course/${this.$route.params.courseId}/add-exam`" class="link-danger mx-1">建立考試</router-link>

          </span>
          <span v-if="allPerm.quizCreate">
            <router-link :to="`/course/${this.$route.params.courseId}/createFormPaper`" class="link-info mx-1">新增表單模板</router-link>

          </span>
          <span v-if="allPerm.quizCreate">
            <router-link :to="`/course/${this.$route.params.courseId}/createProgram`" class="link-info mx-1">新增程式考題</router-link>

          </span>
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import auth from '../../store/auth'

export default {
  name: "index",
  data() {
    this.updateAllPerm()
    return {
      allPerm: {}
    }
  },
  methods: {
    updateAllPerm: async function () {
      let data = await auth.tryGetCourseManage(this.$route.params.courseId)
      this.allPerm = data
    }
  },
  computed:{
    allAuth :function (){
      return this.allPerm && (this.allPerm.courseSetting || this.allPerm.classPermCtrl || this.allPerm.examManage
          || this.allPerm.examCreate  || this.allPerm.topicManage || this.allPerm.outTopicManage)

    }
  }
}

</script>

<style scoped>

</style>