<template>
  <div v-if="uploadShow" class="container-fluid" style="height: 90vh">
    <div class="row mt-4 h-father">
      <div class="col-sm-4 h-father px-0">
        <div class="row m-0" style="height: 15vh;" v-if="examType=='exam'">

          <div class="d-flex align-items-center" >
            <div class="flex-shrink-0">
              <div v-if="student.img == ''" class="spinner-border" role="status"
                   style="height: 100px;width: 100px;background-size: contain;">
                <span class="visually-hidden">Loading...</span>
              </div>
              <img v-if="student.img != ''" :src="'/api/img/' + student.img" alt="mdo"
                   class="float-start img-fluid img-thumbnail">
            </div>
            <div class="flex-grow-1 ms-3" style="font-size: 20px;">
              <p class="col-m-6 my-3"><span class="fw-bold">學號 :</span> {{ student.std_id }}</p>
              <p class="col-m-6 my-3"><span class="fw-bold">姓名 :</span> {{ student.name }}</p>
            </div>
          </div>
          <hr>
        </div>

        <div class="py-3 container-fluid describe-container" :style="{'height':(examType=='exam')?'70vh':'85vh'}">
          <div class="row ">
            <h3 class="col text-center fw-bold" style="color: darkblue">{{ illustrate.title }}</h3>
          </div>
          <div class="row justify-content-evenly">
            <p class="col-xl-6"><span class="fw-bold">課程名稱 :</span> {{ illustrate.courseName }}</p>
            <p class="col-xl-6"><span class="fw-bold">考試名稱 :</span> {{ illustrate.examName }}</p>
          </div>
          <hr>
          <div class="row mt-3">
            <span v-html="bodyHtml"></span>
          </div>
        </div>
      </div>
      <div class="col-sm-8 h-father px-0">
        <div class="container-fluid h-father">
          <div class="row ">
            <ul class="nav nav-tabs dark-tabs">
              <li v-for="(data,index) in fileData" :key="index" :class="{'active':pageArgs.selectedFile === index}"
                  class="nav-item">
                <button class="nav-link" style="color: inherit" @click="selectFile(index)">
                  <i class="far fa-file-code mx-2"></i>
                  <span>{{ data.fileName }}</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="row">
            <ace-editor ref="ace" v-model:value="fileData[pageArgs.selectedFile].code" :lang="illustrate.lang"></ace-editor>
          </div>
          <div class="row my-3  " style="height: 20vh">
            <div class="col-sm-3 card me-2 py-2 overflow-auto h-father">
              <button v-if="btnEnable.execute" class="btn btn-primary mt-2" style="font-size: 16px" @click="submitCode"
                      :disabled="!buttonStatus.submit">
                <span
                    class="fw-bold">執行程式</span> <i
                  class="fas fa-paper-plane mx-1"></i></button>
              <button v-if="btnEnable.view" class="btn btn-info mt-2" style="font-size: 16px" @click="viewSystemOutput">
                <span class="fw-bold">檢視輸入</span>
                <i class="far fa-eye mx-1"></i></button>
              <button v-if="btnEnable.download" class="btn btn-warning mt-2" style="font-size: 16px"><span
                  class="fw-bold">下載程式</span><i
                  class="fas fa-download mx-1"></i></button>
            </div>
            <div class="col card py-2" style="height: 20vh">
              <h5 class="fw-bold">執行結果</h5>
              <hr class="m-0 p-0">
              <div class="h-auto w-auto overflow-auto" style="background-color: rgba(204,204,204,0.2)">
                <pre>{{ compileResult.student }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
</template>

<script>
// import axios from "axios";
import aceEditor from "../module/aceEditor.vue";
import bbCodeParser from 'js-bbcode-parser';
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "codingPanel",
  components: {
    AlertModal,
    aceEditor
  },
  data() {
    const Base64 = require('js-base64')
    const buttonStatus = {
      submit: true,

    }
    const pageArgs = {
      selectedFile: 0,
    }
    const compileResult = {
      student: "",
      input: "Please submit your code first !",
      answer: "Please submit your code first !"
    }
    const illustrate = {
      courseName: "1111物件導向設計",
      examName: "期初考試",
      title: "第一題:BMI 計算程式",
      bodyBBCode: ``,
      lang:""
    }
    const alertArgs = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    const btnEnable = {
      execute: true,
      view: true,
      download: true,
    }
    this.getCodePaper()
    return {
      Base64,
      pageArgs,
      alertArgs,
      illustrate,
      btnEnable,
      compileResult,
      buttonStatus,
      student: {},
      examType:"practice",
      uploadShow: false,
      fileData: [{fileName: 'main.cpp', code: ""}, {fileName: 'BMI.cpp', code: ""}, {fileName: 'BMI.h', code: ""}],
    }
  },
  methods: {
    getCodePaper: async function () {
      await axios.post(`/api/program/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.programId}/codePaper`).then((res) => {
        if (res.data.success) {
          this.uploadShow = true
          this.alertArgs = false
          this.illustrate = res.data.illustrate
          this.btnEnable = res.data.btnEnable
          this.fileData = res.data.fileData
          this.student = res.data.student
          this.examType = res.data.examType
          this.lang = res.data.lang
        } else {
          this.alertArgs = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "此頁面讀取失敗，請重新嘗試",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}`
            }
          }
        }
      })

    },
    selectFile: function (fileIndex) {
      this.pageArgs.selectedFile = fileIndex
      this.$refs.ace.reloadData(this.fileData[this.pageArgs.selectedFile].code)
    },
    viewSystemOutput: function () {
      this.alertArgs = {
        show: true,
        confirmShow: false,
        crossShow: true,
        bodyHtml:
            `<h4 class="fw-bold text-start">系統輸入</h4><pre>${this.compileResult.input}</pre><h4 class="fw-bold text-start">系統輸出</h4> <pre>${this.compileResult.answer}</pre>`,
        title: "系統 輸入/輸出",
        bodyStyle: 'height:70vh;width:70vw;text-align: start;'
      }
    },
    formatCode: function () {
      let rtFileData = []
      for (let i = 0; i < this.fileData.length; i++) {
        rtFileData.push({
          fileName: this.fileData[i].fileName,
          code: this.Base64.encode(this.fileData[i].code)
        })

      }
      return rtFileData
    },
    submitCode: function () {
      this.buttonStatus.submit = false
      //console.log("submit")
      const postData = {
        courseId: this.$route.params.courseId,
        quizId: this.$route.params.examId,
        programId: this.$route.params.programId,
        codes: this.formatCode()
      }
      axios.post('/api/program/submit', postData).then(res => {
        //console.log(res.data)
        this.compileResult.student = (res.data.code.student) ? this.Base64.decode(res.data.code.student ) : ""
        this.compileResult.input = (res.data.code.input) ? this.Base64.decode(res.data.code.input) : ""
        this.compileResult.answer = (res.data.code.answer) ? this.Base64.decode(res.data.code.answer) : ""
        if (res.data.success) {
          this.alertArgs = {
            show: true,
            title: "程式正確通知",
            bodyMsg: "程式檢查通過，題目作答完成!!",
            crossShow: false
          }
        } else {
          this.alertArgs = {
            state: 'warning',
            show: true,
            title: "程式錯誤通知",
            bodyMsg: res.data.info,
            crossShow: false
          }
        }
        this.buttonStatus.submit = true
      })
    },
  },
  computed: {
    bodyHtml: function () {
      return bbCodeParser.parse(this.illustrate.bodyBBCode)
    },
  },
}
</script>

<style scoped>

.describe-container {
  background-color: rgba(58, 59, 59, 0.2);
  border-radius: 5px;
  height: 100%;
  overflow-y: auto;
}

.row {
  text-align: left;
  margin: auto;
}

img {
  max-height: 100px;
}

.dark-tabs {
  margin: 0px 0px -1px 0px;
  border-radius: 10px 10px 0px 0px;
  color: #FFFFFF !important;
  background-color: rgb(58, 59, 58) !important;
}

.active {
  color: #88dcea !important;
  border-radius: 10px 10px 0px 0px;
  background-color: rgb(15, 17, 15) !important;
  border-color: white !important;
}

.nav-link {
  color: #dee2e6;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.h-90 {
  height: 90%;
}

.h-father {
  height: inherit;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}
</style>