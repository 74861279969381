<template>
  <navbar></navbar>
  <div class="container my-4">
    <div class="row">
      <div class="col-3">
        <time-side-bar :data-obj="listTimeSet" @add-time="newTime"
                       :now-choose="nowChoose" @selectItem="selectItem"></time-side-bar>
      </div>

      <div class="col-8 container-fluid">
        <div v-if="dataObj.length != 0 && nowChoose != -1">
          <div class="row">
            <time-content class="col" v-model:title="dataObj.title"
                          v-model:start-time="dataObj.timeStart" @btn-cancel="selectItem(nowChoose)"
                          v-model:end-time="dataObj.timeEnd" @btn-save="saveTimeSetting"></time-content>
          </div>
          <div class="row mt-2">

            <seat-distribute class="col" v-model="dataObj.ipSetting"
                             v-model:select-classroom="seatDetailArgs.selectClassroom"
                             v-model:select-setting="seatDetailArgs.selectSetting"
                             v-model:setting-disable="dataObj.disable"
                             :settingTitle="dataObj.title"></seat-distribute>
          </div>
        </div>

      </div>
    </div>
  </div>
  <alert-modal v-model="alertArgs.show" :="alertArgs"></alert-modal>
</template>

<script>
import timeSideBar from "./ipModule/timeSideBar.vue";
import navbar from "../module/navbar";
import timeContent from "./ipModule/timeContent.vue";
import SeatDistribute from "./seatModule/seatDistribute.vue";
import axios from "axios";
import AlertModal from "../module/Modal/alertModal.vue";

export default {
  name: "index",
  data() {
    this.fetchTimeList()
    const alertArgs = {
      show: false
    }
    const seatDetailArgs = {
      selectClassroom: -1,
      selectSetting: -1,
    }
    return {
      seatDetailArgs,
      alertArgs,
      nowChoose: -1,
      listTimeSet: [],
      dataObj: {
        id: null,
        title: null,
        timeStart: null,
        timeEnd: null,
        ipSetting: [],
        disable: false
      },

    }
  },
  components: {
    AlertModal,
    SeatDistribute,
    navbar,
    timeSideBar,
    timeContent
  },
  methods: {
    newTime: function (title) {
      const newObj = {
        title: title,
        timeStart: null,
        timeEnd: null,
        ipSetting: [],
        disable: false
      }
      this.nowChoose = this.listTimeSet.length
      this.dataObj = newObj
    },
    selectItem: function (select) {
      this.nowChoose = select
      this.seatDetailArgs.selectSetting = -1
      this.seatDetailArgs.selectClassroom = -1
      if (this.listTimeSet.length > select && 0 < select) {
        let request = {
          settingId: this.listTimeSet[select].id
        }
        axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/time-setting/detail`,
            request).then(response => {
          if (response.data.success) {
            this.dataObj = response.data.info
            this.dataObj.ipSetting = response.data.info.ipSetting
          }
        })
      }
    },
    fetchTimeList: async function () {
      axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/time-setting/list`).then(response => {
        if (response.data.success) {
          this.listTimeSet = []
          response.data.info.map(mapItem => {
            this.listTimeSet.push(mapItem)
          })
        }
      })
    },
    deleteTimeSetting: function () {
      if (this.listTimeSet.length > this.nowChoose && 0 < this.nowChoose) {
        const request = {
          settingId: this.listTimeSet[this.nowChoose].id
        }
        axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/time-setting/remove`
            , request).then(response => {
          if (response.data.success) {
            this.alertArgs = {
              show: true,
              title: '刪除成功',
              bodyMsg: `刪除設定成功!`,
            }

          } else {
            this.alertArgs = {
              show: true,
              state: 'warning',
              title: '刪除失敗',
              bodyMsg: `刪除設定失敗，請重新嘗試!`,
            }
          }
        })
      }
    },
    saveTimeSetting: function () {
      const request = {
        settingId: this.dataObj.id,
        title: this.dataObj.title,
        timeStart: this.dataObj.timeStart,
        timeEnd: this.dataObj.timeEnd,
        ipSetting: this.dataObj.ipSetting,
        disable: this.dataObj.disable,
      }
      axios.post(`/api/course/${this.$route.params.courseId}/quiz/${this.$route.params.examId}/time-setting/update`, request)
          .then(response => {
            if (response.data.success) {
              this.alertArgs = {
                show: true,
                title: '儲存成功',
                bodyMsg: `儲存設定 ${request.title} 成功`,
              }
            } else {
              this.alertArgs = {
                show: true,
                title: '儲存失敗',
                bodyMsg: `儲存設定失敗，請重新嘗試`,
                state: 'warning'
              }
            }
            this.fetchTimeList()
          })
    },
  }
}
</script>

<style scoped>

</style>