<template>
  <navbar></navbar>
  <div class="container my-3">
    <div class="row">
      <div class="d-flex justify-content-start  mt-3">
        <h3>
          <strong>
            教室設定
          </strong>
        </h3>
      </div>
    </div>
    <div class="row">
      <create-classroom></create-classroom>
    </div>
  </div>

</template>

<script>
import navbar from "../module/navbar/";
import createClassroom from "./createClassroom.vue";

export default {
  name: "index",
  components: {
    navbar,
    createClassroom,
  }
}
</script>

<style scoped>

</style>