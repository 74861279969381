<template>
    <form v-show="uploadShow">
        <div class="container d-flex justify-content-center">
            <div class="w-75" style="min-width: 800px">
                <div class="row">
                    <h1 v-if="mode == 'add'" class="mb-3 fw-bolder">創建程式題目</h1>
                    <h1 v-if="mode == 'edit'" class="mb-3 fw-bolder">修改程式題目</h1>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">題目名稱</label>
                    <div class=" col-sm-9">
                        <input v-model="title" class="form-control" placeholder="請輸入創建題目的名稱" required
                               type="text">
                    </div>
                </div>
                <div v-if="warningStatus.title" class="row data-brick mb-sm-1 text-start">
                    <small class="col-sm-3"></small>
                    <small class="col-sm-9 text-danger">題目名稱不可為空</small>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">題目分類</label>
                    <div class=" col-sm-9">
                        <input v-model="sort" class="form-control" placeholder="請輸入題目的分類" required type="text">
                    </div>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">題目版本號</label>
                    <div class=" col-sm-9">
                        <input v-model="version" class="form-control" placeholder="default" required type="text">
                    </div>
                </div>
                <div v-if="version != '' && !version.match(/^([0-9]+)(\.?)([0-9]*)$/)"
                     class="row data-brick mb-sm-1 text-start">
                    <small class="col-sm-3"></small>
                    <small class="col-sm-9 text-danger">版本號錯誤，範例ex:(0.5、45.9、10)</small>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">題目內容</label>
                    <div class=" col-sm-9">
                        <Editor v-model="content" :init="{plugins: 'lists link image table code help'}"
                                api-key="nqmmgz0hify7g3z6dbn178ae0us504wb3qq98kbad1fysmov"
                                @init="editorStandBy"/>
                    </div>
                </div>
                <div v-if="warningStatus.content" class="row data-brick mb-sm-1 text-start">
                    <small class="col-sm-3"></small>
                    <small class="col-sm-9 text-danger">題目內容不可為空</small>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">開課工具</label>
                    <div class=" col-sm-9 text-start">
                        <select v-model="language" class="form-select" required>
                            <option disabled selected value="">語言</option>
                            <option v-for="(data,index) in ['C++','C','Python','Java']" :key="index" :value="data">
                                {{ data }}
                            </option>
                        </select>
                    </div>
                </div>

                <div v-if="warningStatus.language" class="row data-brick mb-sm-1 text-start">
                    <small class="col-sm-3"></small>
                    <small class="col-sm-9 text-danger">開課工具不可為空</small>
                </div>

                <div class="row data-brick mb-sm-1">
                    <label class="col-sm-3 col-form-label fw-bold">關鍵字</label>
                    <div class="col-sm-9">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>關鍵字</th>
                                <th>最少</th>
                                <th>最多</th>
                                <th>動作</th>
                            </tr>
                            <registerArray v-for="(Array,ArrayIndex) in Arrays" :key="ArrayIndex" :="Array"
                                           :index="ArrayIndex"
                                           @edit-register-array="editRegisterArray"
                                           @delete-register-array="deleteRegisterArray"/>
                            <tr>
                                <td>-</td>
                                <td><input v-model="keyWord" class="form-control inputWidth" placeholder="關鍵字"
                                           type="text"></td>
                                <td><input v-model="min" class="form-control inputWidth" placeholder="最少"
                                           type="number"></td>
                                <td><input v-model="max" class="form-control inputWidth" placeholder="最多"
                                           type="number"></td>
                                <td>
                                    <button class="btn btn-primary me-0 inputWidth" type="button"
                                            @click="pushArray()"><i class="fas fa-plus-circle"></i>
                                    </button>
                                </td>
                                <td>
                                    <button id="group" class="btn btn-primary me-0 inputWidth" type="button"
                                            @click="pushArray();pushKeyArray()"><i class="fas fa-check"></i>
                                    </button>
                                </td>
                            </tr>
                            </thead>

                        </table>

                        <div class="data-brick mb-sm-1 card my-2">
                            <div class="card-header">
                                <button ref="keyWord" :aria-controls="'keyWord'" :data-bs-target="'#keyWord'"
                                        aria-expanded="false"
                                        class="d-none" data-bs-toggle="collapse" type="button">
                                </button>
                                <a class="text-black pure-link text-start" data-bs-toggle="collapse" role="button"
                                   @click="keywordCollapse">
                                    <div class="d-flex justify-content-between">
                                        <span class="d-flex"><strong>已設立關鍵字</strong></span>
                                        <i class="fas fa-angle-down"></i>
                                    </div>
                                </a>
                            </div>

                            <div id="keyWord" class="collapse p-0">
                                <div class="card-body">
                                    <table class="table table-borderless">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>關鍵字</th>
                                            <th>最少</th>
                                            <th>最多</th>
                                            <th>操作</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </thead>

                                        <group-set v-for="(keyWordGroup,keyWordGroupIndex) in keyWordGroups"
                                                   :key="keyWordGroupIndex" :Arrays="keyWordGroup.Arrays"
                                                   :numberCode="keyWordGroupIndex"
                                                   @add-key-word="addKeyWord" @edit-key-word="editKeyWord"
                                                   @delete-key-word="deleteKeyWord"/>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row data-brick mb-sm-1">
                    <div class="input-group col-sm-9">
                        <label class="col-sm-3 control-label fw-bold" for="defaultFile">預設檔案</label>
                        <button v-if="mode == 'edit'" type="button" class="col-sm-2 ms-2 btn btn-primary "
                                @click="downloadCode('default')">
                            <i class="fas fa-file-download"></i></button>
                        <input id="defaultFile" class="form-control ms-2" multiple required="true" type="file"
                               @change="readFile($event,'defaultFile')">
                    </div>
                </div>

                <div class="row data-brick mb-sm-1">
                    <div class="input-group col-sm-9">
                        <label class="col-sm-3 control-label fw-bold" for="inputFile">輸入檔案</label>
                        <button v-if="mode == 'edit'" type="button" class="col-sm-2  ms-2 btn btn-primary "
                        @click="downloadCode('input')">
                            <i class="fas fa-file-download"></i>
                        </button>
                        <input id="inputFile" class="form-control ms-2" required="true" type="file"
                               @change="readFile($event,'inputFile')">
                    </div>
                </div>

                <div class="row data-brick mb-sm-1">
                    <div class="input-group col-sm-9">
                        <label class="col-sm-3 control-label fw-bold" for="answerFile">答案檔案</label>
                        <button v-if="mode == 'edit'" type="button" class="col-sm-2  ms-2 btn btn-primary "
                                @click="downloadCode('answer')">
                            <i class="fas fa-file-download"></i></button>
                        <input id="answerFile" class="form-control ms-2" required="true" type="file"
                               @change="readFile($event,'answerFile')">
                    </div>
                </div>

                <div class="row data-brick mb-sm-1">
                    <div class="input-group col-sm-9">
                        <label class="col-sm-3 control-label fw-bold">功能選擇</label>

                        <div class="form-check mx-2">

                            <input id="allowView" v-model="viewBoolean" :false-value="0"
                                   :true-value="1" class="form-check-input" type="checkbox">
                            <label class="form-check-label" for="allowView">允許學生檢視輸出入</label>
                        </div>
                        <div class="form-check mx-2">
                            <input id="allowDownload" v-model="downloadBoolean" :false-value="0"
                                   :true-value="1" class="form-check-input" type="checkbox">
                            <label class="form-check-label" for="allowDownload">允許學生下載程式</label>
                        </div>
                    </div>
                </div>

                <div>
                    <button v-if="mode=='add'" class="btn btn-outline-primary me-3" type="button"
                            @click="createProgram">
                        <i class="fas fa-check-circle"></i>
                    </button>
                    <button v-if="mode=='edit'" class="btn btn-outline-primary me-3" type="button" @click="saveUpdate">
                        <i class="fas fa-save"></i>
                    </button>
                    <button class="btn btn-outline-danger me-3" type="button" @click="cancelCreate">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </div>
        </div>

    </form>
    <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import AlertModal from "../module/Modal/alertModal.vue";
import groupSet from "./groupSet.vue";
import registerArray from "./registerArray.vue";
import Editor from '@tinymce/tinymce-vue';
import axios from "axios";
import {useToast} from "vue-toastification";
import {Base64} from "js-base64";

export default {
    name: "index",
    props: {
        mode: {
            type: String
        }
    },
    data() {
        const Base64 = require('js-base64')
        const standBy = {
            editor: false,
            fetchData: false,
        }
        const alertModal = {
            show: true,
            state: "loading",
            title: "讀取中",
            crossShow: false,
            confirmShow: false
        }
        const warningStatus = {
            title: false,
            content: false,
            language: false
        }
        const toast = useToast()
        const options = {
            position: "bottom-right",
            timeout: 1492,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: true,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false
        }
        this.loadExistData()
        return {
            standBy,
            debug: true,
            Base64,
            alertModal,
            warningStatus,
            toast,
            options,
            title: "",
            sort: "",
            version: "",
            content: "",
            language: "",
            keyWord: "",
            code_detail: {},
            min: 1,
            max: 99,
            Arrays: [],//{keyWord: 'sad', min: 1, max: 99,}
            keyWordGroups: [],
            //keyWordGroups: [{"id": 1, "Arrays": [{"keyWord": "test", "min": 1, "max": 99}, {"keyWord": "ds", "min": 1, "max": 99}]}, {"id": 2, "Arrays": [{"keyWord": "dsf", "min": 1, "max": 99}]}, {"id": 3, "Arrays": [{"keyWord": "cxzc", "min": 1, "max": 99}, {"keyWord": "sadsa", "min": 1, "max": 99}]}],
            allFiles: {
                defaultFile: [],//[{name:'',content:''}]
                inputFile: [],
                answerFile: [],
            },
            viewBoolean: 1,
            downloadBoolean: 1,
            uploadShow: false,
        }
    },
    components: {
        groupSet, registerArray, Editor, AlertModal
    },
    methods: {
        editorStandBy: function () {
            this.standBy.editor = true
            this.isShowTime()

        },
        loadExistData: async function () {
            if (this.mode == "edit") {
                const courseId = this.$route.params.courseId
                const programId = this.$route.params.programId
                await axios.post(`/api/program/${programId}/edit/load`, {courseId: courseId}).then(result => {
                    const res = result.data
                    if (res.success) {
                        this.sort = res.info.sort
                        this.title = res.info.title
                        this.version = res.info.version
                        //this.content = res.info.content
                        this.content = (res.info.content) ? this.Base64.decode(res.info.content) : ""
                        this.code_detail = res.info.code_id
                        this.keyWordGroups = this.clientRebuildKeyword(res.info.keyword)
                        this.language = res.info.language
                        this.viewBoolean = res.info.view
                        this.downloadBoolean = res.info.download
                        this.standBy.fetchData = true
                        this.isShowTime()
                    } else {
                        this.alertModal = {
                            show: true,
                            state: "warning",
                            title: "讀取失敗，請重新嘗試",
                            crossShow: false,
                            confirmEvent: function () {
                                this.$router.push(`/course/${this.$route.params.courseId}`)
                            }
                        }
                    }
                })
            }
        },
        isShowTime: function () {
            let state = this.standBy.editor && (this.mode == "add" || this.standBy.fetchData)
            if (state) {
                this.alertModal = {show: false}
                this.uploadShow = true
            }
        },
        clientRebuildKeyword: function (serverSideKeyword) {
            let keywords = []
            serverSideKeyword.forEach((data, index) => {
                let keyword = {id: index += 1, Arrays: []}
                data.forEach(data1 => {
                    keyword.Arrays.push({
                        keyWord: data1.keyword,
                        min: data1.min,
                        max: data1.max
                    })
                })
                keywords.push(keyword)
            })
            return keywords
        },
        serverRebuildKeyword: function (clientSideKeyword) {
            let keywords = []

            clientSideKeyword.forEach((data) => {
                let keyword = []
                data.Arrays.forEach(data1 => {
                    keyword.push({
                        keyword: data1.keyWord,
                        min: data1.min,
                        max: data1.max
                    })
                })
                keywords.push(keyword)
            })
            return keywords
        },
        saveUpdate: async function () {
            if (this.mode == "edit") {
                // 標題填寫
                this.warningStatus.title = (this.title != '') ? false : true
                // 標題填寫
                this.warningStatus.content = (this.content != '') ? false : true
                // 語言填寫
                this.warningStatus.language = (this.language != '') ? false : true
                // 整合條件
                let allWarning = (!this.warningStatus.title && !this.warningStatus.content && !this.warningStatus.language) ? true : false
                // 判斷版本號正確與否
                if (allWarning)
                    allWarning = (this.version == '') ? true : (this.version.match(/^([0-9]+)(\.?)([0-9]*)$/)) ? true : false
                if (allWarning) {
                    const courseId = this.$route.params.courseId
                    const programId = this.$route.params.programId
                    const postData = {
                        courseId: courseId,
                        sort: this.sort,
                        title: this.title,
                        version: this.version,
                        content: this.Base64.encode(this.content),
                        keyword: this.serverRebuildKeyword(this.keyWordGroups),
                        language: this.language,
                        view: this.viewBoolean,
                        download: this.downloadBoolean,
                        files: this.allFiles
                    }
                    await axios.post(`/api/program/${programId}/edit/save`, postData).then(response => {
                        const res = response.data
                        if (res.success) {
                            this.alertModal = {
                                show: true,
                                state: "normal",
                                title: "儲存成功",
                                crossShow: false,
                                bodyMsg: "該考試內容已儲存成功",
                                confirmEvent: function () {
                                    this.$router.go(-1)
                                }

                            }
                        } else {
                            this.alertModal = {
                                show: true,
                                state: "warning",
                                title: "儲存失敗",
                                crossShow: false,
                                bodyMsg: "儲存程式考題內容失敗，請重新嘗試"
                            }
                        }
                    })
                }
            }

        },
        pushArray() {
            if (this.keyWord !== "") {
                this.maxMinSwap()
                this.Arrays.push({
                    "keyWord": this.keyWord,
                    "min": this.min,
                    "max": this.max,
                })
                this.reset()
            }
            //console.log(this.Arrays)
        },
        pushKeyArray() {
            if (this.Arrays.length !== 0) {
                let length = this.keyWordGroups.length + 1
                this.keyWordGroups.push({
                    "id": length,
                    "Arrays": [],
                })
                for (let i = 0; i < this.Arrays.length; i++) {
                    this.keyWordGroups[length - 1].Arrays.push({
                        "keyWord": this.Arrays[i].keyWord,
                        "min": this.Arrays[i].min,
                        "max": this.Arrays[i].max,
                    })
                }
                this.Arrays.splice(0, this.Arrays.length)
                this.toast.success(`關鍵字加入成功`, this.options);
                if (this.$refs.keyWord.getAttribute('aria-expanded') == 'false')
                    this.keywordCollapse()
            }
        },
        keywordCollapse() {
            this.$refs.keyWord.click()
        },
        reset() {
            this.keyWord = ''
            this.min = 1
            this.max = 99
        },
        maxMinSwap() {
            if (this.min >= this.max) {
                let minMax = this.max
                this.max = this.min
                this.min = minMax
            }
        },
        editRegisterArray(event) {
            this.Arrays[event.index].keyWord = event.newKeyWord
            this.Arrays[event.index].min = event.newMin
            this.Arrays[event.index].max = event.newMax
            //console.log(this.Arrays)
        },
        deleteRegisterArray(event) {
            this.Arrays.splice(event.index, 1)
        },
        addKeyWord(event) {
            this.keyWordGroups[event.indexGroup].Arrays.push({
                "keyWord": event.addKeyWord,
                "min": event.addMin,
                "max": event.addMax,
            })
            this.toast.success(`關鍵字新增成功`, this.options);
            //console.log(this.keyWordGroups)
        },
        editKeyWord(event) {
            this.keyWordGroups[event.indexGroup].Arrays[event.indexArray].keyWord = event.newKeyWord
            this.keyWordGroups[event.indexGroup].Arrays[event.indexArray].min = event.newMin
            this.keyWordGroups[event.indexGroup].Arrays[event.indexArray].max = event.newMax
            this.toast.success(`關鍵字修改成功`, this.options);
            //console.log(this.keyWordGroups)
        },
        deleteKeyWord(event) {
            this.keyWordGroups[event.indexGroup].Arrays.splice(event.indexArray, 1)

            if (this.keyWordGroups[event.indexGroup].Arrays.length == 0) {
                this.keyWordGroups.splice(event.indexGroup, 1)
                for (let i = 0; i < this.keyWordGroups.length; i++)
                    this.keyWordGroups[i].id = (i + 1)
            }

            this.toast.success(`關鍵字刪除成功`, this.options);
            //console.log(this.keyWordGroups)
        },
        readFile: function (event, fileName) {
            this.allFiles[fileName] = []
            let self = this
            let files = event.target.files
            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    let reader = new FileReader();
                    if (/text\/\w+/.test(file.type) || file.type === "") {
                        reader.onerror = function () {
                            console.log("Error")
                        }
                        reader.onload = function () {
                            self.allFiles[fileName].push({
                                name: files[i].name,
                                content: self.Base64.encode(reader.result)
                            })
                        }
                        reader.readAsText(file)
                    }
                }
            }
        },
        createProgram() {
            if (this.mode == "add") {
                // 標題填寫
                this.warningStatus.title = (this.title != '') ? false : true
                // 標題填寫
                this.warningStatus.content = (this.content != '') ? false : true
                // 語言填寫
                this.warningStatus.language = (this.language != '') ? false : true
                // 整合條件
                let allWarning = (!this.warningStatus.title && !this.warningStatus.content && !this.warningStatus.language) ? true : false
                // 判斷版本號正確與否
                if (allWarning)
                    allWarning = (this.version == '') ? true : (this.version.match(/^([0-9]+)(\.?)([0-9]*)$/)) ? true : false

                if (allWarning) {
                    let keyword = this.serverRebuildKeyword(this.keyWordGroups)
                    axios.post(`/api/program/${this.$route.params.courseId}/createProgram`, {
                        sort: this.sort,
                        title: this.title,
                        content: this.Base64.encode(this.content),
                        keyword: keyword,
                        version: this.version,
                        language: this.language,
                        allFiles: this.allFiles,
                        view: this.viewBoolean,
                        download: this.downloadBoolean
                    }).then((res) => {
                        if (res.data.success) {
                            this.alertModal = {
                                show: true,
                                state: "normal",
                                title: "創建成功",
                                bodyMsg: "您已創建成功，將返回課程頁面",
                                crossShow: false,
                                confirmEvent: function () {
                                    this.$router.push(`/course/${this.$route.params.courseId}`)
                                }
                            }
                        } else {
                            if (res.data.errMsg === "duplicate conflict") {
                                this.alertModal = {
                                    show: true,
                                    state: "danger",
                                    title: "創建失敗",
                                    bodyMsg: "已存在同樣版本號的題目，請重新創建",
                                    crossShow: false,
                                }
                            } else {
                                this.alertModal = {
                                    show: true,
                                    state: "danger",
                                    title: "創建失敗",
                                    bodyMsg: "本創建失敗，請重新創建",
                                    crossShow: false,
                                }
                            }

                        }
                    })
                } else {
                    this.toast.error(`考題創建失敗，請檢查是否有缺漏`, this.options);
                }
            }
        },
        cancelCreate() {
            this.title = ""
            this.sort = ""
            this.version = ""
            this.content = ``
            this.language = ""
            this.keyWord = ""
            this.min = 1
            this.max = 99
            this.Arrays = []
            this.keyWordGroups = []
            this.allFiles = {defaultFile: [], inputFile: [], answerFile: []}
            this.viewBoolean = 0
            this.downloadBoolean = 0
            this.warningStatus = {
                title: false,
                content: false,
                language: false
            }
        },
        async downloadCode(source_type){
            const code_data = JSON.parse(this.code_detail[source_type])
            for (let i = 0 ; i < code_data.length;i++){
                await axios.post(`/api/program/${this.$route.params.programId}/edit/code/download/${code_data[i]}`,
                    {courseId:this.$route.params.courseId}).then(result=>{
                    const response = result.data
                    if(response.success){
                        const blobData = new Blob([Base64.decode(response.code.content)],{type:"text/plain"})
                        const fakeURL = window.URL.createObjectURL(new Blob([blobData]))
                        const fakeLink = document.createElement('a')
                        fakeLink.style.display = "none"
                        fakeLink.href = fakeURL
                        fakeLink.setAttribute("download",`${source_type}-${response.code.filename}`)
                        document.body.appendChild(fakeLink)
                        fakeLink.click()
                        document.body.removeChild(fakeLink)
                        URL.revokeObjectURL(fakeURL)
                    }

                })
            }

        },
        checkLoading() {
            console.log(123)
        }
    },
}

</script>

<style scoped>
form {
    text-align: center;
    margin: auto;
    padding: 20px;
}

::-webkit-scrollbar {
    display: none;
}


button {
    width: fit-content;
}

.inputWidth {
    width: 80px;
    overflow-x: scroll;
    overflow-y: auto;
    text-overflow: clip;
    margin: 0 auto;
}

label {
    text-align: left;
}

a {
    text-decoration: none;
}
</style>