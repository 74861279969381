<template>
  <button class="btn p-1" :disabled="modelValue.disable || stdSelectDisable">
  <div class="card seat-style m-1" @click="modalCtrl=true" :class="{'has-student':this.modelValue.stdData!=null}">
    <div class="container-fluid px-2">
      <div v-if="!modelValue.disable">
        <div class="row justify-content-center">
          <p class="mt-2 mb-1 card-title-size">{{ seatName }}</p>
          <hr class="my-0">
          <p class="mt-1 mb-1 ">{{ modelValue.seatIp }}</p>
          <hr class="my-0">
          <p class="mt-1 mb-2" v-if="modelValue.stdData != undefined">{{ modelValue.stdData.stdName}}</p>
          <p class="mt-1 mb-2" v-else>{{ "Not Set "}}</p>
        </div>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <p class="mt-2 mb-1">{{ seatName }}</p>
          <hr class="my-0">
          <p class="mt-1 mb-1">Disabled Seat</p>
          <hr class="my-0">
          <p class="mt-1 mb-2">Disabled Seat</p>
        </div>
      </div>
    </div>
  </div>
  </button>
  <div v-if="!modelValue.disable">
    <std-display-modal :showModal="modalCtrl" @close="modalCtrl=false" @emitSend="emitSetStdData" :prop-selected-user="this.modelValue.stdData">
      <template #title>{{ seatName }}</template>
    </std-display-modal>
  </div>

</template>

<script>
import StdDisplayModal from "./stdDisplayModal.vue";
export default {
  name: "studentTable",
  components: {StdDisplayModal},
  props: {
    stdSelectDisable:{},
    modelValue:{}
  },
  data() {
    return {
      modalCtrl : false,
    }
  },
  watch:{
    stdSeatData:{
      deep:true,
    }
  }
  ,
  computed:{
    // eslint-disable-next-line vue/no-dupe-keys
    seatName:function () {
      return `${this.modelValue.seatRow}-${this.modelValue.seatCol}`
    }
  },
  methods:{
    emitSetStdData:function (stdInfo){
      console.log(stdInfo)
      let newValue = this.modelValue
      newValue.stdData = stdInfo
      this.$emit('update:modelValue',newValue)
    }

  }

}
</script>

<style scoped>
.seat-style
{
  width: 110px;
  overflow-x: hidden;
}
p
{
  font-size:10px;
}
.card-title-size
{
  font-size: 15px;
  font-weight: bold;
}

.card
{
  background-color: inherit;
}

.has-student{
  background-color: rgba(241, 176, 75, 0.5);
}

</style>
