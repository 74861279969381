<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header class="modal-header">
          <span v-if="state == 'change'" class="fw-bold">修改密碼</span>
          <span v-if="state == 'delete'" class="text-danger fw-bold">刪除帳號</span>

          <button v-if="doubleCheckMessage != '已修改密碼，即將跳轉回個人資訊頁面'" type="button" class="btn-close"
                  @click="close"></button>
        </header>

        <section class="modal-body">
          <div class="form-group row">
            <label v-if="state == 'change'" for="inputInitialPassword"
                   class="col-sm-2 col-form-label fw-bold">初始密碼</label>
            <label v-if="state == 'delete'" for="inputInitialPassword"
                   class="col-sm-2 col-form-label fw-bold">輸入密碼</label>
            <div class="col-sm-10">
              <input type="password" class="form-control" id="inputInitialPassword" v-model="password"
                     @change="this.doubleCheck = false">
            </div>
          </div>

          <div v-if="passwordError" class="row mb-sm-1 text-start">
            <small class="col-sm-2--warn"></small>
            <small class="col-sm-10--warn text-danger">密碼輸入錯誤，請重新輸入</small>
          </div>

          <div v-if="state == 'change'">
            <div class="form-group row">
              <label for="inputEditPassword" class="col-sm-2 col-form-label fw-bold">修改密碼</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="inputEditPassword" v-model="editPassword"
                       @change="this.doubleCheck = false">
              </div>
            </div>

            <div class="form-group row">
              <label for="inputCheckPassword" class="col-sm-2 col-form-label fw-bold">確定密碼</label>
              <div class="col-sm-10">
                <input type="password" class="form-control" id="inputCheckPassword" v-model="checkPassword"
                       @change="this.doubleCheck = false">
              </div>
            </div>

            <div v-if="checkPasswordError" class="row mb-sm-1 text-start">
              <small class="col-sm-2--warn"></small>
              <small class="col-sm-10--warn text-danger">密碼輸入不一致，請重新輸入</small>
            </div>

            <div v-if="notNull" class="row mb-sm-1 text-start">
              <small class="col-sm-2--warn"></small>
              <small class="col-sm-10--warn text-danger">不可為空，請輸入數值或者返回</small>
            </div>
          </div>
        </section>


        <footer class="modal-footer">
          <div v-if="doubleCheck" class="row mb-sm-1 text-start">
            <small :class="(doubleCheckMessage == '已修改密碼，即將跳轉回個人資訊頁面') ? 'text-success' : 'text-danger'">
              {{ doubleCheckMessage }}
            </small>
          </div>

          <div v-if="doubleCheckMessage != '已修改密碼，即將跳轉回個人資訊頁面'">
            <button v-if="state == 'change'" type="button" class="btn btn-outline-primary me-1" @click="changePassword">
              <i class="fas fa-check-circle"></i>
            </button>

            <button v-if="state == 'delete'" type="button" class="btn btn-outline-danger me-1" @click="deleteAccount">
              <i class="fas fa-trash-alt"></i>
            </button>

            <button type="button"
                    :class="['btn me-1 ',(state != 'delete') ? 'btn-outline-danger' : 'btn-outline-secondary']"
                    @click="close">
              <i class="fas fa-times-circle"></i>
            </button>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Modal",
  props: {
    state: {},
  },
  data() {
    return {
      password: "",
      passwordError: false,
      editPassword: "",
      checkPassword: "",
      checkPasswordError: false,
      notNull: false,
      doubleCheck: false,
      doubleCheckMessage: "",
    }
  },
  methods: {
    close() {
      this.password = ""
      this.passwordError = false
      this.editPassword = ""
      this.checkPassword = ""
      this.checkPasswordError = false
      this.notNull = false
      this.doubleCheck = false
      this.doubleCheckMessage = ""
      this.$emit('close');
    },
    changePassword() {
      const sleep = new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });

      if (this.password == "" || this.editPassword == "" || this.checkPassword == "")
        this.notNull = true
      else {
        if (this.password != "" && this.editPassword != "" && this.checkPassword != "") this.notNull = false
        if (this.editPassword == this.checkPassword) {
          this.checkPasswordError = false
          axios.post("/api/user/check/Password", {password: this.password}).then((res) => {
            if (res.data.success) {
              this.passwordError = false
              if (this.doubleCheck) {
                this.doubleCheckMessage = "已修改密碼，即將跳轉回個人資訊頁面"
                sleep.then((value) => {
                  if (value) {
                    axios.post("/api/user/change/Password", {password: this.password, editPassword: this.editPassword})
                    this.close()
                  }
                })
              } else {
                this.doubleCheck = true
                this.doubleCheckMessage = "即將修改密碼，是否確定修改"
              }
            } else
              this.passwordError = true
          })
        } else
          this.checkPasswordError = true
      }
    },
    deleteAccount() {
      const sleep = new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });

      if (this.password != "") {
        this.notNull = false
        axios.post("/api/user/check/Password", {password: this.password}).then((res) => {
          if (res.data.success) {
            this.passwordError = false
            if (this.doubleCheck) {
              this.doubleCheckMessage = "刪除成功"
              sleep.then((value) => {
                if (value)
                  axios.post("/api/user/delete/account", {password: this.password}).then((resDelete) => {
                    this.close()
                    if (resDelete.data.success) {
                      localStorage.clear()
                      location.href = "/api/logout"
                    }
                  })
              })
            } else {
              this.doubleCheck = true
              this.doubleCheckMessage = "確定要刪除帳號?刪除後將永久消失"
            }
          } else
            this.passwordError = true
        })
      } else this.notNull = true
    },
  }
}

</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.form-group {
  margin: 5px 0;
}


label {
  text-align: left;
}

.col-sm-2 {
  width: 16%;
}

.col-sm-10 {
  padding: 0;
  width: 84%;
}

.col-sm-2--warn {
  width: 16%;
}

.col-sm-10--warn {
  width: 84%;
}
</style>