<template>
  <div v-if="uploadButtonShow && produce.content.length > 0" class="form--div">
    <div class="row">
      <label class="fw-bold px-0" style="font-size: 24px">完整試卷：{{ produce.title }}</label>
    </div>
    <div class="row" style="font-size: 20px">
      <div class="fw-bold px-0">
        <button type="button" class="btn btn-outline-dark me-2" @click="changeClickRightAnswer">
          <i class="fas fa-cog me-1"></i>修改設定
        </button>
        <button type="button" class="btn btn-outline-dark me-2" @click="this.$router.push(`./review`)">
          <i class="fas fa-pen-square me-1"></i>批閱試卷
        </button>
        <button type="button" class="btn btn-outline-dark me-2" @click="this.$router.push(`./show`)">
          <i class="fas fa-eye me-1"></i>查閱試卷
        </button>
      </div>
    </div>
    <div class="mb-1" v-for="(produceData,produceIndex) in produce.content" :key="produceIndex">
      <div @click="show(produceData.type,'titleQuestion',produceIndex)" class="row fontSize-20 mb-0">
        <label class="col-auto px-0">{{ produceIndex + 1 }}.</label>
        <label class="col px-1" style="text-align:justify;text-justify:inter-word;">{{
            (produceData.question.includes('%s')) ? produceData.question.replace(/%s/g, "[?]") : produceData.question
          }}</label>

      </div>
      <div @click="show(produceData.type,'titleQuestion',produceIndex)" class="mb-2">
        <label class="col-auto text-success">此題分數({{ produceData.point }}分)</label>
        <label class="col-auto px-1"><i class="fas fa-caret-down"></i></label>
      </div>
      <button :ref="'titleQuestion' + produceIndex" class="d-none" type="button" data-bs-toggle="collapse"
              :data-bs-target="'#titleQuestion' + produceIndex" aria-expanded="false"
              :aria-controls="'titleQuestion' + produceIndex"></button>

      <div class="collapse p-0" :id="'titleQuestion' + produceIndex">
        <div class="border border-dark shadow--">
          <div class="row my-1">
            <button v-for="(data,index) in produceData.answer" :key="index"
                    :ref="'titleQuestion' + produceIndex + 'button'" type="button"
                    @click="check(produceData.type,'titleQuestion' + produceIndex + 'question' ,produceData.answer.length)"
                    data-bs-toggle="collapse" :data-bs-target="'#titleQuestion' + produceIndex + 'question' + index"
                    aria-expanded="false" :aria-controls="'titleQuestion' + produceIndex + 'question' + index"
                    :class="['btn btn-outline-primary col text-truncate m-1',
                    (index==0)?'ms-0':'',(index==(produceData.answer.length-1))?'me-0':'',
                    (produceData.question.includes('%s'))?'':'d-none']">
              {{ ('' + data.args) }}
            </button>
          </div>

          <div :id="'titleQuestion' + produceIndex + 'totalQuestion' + '0'">
            <div v-for="(data,index) in produceData.answer" :key="index" class="collapse"
                 :id="'titleQuestion' + produceIndex + 'question' + index"
                 :data-bs-parent="'#titleQuestion' + produceIndex + 'totalQuestion' + '0'">
              <dd class="mb-0">
                <dl class="row my-0">
                  <dt class="col-auto px-0" style="font-size: 18px;">{{ index + 1 }}.</dt>
                  <dd class="col" style="font-size: 18px;text-align:justify;text-justify:inter-word;">
                    {{ data.question }}
                  </dd>
                </dl>
              </dd>

              <div @click="showOther('titleQuestion' + produceIndex + 'question' + index + 'answer')">
                <label v-if="produceData.type != 'filling'">正確選項&nbsp;<i class="fas fa-caret-down"></i></label>
                <label v-if="produceData.type == 'filling'">正確答案&nbsp;<i class="fas fa-caret-down"></i></label>
              </div>
              <button class="d-none" type="button" data-bs-toggle="collapse"
                      :ref="'titleQuestion' + produceIndex + 'question' + index + 'answer'" aria-expanded="false"
                      :data-bs-target="'#titleQuestion' + produceIndex + 'question' + index + 'answer'"
                      :aria-controls="'#titleQuestion' + produceIndex + 'question' + index + 'answer'">
              </button>

              <div class="collapse p-0" :id="'titleQuestion' + produceIndex + 'question' + index + 'answer'">
                <div class="row my-1 ms-2" v-for="(answerData,answerIndex) in data.answer" :key="answerIndex">
                  <label class="form-check-label col-auto px-1"><i class="fas fa-circle"></i></label>
                  <label class="form-check-label col ps-0"
                         style="text-align:justify;text-justify:inter-word;">{{ answerData }}</label>
                </div>
              </div>

              <div v-if="produceData.type != 'filling'">
                <div @click="showOther('titleQuestion' + produceIndex + 'question' + index +'mixedOption')">
                  <label>錯誤選項&nbsp;<i class="fas fa-caret-down"></i></label>
                </div>
                <button class="d-none" type="button" data-bs-toggle="collapse"
                        :ref="'titleQuestion' + produceIndex + 'question' + index + 'mixedOption'" aria-expanded="false"
                        :data-bs-target="'#titleQuestion' + produceIndex + 'question' + index +'mixedOption'"
                        :aria-controls="'#titleQuestion' + produceIndex + 'question' + index +'mixedOption'">
                </button>

                <div class="collapse p-0" :id="'titleQuestion' + produceIndex + 'question' + index + 'mixedOption'">
                  <div class="row my-1 ms-2" v-for="(mixedOptionData,mixedOptionIndex) in produceData.mixed"
                       :key="mixedOptionIndex">
                    <label class="form-check-label col-auto px-1"><i class="fas fa-circle"></i></label>
                    <label class="form-check-label col ps-0"
                           style="text-align:justify;text-justify:inter-word;">{{ mixedOptionData }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!uploadButtonShow" class="container form--div mt-3" style="text-align: center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <alert-modal v-model="alertModal.show" :="alertModal" @show="showModel"></alert-modal>
</template>

<script>
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";
//const util = require("../../../BackEnd/util");

export default {
  name: "getTeacherPaper",
  components: {
    AlertModal
  },
  data() {
    this.getPaper()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return {
      alertModal,
      title: "title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title ",
      // titleIndex: 0,
      // question: ["question1,question2,question3", "question4", "question5", "question6", "question7,question8,question9", "question10,question11", "question12", "question13,question14,question15,question16"],
      // answer: ['answer1,answer2,answer3', 'answer4', 'answer5', 'answer6,answer7'],
      // mixedOption: ['option1,option2', 'option3', 'option4', 'option5,option6'],
      produce: {},
      uploadButtonShow: false,
      clickRightAnswer: false
    }
  },
  methods: {
    getPaper: async function () {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/teacherPaper`).then((res) => {
        //console.log(res.data)
        if (res.data.success) {
          this.alertModal = {
            show: false,
          }
          this.uploadButtonShow = true
          this.produce = res.data.produce
        }else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "請重新嘗試",
            confirmEvent: function () {
              location.href=`/course/${this.$route.params.courseId}/exam/${this.$route.params.examId}/showQuiz`
            }
          }
        }
      })
    },
    show(type, name, index) {
      //console.log(this.$refs[name + index])
      this.$refs[name + index][0].click()
      if (this.$refs[name + index + 'button'][0].getAttribute('aria-expanded') == 'false')
        this.$refs[name + index + 'button'][0].click()
      if (this.$refs[name + index + 'question0answer'][0].getAttribute('aria-expanded') == 'true')
        this.$refs[name + index + 'question0answer'][0].click()
      if (type != 'filling' && this.$refs[name + index + 'question0mixedOption'][0].getAttribute('aria-expanded') == 'true')
        this.$refs[name + index + 'question0mixedOption'][0].click()
    },
    showOther(name) {
      //console.log(this.$refs[name])
      this.$refs[name][0].click()
    },
    check(type, name, length) {
      for (let i = 0; i < length; i++)
        if (this.clickRightAnswer) {
          if (this.$refs[name + i + 'answer'][0].getAttribute('aria-expanded') == 'false')
            this.$refs[name + i + 'answer'][0].click()
          if (type != 'filling' && this.$refs[name + i + 'mixedOption'][0].getAttribute('aria-expanded') == 'false')
            this.$refs[name + i + 'mixedOption'][0].click()
        } else {
          if (this.$refs[name + i + 'answer'][0].getAttribute('aria-expanded') == 'true')
            this.$refs[name + i + 'answer'][0].click()
          if (type != 'filling' && this.$refs[name + i + 'mixedOption'][0].getAttribute('aria-expanded') == 'true')
            this.$refs[name + i + 'mixedOption'][0].click()
        }
    },
    showModel(event) {
      this.uploadButtonShow = true
      this.clickRightAnswer = event.check
    },
    changeClickRightAnswer() {
      let htmlCode = ``
      if (this.clickRightAnswer)
        htmlCode = `<div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="clickRightAnswer" checked>
                        <label class="form-check-label" for="clickRightAnswer">是否自動展開答案選項?</label>
                    </div>`
      else
        htmlCode = `<div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="clickRightAnswer">
                        <label class="form-check-label" for="clickRightAnswer">是否自動展開答案選項?</label>
                   </div>`

      this.alertModal = {
        show: true,
        state: "normal",
        title: "更改設定",
        bodyHtml: htmlCode,
        bodyStyle: "width:40vw;text-align: left;",
        crossShow: false,
        confirmEvent: function () {
          this.$emit("show", {check: document.getElementById("clickRightAnswer").checked})
        }
      }
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.fontSize-20 {
  font-size: 20px;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}

</style>