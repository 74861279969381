import Home from "../../components/Home.vue";
import Login from "../../components/account/Login.vue";
import Recover from "../../components/account/ForgetPassword.vue";
import Active from "../../components/account/Active.vue";
import PasswordActive from "../../components/account/PasswordActive.vue";
import Register from "../../components/account/Register.vue";


export default [
    // 首頁 V
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {}
    },
    // 登入頁面 V
    {
        path: '/login',
        name: 'UserLogin',
        component: Login,
        meta: {bypassIfLogin: true}
    },
    // 申請回復密碼
    {
        path: '/recover',
        name: 'UserRecover',
        component: Recover,
    },
    // 啟用連結
    {
        path: '/active:token?',
        name: 'Active',
        component: Active,
    },
    // 忘記密碼連結
    {
        path: '/resetPassword:token?',
        name: 'PasswordActive',
        component: PasswordActive,
    },
    // 註冊帳號連結
    {
        path: '/register',
        name: 'UserRegister',
        component: Register,
    },
]