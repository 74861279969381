<template>
  <div class="form--div">
      <div class="form-group">
        <label class="col-form-label fw-bold --key">搜尋條件
          <button type="button" class="btn" data-bs-toggle="collapse" data-bs-target="#collapseOfSearch">
            <i class="fas fa-search --key"></i>
          </button>
        </label>
      </div>
  </div>
  <div id="collapseOfSearch" class="form--div border border-dark collapse">
    <form>


      <div class="form-group">
        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label" id="staticEmail" v-model="stdMail"
                 :placeholder="'A8250351@g.pccu.edu.tw'">
          <label for="staticEmail" class="fw-bold ms-2">信箱</label>
        </div>

        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label" id="inputStdId" v-model="stdId"
                 :placeholder="'A8222820'">
          <label for="inputStdId" class="fw-bold ms-2">學號</label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label" id="inputSchool" v-model="school"
                 :placeholder="'中國文化大學'" list="schoolOptions">
          <datalist id="schoolOptions">
            <option v-for="(data,index) in school_List" :key="index" :value="data"></option>
          </datalist>
          <label for="inputSchool" class="fw-bold ms-2">學校</label>
        </div>

        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label" id="inputDepartment" v-model="department"
                 :placeholder="'資工系'" list="departmentOptions">
          <datalist id="departmentOptions">
            <option v-for="(data,index) in department_List" :key="index" :value="data"></option>
          </datalist>
          <label for="inputDepartment" class="fw-bold ms-2">科系</label>
        </div>
      </div>

      <div class="form-group">
        <div class="col-sm-6 form-floating">
          <input type="text" class="form-control input--label" id="inputAccount" v-model="account"
                 :placeholder="'帳號'">
          <label for="inputSchool" class="fw-bold ms-2">帳號</label>
        </div>

        <div class="col-sm-3 form-floating">
          <input type="text" class="form-control input--label" id="inputName" v-model="name"
                 :placeholder="'姓名'">
          <label for="inputName" class="fw-bold ms-2">姓名</label>
        </div>

        <div class="col-sm-3 form-floating">
          <select id="role" class="form-select select--role input--label" v-model="role">
            <option value="" :selected="true" disabled></option>
            <option v-for="(data,index) in roleArray" :key="index" :value="data">{{ data }}</option>
          </select>
          <label for="role" class="fw-bold ms-2">角色</label>
        </div>
      </div>

      <div>
        <button type="button" class="btn btn-outline-primary me-3" @click="search">
          <i class="fas fa-search"></i>
        </button>
        <button type="button" class="btn btn-outline-danger me-3" @click="deleteAllSearch">
          <i class="far fa-trash-alt"></i>
        </button>
      </div>

    </form>
  </div>
  <searchResults :sqlAccountInformation="sqlAccountInformation"
                 @delete-account="deleteAccount" @updateSearch="updateSearch"/>
</template>

<script>
import searchResults from "./accountSearchResults.vue"
import axios from "axios";


export default {
  name: "searchCriteria",
  components:{
    searchResults
  },
  created() {
    this.search()
    this.getSchoolAndDepartmentList()
  },
  data() {
    return {
      stdMail: "",
      stdId: "",
      school: "",
      department: "",
      school_List:[],
      department_List: [],
      account: "",
      name:"",
      role: "",
      roleArray: ['Guest', 'Student', 'Teacher', 'Admin'],
      sqlAccountInformation:{}
    }
  },
  methods: {
    search() {
      if (this.stdMail != "" || this.stdId != "" || this.school != "" ||
          this.department != "" || this.account != "" || this.lastName != "" ||
          this.firstName != "" || this.role != "") {
        axios.post("/api/user/search", {
          std_mail: this.stdMail,
          std_id: this.stdId,
          school: this.school,
          department: this.department,
          account: this.account,
          name: this.name,
          role: this.role,
        }).then((res) => {
          this.sqlAccountInformation = res.data
        })
      }
    },
    getSchoolAndDepartmentList(){
      axios.get('/api/info/get/schoolAndDepartmentList').then((res) => {
        let sql_school_list = []
        res["data"].forEach((school) => {
          sql_school_list.push(school.school)
        })
        sql_school_list = sql_school_list.filter((ele, pos) => sql_school_list.indexOf(ele) == pos);
        this.school_List = sql_school_list

        let sql_department_list = []
        res["data"].forEach((dep) => {
          sql_department_list.push(dep["department"])
        })
        sql_department_list = sql_department_list.filter((ele, pos) => sql_department_list.indexOf(ele) == pos);
        this.department_List = sql_department_list
      })
    },
    deleteAllSearch() {
      this.stdMail = ""
      this.stdId = ""
      this.school = ""
      this.department = ""
      this.account = ""
      this.name = ""
      this.role = ""
    },
    deleteAccount(event){
      this.sqlAccountInformation.splice(event.index,1)
    },
    updateSearch(){
      this.$emit("updateSearch")
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 90%;
  margin: 20px auto;
}

.--key {
  font-size: 20px;
}

form {
  margin: 10px 0;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}

label {
  text-align: left;
}

.input--label {
  margin: auto;
  width: 97%;
}
#inputName{
  width: 94%;
}

#role{
  width: 94%;
}

.select--role {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

</style>