<template>
  <button v-show="isShow" type="button" class="btn btn-default btn-circle go-to-top border-dark" @click="topFunction">
    <i class="fa fa-chevron-up"></i>
  </button>
</template>

<script>
export default {
  name: "toTopButton",
  data(){
    return{
      isShow:false
    }
  },
  mounted() {
    document.addEventListener('scroll', this.scrollFunction)
  },
  unmounted () {
    window.removeEventListener('scroll', this.scrollFunction);
  },
  methods: {
    scrollFunction() {
      this.isShow = (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20)?true:false
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
}
</script>

<style scoped>
.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

.go-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
}
</style>