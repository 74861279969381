<template>
  <navbar></navbar>
  <div v-show="loadCourseShow && loadStudentShow" class="container">
    <div class="row">
      <h2 class="text-start"><strong>課程設定</strong></h2>
    </div>
    <course-panel @loadCoursePanel="checkLoad"></course-panel>
    <hr class="row my-2">
    <student-panel @loadStudentPanel="checkLoad"></student-panel>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import CoursePanel from "./coursePanel.vue";
import StudentPanel from "./studentPanel.vue";
import navbar from "../../../module/navbar";
import AlertModal from "../../../module/Modal/alertModal.vue";

export default {
  name: "index",
  components: {StudentPanel, CoursePanel, navbar, AlertModal},
  data(){
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    return{
      alertModal,
      loadCourseShow: false,
      loadStudentShow:false,
    }
  },
  methods:{
    checkLoad(name){
      this[name] = true
      if(this.loadCourseShow && this.loadStudentShow){
        this.alertModal = {
          show: false,
        }
      }
    }
  }
}
</script>

<style scoped>

</style>