<template>
  <navbar hide-bread-crumb="true"></navbar>
  <h1>:( [403] </h1>
  <h2 class="text-danger">Forbidden</h2>
  <h2>Server reject your request</h2>
  <button class="btn btn-primary mx-3" @click="$router.go(-1)">回上一頁</button>
</template>

<script>
import Navbar from "@/components/module/navbar";

export default {
  name: "page401",
  components: {Navbar}
}
</script>

<style scoped>
h1{
  font-size: 200px;
  font-weight: bolder;
  text-align: center;
}
h2{
  font-size: 100px;
  font-weight: bolder;
  text-align: center;
}
</style>