<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong class="me-auto">Bootstrap</strong>
      <small>11 mins ago</small>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
      Hello, world! This is a toast message.
    </div>
  </div>

</template>

<script>

export default {
  name: "lyt Test2",
  data(){

  },
  methods:{
    change:function (){
      // eslint-disable-next-line no-undef

    }
  }
}
</script>

<style scoped>

</style>