<template>
  <div class="form--div border border-dark shadow--">
    <form>
      <div class="form-group row">
        <label class="col-sm-10 col-form-label fw-bold --key">個人資訊</label>
      </div>
      <div class="row mb-2">
        <div class="col-5 row px-4 my-auto">
          <div class="col-8">
            <div v-if="image == ''" class="spinner-border" role="status"
                 style="height: 180px;width: 180px;background-size: contain;">
              <span class="visually-hidden">Loading...</span>
            </div>
            <img v-if="image != ''" :src="image" alt="mdo" class="float-start img-fluid img-thumbnail">
          </div>
          <div class="col-4 d-flex align-items-end px-0">
            <button :class="['btn btn-outline-secondary border align-items-end',(showImageButton)?'d-none':'']"
                    type="button" @click="$refs.file.click()"><i class="fas fa-edit fa-fw"></i></button>
            <button :class="['btn btn-outline-secondary border align-items-end',(showImageButton)?'':'d-none']"
                    type="button" @click="sendImage"><i class="fas fa-check fa-fw"></i></button>
            <button :class="['btn btn-outline-secondary border align-items-end',(showImageButton)?'':'d-none']"
                    type="button" @click="notModify"><i class="fas fa-times fa-fw"></i></button>

            <input ref="file" type="file" class="d-none" name="fileImg" accept="image/jpeg, image/png, image/jpg"
                   @change="changeImage">
          </div>
        </div>
        <div class="col-7">
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-4 col-form-label fw-bold">信箱</label>
            <div class="col-sm-8">
              <input type="text" readonly class="form-control readonly" id="staticEmail" :value="mail">
            </div>
          </div>

          <div class="form-group row">
            <label for="inputStdId" class="col-sm-4 col-form-label fw-bold">學號</label>
            <div class="col-sm-8">
              <input type="text" readonly class="form-control readonly" id="inputStdId" :value="stdID">
            </div>
          </div>

          <div class="form-group row">
            <label for="inputSchool" class="col-sm-4 col-form-label fw-bold">學校科系</label>
            <div class="col-sm-8">
              <input type="text" readonly class="form-control readonly" id="inputSchool"
                     :value="school + ' ' + department">
            </div>
          </div>

          <div class="form-group row">
            <label for="inputYear" class="col-sm-4 col-form-label fw-bold">學年度</label>
            <div class="col-sm-8">
              <input type="text" readonly class="form-control readonly" id="inputYear"
                     :value="academic_year + term">
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row my-auto">
        <label class="col-sm-2 col-form-label fw-bold">帳號</label>
        <div class="col-sm-10 input-group">
          <input v-if="editAccount" type="text" class="form-control" :placeholder="account" v-model="newAccount">
          <input v-if="!editAccount" type="text" class="form-control readonly-with-border" :value="account" readonly>
          <span class="input-group-append">
                <button v-if="!editAccount" class="btn btn-outline-secondary border" type="button"
                        @click="editAccount=true">
                    <i class="fas fa-edit"></i>
                </button>

                <button v-if="editAccount" class="btn btn-outline-secondary border" type="button"
                        @click="changeAccount">
                    <i class="fas fa-check"></i>
                </button>
          </span>
        </div>
      </div>

      <div v-if="accountError" class="row mb-sm-1 text-start">
        <small class="col-sm-5"></small>
        <small class="col ms-3 text-danger">帳號重複，請重新輸入</small>
      </div>

      <div class="form-group row row-cols-3">
        <label class="col-sm-2 col-form-label fw-bold">姓名</label>
        <div class="col-sm-10 input-group">
          <input v-if="editName" type="text" class="form-control" v-model="newName"
                 :placeholder="username">
          <input v-if="!editName" type="text" class="form-control readonly-with-border"
                 :value="username" readonly>
          <span class="input-group-append">
                <button v-if="!editName" class="btn btn-outline-secondary border" type="button" @click="editName=true">
                    <i class="fas fa-edit"></i>
                </button>

                <button v-if="editName" class="btn btn-outline-secondary border" type="button"
                        @click="changeName">
                    <i class="fas fa-check"></i>
                </button>
          </span>
        </div>

      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label fw-bold">電話</label>
        <div class="col-sm-10 input-group">
          <input v-if="editPhone" type="text" class="form-control" :placeholder="phone" v-model="newPhone"
                 pattern="^09[0-9]{8}|^\+886[0-9]{9}">
          <input v-if="!editPhone" type="text" class="form-control readonly-with-border" :value="phone"
                 pattern="^09[0-9]{8}|^\+886[0-9]{9}" readonly>
          <span class="input-group-append">
                <button v-if="!editPhone" class="btn btn-outline-secondary border" type="button"
                        @click="editPhone=true">
                    <i class="fas fa-edit"></i>
                </button>

                <button v-if="editPhone" class="btn btn-outline-secondary border" type="button"
                        @click="changePhone">
                    <i class="fas fa-check"></i>
                </button>
          </span>
        </div>
      </div>

      <div class="form-group row password--div">
        <label class="col-sm-2 col-form-label fw-bold">密碼</label>
        <div class="col-sm-10">
          <a href="#" class="fw-bold" @click="isModalVisible = !isModalVisible;state = 'change'">更改密碼</a>
        </div>
      </div>

      <!--
      <hr>
      <div class="form-group row password--div">
        <label class="col-sm-2 col-form-label text-danger fw-bold" style="font-size: 20px">刪除帳號</label>
        <div class="col-sm-10">
          <button class="btn btn-outline-danger border fw-bold" type="button"
                  @click="isModalVisible = !isModalVisible;state = 'delete'">
            刪除帳號
          </button>
        </div>
      </div>
      -->

      <div>
        <modal v-show="isModalVisible" :state="state"
               @close="closeChangePasswordModal"></modal>
      </div>

      <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
    </form>
  </div>
</template>

<script>
import modal from "./Modal.vue"
import AlertModal from "../module/Modal/alertModal.vue";
import axios from "axios";

export default {
  name: "information",
  components: {
    modal, AlertModal
  },
  data() {
    this.getInformation()
    this.getImage()
    const alertModal = {
      show: false
    }
    return {
      alertModal,
      mail: "",
      stdID: "",
      academic_year: "",
      term: "",
      school: "",
      department: "",
      username: "",
      account: "",
      phone: "",
      isModalVisible: false,
      state: "",
      newAccount: "",
      newName: "",
      newPhone: "",
      changeError: false,
      changeErrorMessage: "",
      editAccount: false,
      editName: false,
      editPhone: false,
      accountError: false,
      originalImage:"",
      image: "",
      files: null,
      showImageButton: false,
    }
  },
  methods: {
    getInformation() {
      axios.get('/api/user/get/personalInformation/').then((res) => {
        this.mail = res.data.std_mail
        this.stdID = res.data.std_id
        this.academic_year = res.data.academic_year
        this.term = res.data.term
        this.school = res.data.school
        this.department = res.data.department
        this.username = res.data.name
        this.account = res.data.account
        this.phone = res.data.phone_number
      })
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeChangePasswordModal() {
      this.isModalVisible = false;
      location.href = "/personalInformation"
    },
    changePersonalInformation() {
      if (this.newName != "" || this.newPhone != "" || this.newAccount != ""
          && this.editAccount == false && this.editName == false && this.editPhone == false) {

        if (this.newName == "") this.newName = this.username
        if (this.newPhone == "") this.newPhone = this.phone
        if (this.newAccount == "" && this.editAccount == false) this.newAccount = this.account
        console.log(this.newAccount, this.newName, this.newPhone)
        axios.post("/api/user/change/personalInformation", {
          account: this.newAccount,
          name: this.newName,
          phone_number: this.newPhone
        })
      }
    },
    changeName() {
      if (this.newName != "") {
        this.username = this.newName
        this.changePersonalInformation()
        this.newName = ""
      }
      this.editName = false
    },
    changePhone() {
      if (this.newPhone != "") {
        this.phone = this.newPhone
        this.changePersonalInformation()
        this.newPhone = ""
      }
      this.editPhone = false
    },
    changeAccount() {
      if (this.newAccount != "") {
        axios.post(`/api/user/personalInformation/`, {account: this.newAccount}).then((res) => {
          if (res.data) {
            this.accountError = true
          } else {
            this.accountError = false
            this.account = this.newAccount
            this.editAccount = false
            this.changePersonalInformation()
            this.newAccount = ""
          }
        })
      } else this.editAccount = false
    },
    getImage() {
      axios.post(`/api/user/image`).then((res) => {
        if (res.data.success)
          this.image = '/api/img/' + res.data.path || ''
        else
          this.image = ''

        this.originalImage = this.image
      })
    },
    sendImage: function () {
      let formData = new FormData();
      formData.append('fileImg', this.files)

      this.showImageButton = false
      if (this.files.size < 1000000) {
        axios.post(`/api/user/upload/image`, formData).then((res) => {
          console.log('file:', res.data)
          if (res.data.success) {
            this.img = '/api/img/' + res.data.file.filename
            this.alertModal = {
              show: true,
              state: "normal",
              title: "上傳成功",
              bodyMsg: "您已上傳成功，將重新整理",
              crossShow: false,
              confirmEvent: function () {
                location.href = "/personalInformation"
              }
            }
            this.message = '上傳成功'
          } else {
            this.alertModal = {
              show: true,
              state: "danger",
              title: "上傳失敗",
              bodyMsg: "本次上傳失敗，請重新上傳",
              crossShow: false,
            }
          }
        })
      } else {
        this.alertModal = {
          show: true,
          state: "danger",
          title: "上傳失敗",
          bodyMsg: "因為檔案過大上傳失敗，請重新上傳",
          crossShow: false,
        }
      }
    },
    changeImage(event) {
      console.log(event.target.files)
      this.image = URL.createObjectURL(event.target.files[0]);
      this.files = event.target.files[0]
      event.target.value = ""
      this.showImageButton = true
    },
    notModify(){
      this.image = this.originalImage
      this.showImageButton = false
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
}

.--key {
  font-size: 26px
}

form {
  margin: 10px 0;
}

.form-group {
  margin: 5px 0;
}

label {
  text-align: left;
}

.input-group {
  width: 83%;
}

.password--div {
  text-align: left;
  align-items: center;
}

.input-group-append {
  margin: auto;
  display: flex;
  align-items: center;
}

.readonly {
  background: #ffffff;
  border: 0px;
}

.readonly-with-border {
  background: #ffffff;
}

.readonly:focus {
  box-shadow: none;
}

.readonly-with-border:focus {
  border: 1px solid #ced4da;
  box-shadow: none;
}

img {
  max-height: 200px;
}

.shadow-- {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

</style>