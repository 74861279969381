<template>
  <headerNavbar/>
  <studentCodeRecord v-if="mode == 'student'"/>
  <showCodeRecord v-if="mode=='show'"/>
</template>

<script>
import headerNavbar from "../../../module/navbar/header-navbar.vue";
import studentCodeRecord from "./studentCodeRecord.vue";
import showCodeRecord from "./showCodeRecord.vue";

export default {
  name: "index",
  components: {
    headerNavbar, studentCodeRecord, showCodeRecord
  },
  props: {
    mode: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>