<template>
  <navbar :showGoToTop="true"/>
  <showQuiz/>
</template>

<script>
import showQuiz from "./showQuiz.vue";
import navbar from "../../module/navbar";

export default {
  name: "index",
  components: {
    navbar,showQuiz
  },
}
</script>

<style scoped>

</style>