<template>
  <div v-if="uploadShow">
    <div class="container mt-4">
      <div class="row ">
        <div class="col d-flex justify-content-start">
          <h4 class=""><strong>考試管理</strong></h4>
        </div>
      </div>

      <div class="row ">
        <div class="col">
          <table class="table table-striped">
            <thead>
            <tr>
              <th style="width:10%"><input type="checkbox" class=" form-check-input me-1" v-model="checkedAll">全選</th>
              <th style="width:5%">#</th>
              <th style="width:20%">考試名稱</th>
              <th style="width:20%">開始時間</th>
              <th style="width:20%">結束時間</th>
              <th style="width:10%">建立者</th>
              <th style="width:15%">操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data,index) in listQuiz" :key="index">
              <td><input type="checkbox" class="form-check-input" v-model="data.checked"></td>
              <th> {{ index + 1 }}</th>
              <td>{{ data.title }}</td>
              <td>{{ data.startTime }}</td>
              <td>{{ data.endTime }}</td>
              <td>{{ data.creator }}</td>
              <td>
                  <button class="btn btn-outline-primary py-0 mx-1 px-1" @click="goExamPage(data.id)" ><i
                          class="fas fa-eye"></i></button>
                <button class="btn btn-outline-primary py-0 mx-1 px-1" @click="goEdit(data.id)"><i
                    class="fas fa-edit"></i></button>
                <!--              <button class="btn btn-outline-danger py-0 mx-1 px-1"><i class="fas fa-trash-alt"></i></button>-->
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="false" class="row">
      <div class="col">
        <button class="btn btn-primary mx-1">下載成績</button>
        <!--      <button class="btn btn-danger mx-1">刪除考試</button>-->
        <button class="btn btn-primary  mx-1">設定時間</button>
      </div>
    </div>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal"></alert-modal>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
import AlertModal from "../../../module/Modal/alertModal.vue";

export default {
  name: "quizManagePanel",
  components: {
    AlertModal
  },
  data() {
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    this.fetchQuizList()
    return {
      alertModal,
      checkedAll: false,
      listQuiz: [],
      uploadShow: false,
    }
  },
  watch: {
    checkedAll: {
      handler: function () {
        for (let i = 0; i < this.listQuiz.length; i++) {
          this.listQuiz[i].checked = this.checkedAll
        }
      }
    }
  },
  methods: {
    fetchQuizList: async function () {
      await axios.post(`/api/course/${this.$route.params.courseId}/quiz-manage/list`).then(async res => {
        if (res.data.success) {
          let info = res.data.info
          for (let i = 0; i < info.length; i++) {
            let startText = (info[i].startTime != null) ? dayjs(new Date(info[i].startTime)).format(`YYYY-MM-DD HH:mm`) : '未設定'
            let endText = (info[i].endTime != null) ? dayjs(new Date(info[i].endTime)).format(`YYYY-MM-DD HH:mm`) : '未設定'
            this.listQuiz.push({
              checked: false,
              id: info[i].id,
              title: info[i].title,
              startTime: startText,
              endTime: endText,
              creator: info[i].creator
            })
          }
          this.alertModal = {
            show: false,
          }
          this.uploadShow = true
        }
        else {
          this.alertModal = {
            show: true,
            state: "warning",
            title: "讀取失敗",
            bodyMsg: "此頁面讀取失敗，請重新嘗試",
            crossShow: false,
            confirmEvent: function () {
              location.href = `/course/${this.$route.params.courseId}`
            }
          }
        }
      })
    },
    goEdit: function (examId) {
      this.$router.push(`/course/${this.$route.params.courseId}/exam/${examId}/edit`)
    },
    goExamPage: function (examId) {
      this.$router.push(`/course/${this.$route.params.courseId}/exam/${examId}`)
    }
  },

}
</script>

<style scoped>

</style>