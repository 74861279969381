<template>
  <navbar hide-bread-crumb="true"></navbar>
<h1>:( [404] </h1>
<h2>Page Not Found</h2>
  <router-link to="/"><button class="btn btn-primary">回到首頁</button></router-link>
  <button class="btn btn-primary mx-3" @click="$router.go(-1)">回上一頁</button>
</template>

<script>
import Navbar from "@/components/module/navbar";

export default {
  name: "page404",
  components: {Navbar}
}
</script>

<style scoped>
h1{
  font-size: 200px;
  font-weight: bolder;
  text-align: center;
}
h2{
  font-size: 100px;
  font-weight: bolder;
  text-align: center;
}
</style>