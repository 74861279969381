<template>
  <div v-show="loadSeatColor">
    <navbar :seatBoolean="seatBoolean" :seatNumber="seatNumber"/>
  </div>
  <codingPanel/>
</template>

<script>
import navbar from "../module/navbar"
import codingPanel from "./codingPanel.vue";
import axios from "axios";

export default {
  name: "index.html",
  components: {
    navbar,
    codingPanel,
  },
  data() {
    this.getColor()
    return {
      loadSeatColor: false,
      seatBoolean: false,
      seatNumber: 0,
    }
  },
  methods: {
    getColor: async function () {
      await axios.post(`/api/course/${this.$route.params.examId}/color`).then((res) => {
        if (res.data.success) {
          this.seatBoolean = res.data.seatBoolean
          this.seatNumber = res.data.seatNumber
        }
        this.loadSeatColor = true
      })
    }
  }
}
</script>

<style scoped>

</style>