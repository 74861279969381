import CourseList from "../../components/courseList/index.vue";
import CourseMenu from "../../components/courseMenu/index.vue";
import examMenu from "../../components/examMenu/index.vue";
import examDetailCtrl from "../../components/examMenu/examControlComponent/examDetailCtrl/index.vue";
import createCourse from "../../components/createCourse/index.vue";
import joinCourse from "../../components/joinCourse/index.vue";
//import programTopic from "@/components/programCodingPage";
import createProblem from "../../components/createProblem/index.vue";
import createFormPaper from "../../components/formPaper/createFormPaper/index.vue";
import examFormPaper from "../../components/formPaper/examFormPaper/index.vue";
import examinationPaper from "../../components/formPaper/examinationPaper/index.vue";
import userFormExam from "../../components/userFormExam/index.vue";
import showQuizInformation from "../../components/showQuizInformation/index.vue";
import studentQuiz from "../../components/showQuizInformation/studentQuiz/index.vue";
import studentCodeRecord from "../../components/showQuizInformation/studentQuiz/code/index.vue";
import createRandomTopic from "../../components/formPaper/examinationPaper/createRandomTopic/index.vue";
import CourseSetting from "../../components/courseMenu/ctrlComponent/courseSetting/index.vue";
import permissionManage from "../../components/courseMenu/ctrlComponent/permissionManage/index.vue";
import QBManage from "../../components/courseMenu/ctrlComponent/QBManage/index.vue";
import quizManage from "../../components/courseMenu/ctrlComponent/quizManage/index.vue";
import quesGroupSetting from "../../components/examMenu/examControlComponent/quesGroupSetting.vue";
import codeManage from "../../components/examMenu/examControlComponent/codeManage/index.vue";
import programCodingPage from "../../components/programCodingPage/index.vue";

export default [

    // 課程列表 V
    {
        path: '/course',
        name: 'course',
        component: CourseList,
        meta: {needLogin: true},
    },

    // 特定courseId課程
    {
        path: "/course/:courseId",
        name: 'courseMenu',
        component: CourseMenu,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程:課程設定
    {
        path: "/course/:courseId/setting",
        name: 'CourseSetting',
        component: CourseSetting,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程:權限設定
    {
        path: "/course/:courseId/permission",
        name: 'permissionManage',
        component: permissionManage,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程:題庫管理
    {
        path: "/course/:courseId/QBank",
        name: 'QBInsideManage',
        component: QBManage,
        props: {
            mode:"manage"
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程:外部題庫匯入
    {
        path: "/course/:courseId/QBankImport",
        name: 'QBOutsideManage',
        component: QBManage,
        props: {
            mode:"import"
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程:考試管理
    {
        path: "/course/:courseId/examManage",
        name: 'quizManage',
        component: quizManage,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 新增考試
    {
        path: '/course/:courseId/add-exam',
        name: 'newExam',
        component: examDetailCtrl,
        props: {
            mode: 'new'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程中的examId試卷修改
    {
        path: '/course/:courseId/exam/:examId/edit',
        name: 'editExam',
        component: examDetailCtrl,
        props: {
            mode: 'edit'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程中的examId試卷題組設定
    {
        path: '/course/:courseId/exam/:examId/ques-group-setting',
        name: 'quesGroupSetting',
        component: quesGroupSetting,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程中的examId試卷
    {
        path: '/course/:courseId/exam/:examId',
        name: 'examData',
        component: examMenu,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 特定courseId課程中的examId試卷修改
    {
        path: '/course/:courseId/exam/:examId/code-manage',
        name: 'code-manage',
        component: codeManage,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 建立課程
    {
        path: '/createCourse',
        name: 'createCourse',
        component: createCourse,
        meta: {needLogin: true},
    },
    // 加入課程
    {
        path: '/joinCourse',
        name: 'joinCourse',
        component: joinCourse,
        meta: {needLogin: true},
    },
    // 建立程式考卷題目
    {
        path: '/course/:courseId/createProgram',
        name: 'createProblem',
        component: createProblem,
        props: {
            mode:"add"
        },
        meta: {needLogin: true},
    },
    // 程式考試頁面
    {
        path: '/course/:courseId/exam/:examId/program/:programId',
        name: 'codingPage',
        component: programCodingPage,
        meta: {needLogin: true, needCourseAuth: true},
    },
    {
        path: '/course/:courseId/program/:programId',
        name: 'programEdit1',
        component: createProblem,
        props: {
            mode:"edit"
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    {
        path: '/program/:programId',
        name: 'programEdit2',
        component: createProblem,
        props: {
            mode:"edit"
        },
        meta: {needLogin: true},
    },

    // 老師查看某位學生的程式提交紀錄
    {
        path: '/course/:courseId/exam/:examId/program/:programId/student/:uuid/submitRecord',
        name: 'studentCodeRecord',
        component: studentCodeRecord,
        props: {
            mode: 'student'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 老師查看某位學生的某項程式提交紀錄
    {
        path: '/course/:courseId/exam/:examId/program/:programId/student/:uuid/record/:recordId',
        name: 'showCodeRecord',
        component: studentCodeRecord,
        props: {
            mode: 'show'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    //創建表單考卷模板頁面
    {
        path: '/course/:courseId/createFormPaper',
        name: 'createFormPaper',
        component: createFormPaper,
        props: {
            mode: 'create'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    //修改表單考卷模板頁面
    {
        path: '/course/:courseId/form/:formId/editFormPaper',
        name: 'editFormPaper',
        component: createFormPaper,
        props: {
            mode: 'edit'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    //創建表單考卷頁面
    {
        path: '/course/:courseId/exam/:examId/examinationPaper',
        name: 'examinationPaper',
        component: examinationPaper,
        meta: {needLogin: true, needCourseAuth: true},
    },
    //創建表單模板隨機考卷頁面
    {
        path: '/course/:courseId/exam/:examId/form/:formId/createRandomPaper',
        name: 'createRandomTopic',
        component: createRandomTopic,
        meta: {needLogin: true, needCourseAuth: true},
    },
    //學生考試
    {
        path: '/course/:courseId/exam/:examId/form/:formId',
        name: 'userFormExam',
        component: userFormExam,
        props: {
            mode: 'student'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 老師查看此次考卷裡所有學生的資料
    {
        path: '/course/:courseId/exam/:examId/showQuiz',
        name: 'showQuizInformation',
        component: showQuizInformation,
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 老師觀看特定學生考卷
    {
        path: '/course/:courseId/exam/:examId/student/:uuid/quizInformation',
        name: 'studentQuizInformation',
        component: studentQuiz,
        meta: {needLogin: true, needCourseAuth: true},
    },
    //老師考卷畫面
    {
        path: '/course/:courseId/exam/:examId/form/:formId/teacher',
        name: 'teacherFormExam',
        component: userFormExam,
        props: {
            mode: 'teacher'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 老師批閱學生考卷
    {
        path: '/course/:courseId/exam/:examId/form/:formId/review',
        name: 'reviewPaper',
        component: userFormExam,
        props: {
            mode: 'review'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 顯示所有此考試的學生名單
    {
        path: '/course/:courseId/exam/:examId/form/:formId/show',
        name: 'showStudent',
        component: userFormExam,
        props: {
            mode: 'show'
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    // 老師觀看特定學生試卷
    {
        path: '/course/:courseId/exam/:examId/form/:formId/student/:uuid',
        name: 'checkUserFormExam',
        component: userFormExam,
        props: {
            mode: 'student',
            check: true,
        },
        meta: {needLogin: true, needCourseAuth: true},
    },
    //顯示表單考卷頁面
    {
        path: '/examFormPaper',
        name: 'examFormPaper',
        component: examFormPaper,
        meta: {needLogin: true},
    },
]