<template>
  <div v-if="uploadButtonShow && produce.content.length > 0" class="form--div">
    <!--選取批改題目的區塊-->
    <div class="row">
      <label class="fw-bold px-0 col-auto border--" style="font-size: 24px"
             @click="allTopics('titleQuestion',produce.content.length)">
        <span class="pe-1">批閱試卷：{{ produce.title }}</span><i class="fas fa-caret-down"></i>
      </label>
    </div>
    <button ref="reviewPaper" class="d-none" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#reviewPaper'" aria-expanded="false" :aria-controls="'reviewPaper'">
    </button>
    <div class="collapse p-0" :id="'reviewPaper'">
      <div class="border border-dark shadow--">
        <div class="mb-1" v-for="(produceData,produceIndex) in produce.content" :key="produceIndex">
          <div class="row fontSize-20 mb-0">
            <label @click="show('titleQuestion',produceIndex,produce.content.length)" class="col-auto px-0">
              {{ produceIndex + 1 }}.</label>
            <label @click="show('titleQuestion',produceIndex,produce.content.length)" class="col px-1"
                   style="text-align:justify;text-justify:inter-word;">{{
                (produceData.question.includes('%s')) ? produceData.question.replace(/%s/g, "[?]") : produceData.question
              }}</label>
          </div>
          <div @click="show('titleQuestion',produceIndex,produce.content.length)" class="mb-2">
            <label class="col-auto text-success">此題分數({{ produceData.point }}分)</label>
            <label class="col-auto px-1"><i class="fas fa-caret-down" :ref="'icon' + produceIndex"></i></label>
          </div>
          <button :ref="'titleQuestion' + produceIndex" class="d-none" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#titleQuestion' + produceIndex" aria-expanded="false"
                  :aria-controls="'titleQuestion' + produceIndex">
          </button>

          <div class="collapse p-0" :id="'titleQuestion' + produceIndex">
            <div class="border border-dark shadow--">
              <div class="row my-1">
                <button v-for="(data,index) in produceData.answer" :key="index"
                        type="button" :class="['btn btn-outline-primary col text-truncate m-1',
                        (index==0)?'ms-0':'',(index==(produceData.answer.length-1))?'me-0':'']"
                        @click="changeQuestion(produceIndex,index)">
                  {{ (produceData.question.includes('%s')) ? ('' + data.args) : produceData.question }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--設定的區塊-->
    <div class="row" style="font-size: 24px">
      <label class="fw-bold px-0">
        <button type="button" class="btn btn-outline-dark me-2" @click="collapse('setting')">
          <i class="fas fa-cog me-1"></i>批閱試卷的參數設定
        </button>
      </label>
    </div>
    <button ref="setting" class="d-none" type="button" data-bs-toggle="collapse"
            :data-bs-target="'#setting'" aria-expanded="false" :aria-controls="'setting'">
    </button>
    <div class="collapse p-0" :id="'setting'">
      <div class="border border-dark shadow--">
        <div class="row">
          <div class="input-group px-0">
            <label class="col-form-label fw-bold pe-3">分數按鈕快捷鍵</label>
            <input type="text" class="form-control" placeholder="EX:5,55,7(此結果將設置3個快捷按鈕分別是5,7,55)"
                   v-model="this.point.score" @change="changePointArray()">
          </div>
        </div>
        <div class="row">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="showNoPaper" :disabled="onlyHideNoPaper"
                   @change="showNoPaper($event,'show')">
            <label class="form-check-label" for="showNoPaper">只顯示未上傳試卷者</label>
          </div>
        </div>
        <div class="row">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="hideNoPaper" :disabled="onlyShowNoPaper"
                   @change="showNoPaper($event,'hide')">
            <label class="form-check-label" for="hideNoPaper">只顯示上傳試卷者資料</label>
          </div>
        </div>
      </div>
    </div>
    <!--新設定-->
    <table v-if="this.question!=''" class="table border border-secondary table-hover shadow--" style="font-size: 18px">
      <thead>
      <tr>
        <th style="width: 50%;" scope="col">題目</th>
        <th style="width: 40%;" scope="col">答案</th>
        <th style="width: 10%;" scope="col">分數</th>
      </tr>
      </thead>

      <tbody>
      <tr>
        <td style="width: 50%;">{{ teacherReview.question }}</td>
        <td style="width: 40%;">
          <div class="form-check px-0" v-for="(dataAnswer,indexAnswer) in teacherReview.answer" :key="indexAnswer"
               :style="[(teacherReview.type == 'filling')?'padding:0;':'']">

            <div v-if="teacherReview.type != 'filling'" class="row my-0">
              <label class="col-auto px-1"><i class="fas fa-check-circle"></i></label>
              <label class="col ps-0" style="text-align:justify;text-justify:inter-word;">{{
                  dataAnswer
                }}</label>
            </div>

            <div v-if="teacherReview.type == 'filling'" class="form-group row my-0">
              <label class="col-form-label col-auto p-0">{{ dataAnswer }}</label>
            </div>
          </div>
        </td>
        <td style="width: 10%;">{{ teacherReview.point }}</td>
      </tr>
      </tbody>
    </table>

    <table v-if="this.question!='' || this.noPaper.length != 0" class="table border border-secondary table-hover">
      <thead>
      <tr>
        <th class="state-- border-table--" scope="col"
            @click="sortNoun='state';this.sortNumber+=(this.firstClick)?0:1;sortPaperOrder()">狀態&nbsp;
          <span v-if="sortNoun=='state'"><i class="fas fa-caret-down"></i></span>
        </th>
        <th class="std_id-- border-table--" scope="col" @click="sortNoun='std_id';sortPaperOrder()">學號&nbsp;
          <span v-if="sortNoun=='std_id'"><i class="fas fa-caret-down"></i></span>
        </th>
        <th class="name-- border-table--" scope="col" @click="sortNoun='name';sortPaperOrder()">姓名&nbsp;
          <span v-if="sortNoun=='name'"><i class="fas fa-caret-down"></i></span>
        </th>
        <th class="answer-- border-table--" scope="col" @click="sortNoun='answer';sortPaperOrder()">答案&nbsp;
          <span v-if="sortNoun=='answer'"><i class="fas fa-caret-down"></i></span>
        </th>
        <th class="score--" scope="col">分數</th>
        <th class="operate--" scope="col">操作</th>
      </tr>
      </thead>

      <tbody v-for="(paperData,paperIndex) in showPaper" :key="paperIndex">
      <tr v-if="!onlyShowNoPaper">
        <td :class="[(paperData.content.review)?'text-success':'text-danger','state--']">
          {{ (paperData.content.review) ? '已批閱' : '未批閱' }}
          <i v-show="paperData.content.review" class="fas fa-check-circle"></i>
          <i v-show="!paperData.content.review" class="fas fa-times-circle"></i>
        </td>
        <td class="std_id--">{{ paperData.std_id }}</td>
        <td class="name--">{{ paperData.name }}</td>
        <td class="answer--">
          <span v-for="(dataAnswer,indexAnswer) in paperData.content.answer" :key="indexAnswer">
          <div class="form-check px-0" :style="[(paperData.content.type == 'filling')?'padding:0;':'']"
               v-if="paperData.content.type == 'filling' || (paperData.content.type != 'filling' && dataAnswer.choose)">

            <div v-if="paperData.content.type != 'filling' && dataAnswer.choose" class="row my-0">
              <label class="col-auto px-1"><i class="fas fa-check-circle"></i></label>
              <label class="col ps-0" style="text-align:justify;text-justify:inter-word;">{{
                  dataAnswer.content
                }}</label>
            </div>

            <div v-if="paperData.content.type == 'filling'" class="form-group row my-0">
              <label class="col-form-label col-auto p-0"><pre class="my-0">{{ dataAnswer.content }}</pre></label>
            </div>
          </div>
            </span>
        </td>
        <td class="score--">{{ paperData.content.score }}</td>
        <td class="operate--">
          <input type="text" class="form-control border-dark" placeholder="請填寫分數或點選按鈕批改"
                 @change="mark(paperIndex,$event.target.value);$event.target.value=''">
          <button v-for="arrayData in point.array" :key="arrayData" type="button"
                  class="btn btn-outline-dark m-1 ms-0" @click="mark(paperIndex,arrayData)">
            {{ arrayData }}
          </button>
        </td>
      </tr>
      </tbody>

      <tbody v-for="(paperData,paperIndex) in noPaper" :key="paperIndex">
      <tr v-if="!onlyHideNoPaper">
        <td class="text-danger fw-bold">未繳交&nbsp;<i class="fas fa-exclamation-triangle"></i></td>
        <td class="std_id--">{{ paperData.std_id }}</td>
        <td class="name--">{{ paperData.name }}</td>
        <td class="answer-- text-danger fw-bold">尚未交卷</td>
        <td class="score-- text-danger fw-bold">0</td>
        <td class="operate--"></td>
      </tr>
      </tbody>
    </table>

  </div>

  <div v-if="!uploadButtonShow" class="container form--div mt-3" style="text-align: center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <alert-modal v-model="alertModal.show" :="alertModal" @show="showModel"></alert-modal>
</template>

<script>
import AlertModal from "../../module/Modal/alertModal.vue";
import axios from "axios";
import {useToast} from "vue-toastification";

export default {
  name: "reviewPaper",
  components: {
    AlertModal
  },
  data() {
    this.getPaper()
    const alertModal = {
      show: true,
      state: "loading",
      title: "讀取中",
      crossShow: false,
      confirmShow: false
    }
    const error = {
      notApproved: false,
      approved: false,
      notAnswer: true
    }
    const point = {
      score: '',
      array: [],
    }
    const toast = useToast()
    const options = {
      position: "bottom-right",
      timeout: 1492,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: true,
      hideProgressBar: false,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    return {
      alertModal,
      error,
      point,
      toast,
      options,
      title: "title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title title ",
      produce: {},
      teacherReview: {
        type: "",
        point: "",
        question: "",
        answer: ""
      },
      sortNoun: "state",
      sortNumber: 0,
      firstClick:true,
      onlyShowNoPaper: false,
      onlyHideNoPaper: false,
      paper: [],
      showPaper: [],
      noPaper: [],
      question: '',
      uploadButtonShow: false
    }
  },
  methods: {
    getPaper: async function () {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/teacherPaper`).then((res) => {
        if (res.data.success) {
          this.produce = res.data.produce
        }
      })
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/reviewPaper`).then((res) => {
        if (res.data.success) {
          this.paper = res.data.paper
          this.noPaper = res.data.noPaper
          this.error.notAnswer = (this.noPaper.length) ? false : true
          this.alertModal = {
            show: false,
          }
          this.uploadButtonShow = true
        }
      })
    },
    show(name, index, length) {
      for (let i = 0; i < length; i++) {
        if (this.$refs[name + i][0].getAttribute('aria-expanded') == 'true')
          this.$refs[name + i][0].click()
      }
      this.$refs[name + index][0].click()
    },
    showOther(name) {
      this.$refs[name][0].click()
    },
    showModel() {
      this.uploadButtonShow = true
    },
    allTopics(name, length) {
      this.$refs.reviewPaper.click()
      for (let i = 0; i < length; i++) {
        if (this.$refs[name + i][0].getAttribute('aria-expanded') == 'true')
          this.$refs[name + i][0].click()
      }
    },
    collapse(name) {
      this.$refs[name].click()
    },
    differentiate() {
      let notApproved = 0
      let approved = 0
      this.paper.map((paperMap) => {
        if (paperMap.isAnswer) {
          paperMap.content.content.map((contentMap) => {
            notApproved += (contentMap.heading == this.question && !contentMap.review) ? 1 : 0
            approved += (contentMap.heading == this.question && contentMap.review) ? 1 : 0
          })
          this.error.notApproved = (notApproved) ? true : false
          this.error.approved = (approved) ? true : false
        }
      })
    },
    changeQuestion(contentIndex, answerIndex) {
      this.question = this.produce.content[contentIndex].answer[answerIndex].question
      this.teacherReview.type = this.produce.content[contentIndex].type
      this.teacherReview.point = this.produce.content[contentIndex].point
      this.teacherReview.question = this.produce.content[contentIndex].answer[answerIndex].question
      this.teacherReview.answer = this.produce.content[contentIndex].answer[answerIndex].answer
      this.differentiate()
      this.showPaper = []
      this.paper.forEach((item1) => {
        item1.content.content.forEach((item2) => {
          (item2.heading == this.question) ? this.showPaper.push({
            id: item1.id,
            std_id: item1.std_id,
            name: item1.name,
            content: item2,
            contentScore: item1.contentScore,
            isAnswer: item1.contentScore
          }) : ''
        })
      })
      this.sortPaperOrder()
      this.toast.success(`題目選取成功，可至下方批改`, this.options);
    },
    changePointArray() {
      this.point.array = this.point.score.split(',').map(item => parseInt(item, 10)).filter(item => !isNaN(item))
      this.point.array = [...new Set(this.point.array)];
      this.point.array.sort(function (a, b) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
      this.toast.success(`分數快捷鍵設定成功`, this.options);
      this.collapse('setting')
    },
    showNoPaper(event, state) {
      let string = ''
      if (state == 'show') {
        this.onlyShowNoPaper = event.target.checked
        string = (this.onlyShowNoPaper) ? "現在表格只顯示試卷尚未上傳的學生" : "現在表格將顯示全體學生資料"
      }
      if (state == 'hide') {
        this.onlyHideNoPaper = event.target.checked
        string = (this.onlyHideNoPaper) ? "現在表格只顯示試卷已上傳學生的資料" : "現在表格將顯示全體學生資料"
      }
      this.toast.success(`${string}`, this.options);
    },
    sortPaperOrder() {
      let newPaper = JSON.parse(JSON.stringify(this.showPaper))
      let newNoPaper = JSON.parse(JSON.stringify(this.noPaper))
      if (this.question != '' && newPaper.length > 0) {
        if (this.sortNoun == 'state') {
          this.firstClick = false
          if (this.sortNumber % 2 == 0) {
            newPaper.sort(function (a, b) {
              if (a.content.review && b.content.review) return 0
              if (a.content.review && !b.content.review) return 1
              if (!a.content.review && b.content.review) return -1
              if (!a.content.review && !b.content.review) return 0
            })
          } else {
            newPaper.sort(function (a, b) {
              if (a.content.review && b.content.review) return 0
              if (a.content.review && !b.content.review) return -1
              if (!a.content.review && b.content.review) return 1
              if (!a.content.review && !b.content.review) return 0
            })
          }
        }
        if (this.sortNoun == 'std_id') {
          this.sortNumber = 0
          this.firstClick = true
          newPaper.sort(function (a, b) {
            if (a.std_id > b.std_id) return 1
            if (a.std_id < b.std_id) return -1
            return 0
          })
        }
        if (this.sortNoun == 'name') {
          this.sortNumber = 0
          this.firstClick = true
          newPaper.sort(function (a, b) {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })
        }
        if (this.sortNoun == 'answer') {
          this.sortNumber = 0
          this.firstClick = true
          newPaper.sort(function (a, b) {
            if (newPaper[0].content.type != 'filling') {
              let aCount = 0
              let bCount = 0
              a.content.answer.forEach((item) => aCount += (item.choose) ? 1 : 0)
              b.content.answer.forEach((item) => bCount += (item.choose) ? 1 : 0)

              if (aCount > bCount) return 1
              if (aCount < bCount) return -1
              return 0
            }
            if (newPaper[0].content.type == 'filling') {
              if (a.content.answer[0].content.length > b.content.answer[0].content.length) return 1
              if (a.content.answer[0].content.length < b.content.answer[0].content.length) return -1
              return 0
            }
          })
        }
        this.showPaper = newPaper
      }
      if (this.sortNoun == 'std_id') {
        newNoPaper.sort(function (a, b) {
          if (a.std_id > b.std_id) return 1
          if (a.std_id < b.std_id) return -1
          return 0
        })
      }
      if (this.sortNoun == 'name') {
        newNoPaper.sort(function (a, b) {
          if (a.name > b.name) return 1
          if (a.name < b.name) return -1
          return 0
        })
      }
      this.noPaper = newNoPaper
    },
    mark: function (showPaperIndex, newScore) {
      let paperIndex = 0
      let contentIndex = 0
      this.paper.forEach((item, index) => {
        if (item.std_id == this.showPaper[showPaperIndex].std_id) paperIndex = index
      })
      this.paper[paperIndex].content.content.forEach((item, index) => {
        if (item.heading == this.question) contentIndex = index
      })
      let oldPaper = JSON.parse(JSON.stringify(this.paper[paperIndex]))
      this.paper[paperIndex].contentScore -= this.paper[paperIndex].content.content[contentIndex].score
      this.paper[paperIndex].content.content[contentIndex].score = parseInt(newScore, 10)
      this.paper[paperIndex].content.content[contentIndex].review = true
      this.paper[paperIndex].contentScore += this.paper[paperIndex].content.content[contentIndex].score

      let oldShowPaper = JSON.parse(JSON.stringify(this.showPaper[showPaperIndex]))
      this.showPaper[showPaperIndex].contentScore -= this.showPaper[showPaperIndex].content.score
      this.showPaper[showPaperIndex].content.score = parseInt(newScore, 10)
      this.showPaper[showPaperIndex].content.review = true
      this.showPaper[showPaperIndex].contentScore += this.showPaper[showPaperIndex].content.score

      this.uploadMark(showPaperIndex, paperIndex, newScore, oldPaper, oldShowPaper)
    },
    uploadMark: async function (showPaperIndex, paperIndex, newScore, oldPaper, oldShowPaper) {
      await axios.post(`/api/paper/${this.$route.params.courseId}/${this.$route.params.examId}/${this.$route.params.formId}/upload/ReviewPaper`, {
        paper: this.paper[paperIndex]
      }).then((res) => {
        if (res.data.success) {
          this.toast.success(`分數更改成功`, this.options);
          this.differentiate()
        } else {
          this.toast.error("分數變更失敗", {
            position: "bottom-right",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false
          });
          this.paper[paperIndex] = oldPaper
          this.showPaper[showPaperIndex] = oldShowPaper

        }
      })
    }
  }
}
</script>

<style scoped>
.form--div {
  width: 80%;
  max-width: 80vw;
  margin: 20px auto;
  text-align: left;
}

label {
  text-align: left;
}

.fontSize-20 {
  font-size: 20px;
}

.row {
  margin: 10px 0;
}

.shadow-- {
  box-shadow: 0 .375rem .5rem rgba(0, 0, 0, .15);
  border-radius: 0.25rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem !important;
}

.border--:hover {
  border-radius: 0.375rem;
  border: 1px solid #212529;
  padding: 0px 0.5rem !important;
}

.border-table--:hover {
  border: 1px solid #212529;
  background-color: #cefaef;
}

.state-- {
  width: 10%;
}

.std_id-- {
  width: 10%;
}

.name-- {
  width: 10%;
}

.answer-- {
  width: 35%;
}

.score-- {
  width: 5%;
}

.operate-- {
  width: 20%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #555;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb:window-inactive {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #e3e0e0;
}

</style>